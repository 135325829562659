import { getInstance } from "@/auth"
import { AUTH0_CONFIG } from '@/config'

export const authGuard = (to, from, next) => {
  if (AUTH0_CONFIG.BYPASS_AUTH0) {
    return next()
  }

  const authService = getInstance()

  const fn = () => {
    if (authService.isAuthenticated) {
      return next()
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn()
    }
  })
}