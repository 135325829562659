// - https://github.com/tema-q/woenenn-vue-client/wiki/surveyJS:-Editierm%C3%B6glichkeit-von-Surveys-per-SurveyCreator
// - https://github.com/tema-q/woenenn-vue-client/issues/755

import _ from 'lodash'

const VariableModel = function() {}

VariableModel.prototype = {}

const WOENENN_TYPES = {
  boolean: {
    surveyType: 'boolean'
  },
  categorical: {
    defaultSubtype: 'dropdown',
    subtypes: {
      radiogroup: {
        surveyType: 'radiogroup'
      },
      dropdown: {
        surveyType: 'dropdown'
      }
    }
  },
  categorical_multiple: {
    defaultSubtype: 'checkbox',
    subtypes: {
      checkbox: {
        surveyType: 'checkbox'
      },
      ranking: {
        surveyType: 'ranking'
      }
    }
  },
  /*date: {
    surveyType: 'datepicker'
  },*/
  date: {
    surveyType: 'date'
  },
  date: {
    surveyType: 'text',
    surveySubtype: 'date'
  },
  datetime: {
    surveyType: 'text',
    surveySubtype: 'datetime-local'
  },
  html: {
    surveyType: 'html-rendered'
  },
  json: {
    surveyType: 'json'
  },
  link: {
    surveyType: 'html-rendered'
  },
  paneldynamic: {
    surveyType: 'paneldynamic'
  },
  matrix: {
    surveyType: 'matrix'
  },
  number: {
    defaultSubtype: 'number',
    subtypes: {
      number: {
        surveyType: 'text',
        surveySubtype: 'number'
      },
      range: {
        surveyType: 'text',
        surveySubtype: 'range'
      },
      rating: {
        surveyType: 'rating'
      }
    }
  },
  "related-survey": {
    surveyType: 'related-survey'
  },
  "related-surveys": {
    surveyType: 'related-surveys'
  },
  "surveyjs-creator": {
    surveyType: 'surveyjs-creator'
  },
  text: {
    defaultSubtype: 'text',
    subtypes: {
      text: {
        surveyType: 'text',
        surveySubtype: 'text'
      },
      email: {
        surveyType: 'text',
        surveySubtype: 'email'
      },
      password: {
        surveyType: 'text',
        surveySubtype: 'password'
      },
      comment: {
        surveyType: 'comment'
      }
    }
  },
  verbatim_n: {
    surveyType: 'paneldynamic'
  }
}

const convertTypes = (typesDef, type, subtype) => {
  let typeDef = typesDef[type]
    
  if (!typeDef) {
    throw new Error(`Unknown variable type ${type}`)
  }

  if (subtype) {
    if (!typeDef.subtypes) {
      throw new Error(`Variable subtype ${subtype} of variable type ${type} has no survey type match`)
    }

    let subtypeDef = typeDef.subtypes[subtype]

    if (!subtypeDef) {
      throw new Error(`Unknown variable subtype ${subtype} for variable type ${type}`)
    }

    return subtypeDef
  } else {
    if (typeDef.defaultSubtype) {
      return typeDef.subtypes[typeDef.defaultSubtype]
    }
    return typeDef
  }
}

// SURVEY_TYPES is a mapping from WOENENN_TYPES: 
// {
//   <surveyTypeName>: [
//     <wonennTypeName>,
//     ...
//   ],
//   ...
// }
const SURVEY_TYPES = (() => {
  const buildSurveyTypes = (surveyTypes, woenennTypeDefs, parentTypeName) => {
    for (let typeName in woenennTypeDefs) {
      let woenennTypeDef = woenennTypeDefs[typeName]
      let surveyType = woenennTypeDef.surveyType
      let surveySubtype = woenennTypeDef.surveySubtype
  
      if (surveyType) {
        let surveyTypeDef = surveyTypes[surveyType] = surveyTypes[surveyType] || {}
  
        let woenennType = parentTypeName || typeName
        let woenennSubtype = parentTypeName ? typeName : null

        if (surveySubtype) {
          let subtypes = surveyTypeDef.subtypes = surveyTypeDef.subtypes || {}
          let surveySubtypeDef = subtypes[surveySubtype] = subtypes[surveySubtype] || {}

          surveySubtypeDef.woenennType = woenennType

          if (woenennSubtype) {
            surveySubtypeDef.woenennSubtype = woenennSubtype
          }
        } else {
          surveyTypeDef.woenennType = woenennType

          if (woenennSubtype) {
            surveyTypeDef.woenennSubtype = woenennSubtype
          }
        }
      }
  
      if (woenennTypeDef.subtypes) {
        buildSurveyTypes(surveyTypes, woenennTypeDef.subtypes, typeName)
      }
    }

    return surveyTypes
  }
  
  return buildSurveyTypes({}, WOENENN_TYPES)
})()

/*const SURVEY_TYPES = {
  boolean: {
    dbType: 'Booleans',
    woenennType: 'boolean',
    multiple: false
  },
  checkbox: {
    dbType: 'Array of Strings',
    woenennType: 'categorical',
    multiple: true
  },
  datepicker: {
    dbType: 'Date',
    woenennType: 'text',
    multiple: false
  },
  dropdown: {
    dbType: 'String',
    woenennType: 'categorical',
    multiple: false
  },
  json: {
    dbType: 'Object',
    woenennType: 'json',
    multiple: false
  },
  radiogroup: {
    dbType: 'String',
    woenennType: 'text',
    multiple: false
  },
  ranking: {
    dbType: 'Array of Strings',
    woenennType: 'categorical',
    multiple: true
  },
  rating: {
    dbType: 'Int32',
    woenennType: 'number',
    multiple: false
  },
  "surveyjs-creator": {
    dbType: 'Object',
    woenennType: 'surveyjs-creator',
    multiple: false
  },
  "related-survey": {
    dbType: 'ObjectId',
    woenennType: 'related-survey',
    multiple: false
  },
  "related-surveys": {
    dbType: 'Array',
    woenennType: 'related-surveys',
    multiple: true
  },
  text: {
    subtypes: {
      date: {
        dbType: 'Date',
        woenennType: 'text',
        multiple: false
      },
      "datetime-local": {
        dbType: 'Date',
        woenennType: 'text',
        multiple: false
      },
      email: {
        dbType: 'String',
        woenennType: 'text',
        multiple: false
      },
      number: {
        dbType: 'String',
        woenennType: 'number',
        multiple: false
      },
      password: {
        dbType: 'String',
        woenennType: 'text',
        multiple: false
      },
      range: {
        dbType: 'String',
        woenennType: 'number',
        multiple: false
      },
      text: {
        dbType: 'String',
        woenennType: 'text',
        multiple: false
      }
    }
  }
}*/

Object.assign(VariableModel, {
  WOENENN_TYPES: WOENENN_TYPES,

  SURVEY_TYPES: SURVEY_TYPES,

  checkFilterValue(value, variable) {
    switch(variable.type) {
      case 'categorical':
        if (value != null && !Array.isArray(value)) {
          //console.error(variable, value, typeof value)
          //throw new Error(`variable "${variable.name}" is not an array: ${value}`) 
        }
        break
      case 'text':
        if (value != null && !_.isString(value)) {
          //console.error(variable, value, typeof value)
          //throw new Error(`variable "${variable.name}" is not an array: ${value}`)
        }
        break
      case 'date':
        break
      case 'datetime':
        break
      case 'link':
        break
      case 'number':
        break
      case 'array_of_strings':
        if (value != null && !Array.isArray(value)) {
          //throw new Error(`variable "${variable.name}" is not an array of strings: ${value}`)
        }
        break
      case 'verbatim_n':
        break
    }
  },

  wonennTypeToSurveyType(type, subtype) {
    return convertTypes(WOENENN_TYPES, type, subtype)
  },

  surveyTypeToWoenennType(type, subtype) {
    return convertTypes(SURVEY_TYPES, type, subtype)
  }
})

export default VariableModel