<template>
  <div style="display: flex; flex-direction: column; align-items: center; width: 232px; padding: 16px;">
    <div ref="sb-selector" class="sb-selector">
      <div class="h-panel" :style="{ background: hueBackground }"></div>
      <div class="s-panel"></div>
      <div class="b-panel"></div>
      <div ref="sb-indicator" class="sb-indicator"></div>
      <div ref="sb-event-target" class="sb-event-target" tabindex=0></div>
    </div>
    <slider :value="hue" class="hue-slider" @input="hue = $event; updateValue()"  :min="0" :max="1" :precision="2"></slider>
    <div style="display: flex; align-items: flex-end; justify-content: stretch;">
      <div style="flex: 0 0 auto; width: 32px; height: 32px; margin-right: 16px;" :style="{background: value}"></div>
      <div style="width: 100%;">
      <md-field style="min-width: 40px; margin: 0;">
        <span class="md-prefix">#</span>
        <md-input type="text" v-model="textValue" @input="textInput" @change="textChange"></md-input>
      </md-field>
      </div>
    </div> 
  </div>
</template>


<script>

export default {
  name: 'colorpicker',
  props: {
    value: String,
  },
  data() {
    return {
      isDragging: false,
      hue: 0,
      saturation: 0,
      brightness: 0,
      hueBackground: '#f00',
      textValue: 'ff0000',
      sliderValue: 0
    }
  },
  methods: {   
    textInput(e) {
      let textInput = e || '';
      if (textInput[0] === '#') {
        textInput = textInput.substring(1);
      }
      let r;
      let g;
      let b;      
      // three digit hex
      let m = textInput.match(/^([0-9a-f]{3})($|\s)/i);
      let m1;
      if (m) {
        m1 = m[1];
        r = parseInt(m1[0], 16) * 0x11;
        g = parseInt(m1[1], 16) * 0x11;
        b = parseInt(m1[2], 16) * 0x11;
      } else {
        // six digit hex
        m = textInput.match(/^([0-9a-f]{6})($|\s)/i);
        if (m) {
          m1 = m[1];
          r = parseInt(m1.substr(0, 2), 16);
          g = parseInt(m1.substr(2, 2), 16);
          b = parseInt(m1.substr(4, 2), 16);
        } else {
          r = null;
          g = null;
          b = null;
        }
      }
      if (r != null && b != null && g != null) {
        let rgbFromHsv = this.hsvToRgb(this.hue, this.saturation, this.brightness);
        if (rgbFromHsv[0] !== r || rgbFromHsv[1] !== g || rgbFromHsv[2] !== b) {
          let hsv = this.rgbToHsv(r, g, b);
          this.hue = hsv[0];
          this.saturation = hsv[1];
          this.brightness = hsv[2];
          this.$emit('input', this.rgbToHex(r, g, b));
        }
      }
    },
    textChange(e) {
      this.textValue = this.value.substring(1);
    },
    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + b | (g << 8) | (r << 16)).toString(16).slice(1).toUpperCase();
    },
    _hueToRgb(p, q, t) {
      t += t < 0 ? 1 : t > 1 ? -1 : 0;
      return Math.round(255 * (
          t < 1 / 6 ? p + (q - p) * 6 * t
            : t < 1 / 2 ? q
            : t < 2 / 3 ? p + (q - p) * (2 / 3 - t) * 6
            : p
        ));
    },
    hsvToRgb(h, s, v) {
      let r;
      let g;
      let b;
      if (s === 0) {
        r = Math.round(255 * v);
        g = r;
        b = r;
      } else {
        let m2 = v;
        let m1 = (1 - s) * m2;
        r = this._hueToRgb(m1, m2, h + 1 / 3);
        g = this._hueToRgb(m1, m2, h);
        b = this._hueToRgb(m1, m2, h - 1 / 3);
      }
      return [r, g, b];
    },
    rgbToHsv(r, g, b) {
      let min = Math.min(r, g, b);
      let max = Math.max(r, g, b);
      let delta = max - min;
      let v = max / 255;
      let s = max === 0 ? 0 : delta / max;
      let h;
      if (s === 0) {
        h = this.hue;
      } else {
        h = r === max ? (g - b) / delta : g === max ? 2 + (b - r) / delta : 4 + (r - g) / delta;
        if (h < 0) h += 6;
        h /= 6;
      }
      return [h, s, v];
    },
    updateValue() {
      let h = this.hue;
      let s = this.saturation;
      let v = this.brightness;
      let rgb = this.hsvToRgb(h, s, v);
      let colorhex = this.rgbToHex(rgb[0], rgb[1], rgb[2]);
      this.textValue = colorhex.substring(1);
      this.$emit('input', colorhex)
    },
    documentMousemove(e) {
      if (e.buttons !== 1) {
        this.endDrag();
      } else {
        let sbEventTarget = this.$refs['sb-event-target'];
        let x = e.pageX - (window.pageXOffset + sbEventTarget.getBoundingClientRect().left);
        let y = e.pageY - (window.pageYOffset + sbEventTarget.getBoundingClientRect().top);
        let width = sbEventTarget.offsetWidth;
        let height = sbEventTarget.offsetHeight;
        this.saturation = Math.max(0, Math.min(1, x / width ));
        this.brightness = Math.max(0, Math.min(1, 1 - y / height));
        this.updateValue();
        e.preventDefault();
      }
    },
    endDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.documentMousemove);      
      let sbEventTarget = this.$refs['sb-event-target'];
      sbEventTarget.style.cursor = 'crosshair';
    }
  },  
  mounted() {
    let sbEventTarget = this.$refs['sb-event-target'];
    sbEventTarget.addEventListener('mousedown', e => {
      sbEventTarget.style.cursor = 'none';
      let x = e.offsetX;
      let y = e.offsetY;
      let width = sbEventTarget.offsetWidth;
      let height = sbEventTarget.offsetHeight;
      this.saturation = x / width;
      this.brightness = 1 - y / height;
      this.updateValue();
      document.addEventListener('mousemove', this.documentMousemove);
      document.addEventListener('mouseup', this.endDrag);
    });
    this.textInput(this.value);
  },
  beforeDestroy () {
    this.endDrag();
    document.removeEventListener('mousemove', this.documentMousemove);
    document.removeEventListener('mouseup', this.endDrag);
  },
  watch: {    
    hue(newValue) {
      /*let x = pageX - selectorOffset.left;
      let y = pageY - selectorOffset.top;
      let width = sbSelector.outerWidth();
      let height = sbSelector.outerHeight();*/
      // update slider position     
      let hueRgb = this.hsvToRgb(newValue, 1, 1);
      let hueHex = this.rgbToHex(hueRgb[0], hueRgb[1], hueRgb[2]);
      this.hueBackground = hueHex;
    }, 
    saturation(newValue) {
      // update horizontal position on sb-selector
      let sbSelector = this.$refs['sb-selector'];
      let sbIndicator = this.$refs['sb-indicator'];
      let width = sbSelector.offsetWidth;      
      sbIndicator.style.left = Math.round(newValue * width) + 'px';
    }, 
    brightness(newValue) {
      // update vertical position on sb-selector
      let sbSelector = this.$refs['sb-selector'];
      let sbIndicator = this.$refs['sb-indicator'];
      let height = sbSelector.offsetHeight;
      sbIndicator.style.top = Math.round((1 - newValue) * height) + 'px';
    },    
  },  
}

</script>


<style lang="scss">

.sb-selector {
    position: relative;
    width: 200px; /* must be half of controls table width*/
    height: 200px; /* must be half of controls table width*/
    overflow: hidden;
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
    background-color: red;
}

.sb-selector .sb-event-target {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
    cursor: crosshair
}

.sb-selector .h-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f00;
}

.sb-selector .s-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0) 100%);
}

.sb-selector .b-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
}

.sb-selector > .indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255, 255, 255, .8);
    border-radius: 50%;
    margin: -8px 0 0 -8px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0s ease;
    transition-property: left, top, width, height, border-width, margin;
    transition-duration: 0s, 0s, 0.2s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.sb-selector.focused > .indicator {
    width: 32px;
    height: 32px;
    border-width: 11px;
    margin: -16px 0 0 -16px;
}

.sb-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 3px solid rgba(255, 255, 255, .8);
    border-radius: 50%;
    margin: -8px 0 0 -8px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transition: 0s ease;
    transition-property: left, top, width, height, border-width, margin;
    transition-duration: 0s, 0s, 0.2s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.sb-indicator {
    width: 32px;
    height: 32px;
    border-width: 11px;
    margin: -16px 0 0 -16px;
}

.hue-slider {
    position: relative;
    display: block;
    height: 24px;
    width: 200px;  /* Currently the slider does not react on size changes, therefore we need a constant width in any case */
    touch-action: none; /* Prevent default touch behaviour like scrolling or pinch-zoom */
    margin-top: 8px;
}

.hue-slider > .slider-track {
    background: linear-gradient(to right, #f00 0, #ff0 16.67%, #0f0 33.33%, #0ff 50%, #00f 66.67%, #f0f 83.33%, #f00 100%);
    position: absolute;
    left: 0;
    top: 4px;
    width: 100%;
    height: 16px;
}

.hue-slider .track-overlay,
.hue-slider .indicator-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hue-slider > .slider-indicator {
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid #fff;
    box-shadow:  0px 1px 3px rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 5px;
    left: 1px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    border-radius: 50%;
    transition: 0s ease;
    transition-property: left, width, height, top, margin-left;
    transition-duration: 0s, 0.2s, 0.2s, 0.2s, 0.2s;
}

.hue-slider:hover > .indicator {
    top: 4px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
}

.hue-slider.focused > .indicator {
    top: 0;
    width: 24px;
    height: 24px;
    margin-left: -12px;
}

.hue-slider .indicator-overlay {
    border-radius: 50%;
}

.hue-slider > .event-target {
    position: absolute;
    cursor: ew-resize;
    width: calc(100% + 14px);
    left: calc(0px - 7px);
    top: 0;
    height: 100%;
    outline: 0;
    background: rgba(0, 0, 0, 0); /* Makes sure events and mouse pointers work properly on IE10 */
}

.md-input {
  width: 100%;
}

</style>