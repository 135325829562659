<template>
<div class="alert-wrapper">
  <div class="alert">
    <div v-if="title">
      <v-icon class="mr-1">info</v-icon>
      <span  class="md-body-2">{{title}}</span>
    </div>
    <v-icon v-else class="mr-1">info</v-icon>
    <slot />
  </div>
</div>
</template>

<script>
export default {
  name: 'AlertView',
  props: ['title']
}
</script>


<style lang="scss" scoped>

$blue-light: rgba(146, 215, 255, 0.171);

.alert-wrapper {
  background: white;
  border-radius: 4px;
  margin: 4px 0;
}

.alert {
  //display: inline-block;
  background: $blue-light;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 6px;
  line-height: 24px;
  font-size: 12px;
  list-style-position: inside;
}

</style>
