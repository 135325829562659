<template>
<div class="settings-container" v-if="settings && settingsModel">
  <div class="heading">{{ this.$store.state.analysisView === 'datasets' ? $t('texts.SETTINGS_DATASETS') :  this.$store.state.analysisView === 'tabulation' ? $t('texts.SETTINGS_TABULATION') :  $t('texts.SETTINGS_CHART')}}</div>
  <md-content class="content md-scrollbar">
    <analysis-settings-item v-for="settingsModelItem in settingsModel" ref="settingsModelItems" v-bind:key="settingsModelItem.name"  v-bind:settingsModel.sync="settingsModel" v-bind:settingsModelItem.sync="settingsModelItem"  v-bind:settingsItemName="settingsModelItem.name" v-bind:analysisView="analysisView" v-bind:settings="settings" @input="setSettingsModified(settingsModelItem, $event)">
    </analysis-settings-item>
  </md-content>
</div>
</template>


<script>
import error from '@/services/error';
import evalExpression from '@/services/boolExEval';

export default {
  name: 'AnalysisSettings',
  methods: {
    setSettingsModified(settingsModelItem, val) {
      this.settings[settingsModelItem.name] = val;
      // Notify setting item components about change, so they can adapt if necessary
      // Important: Do this before updating the datasets or tabulation view
      let settingsModelItems = this.$refs.settingsModelItems;
      for (let item of settingsModelItems) {        
        item.settingsChanged();
      }
      if (evalExpression(settingsModelItem.filter, { view: 'datasets', settings: this.settings })) {
        // datasets changed
        this.$store.commit('setAnalysisSettingsDatasetsDirty', true)
        this.applySettings()
      }  
      if (evalExpression(settingsModelItem.filter, { view: 'tabulation', settings: this.settings })) {
        // tabulation changed
        this.$store.commit('setAnalysisSettingsTabulationDirty', true);
        this.applySettings();
      }
      if (evalExpression(settingsModelItem.filter, { view: 'chart', settings: this.settings })) {
        // data chart changed
        this.$store.commit('setAnalysisSettingsChartDirty', true);
        this.applySettings();
      }
    },
    applySettings() {
      if (this.settings != null) {
        let newSettings = JSON.parse(JSON.stringify(this.settings));
    
        this.$store.dispatch('setAnalysisSettings', newSettings);
        this.updateErrorMessage();
      }    
    },
    updateErrorMessage() {
      let error = null;
      let settingsModelItems = this.$refs.settingsModelItems;
      if (settingsModelItems) {
        for (let item of settingsModelItems) {
          //ToDo: only if item is visible
          if (item.visible) {
            error = error || item.errorMessage;
          }
        }
      }
      this.$store.commit('setAnalysisSettingsError', error);
      return error;
    }
  },
  data() {
    return {
      settings: null,
      settingsModel: null
    };
  },
  async beforeMount() {
    try {
      this.settingsModel = await this.$store.dispatch('getAnalysisSettingsModel');
      this.settings = await this.$store.state.analysisSettings;
      this.$store.watch(
        state => state.analysisSettings,
        newSettings => {
          this.settings = newSettings; 
          let settingsModelItems = this.$refs.settingsModelItems;
          for (let item of settingsModelItems) {        
            item.settingsChanged();
          } 
        }
      )
    } catch(e) {
      error.runtimeError(e);
    }
  },
  computed: { 
    analysisView() {
      return this.$store.state.analysisView;
    }    
  },
}
</script>

<style lang="scss" scoped>
  .heading {
    flex: 0 0 auto;
    padding: 16px 16px 12px 16px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(221, 221, 221);
    white-space: nowrap;
    color: $primary;
    text-transform: uppercase;
  }

  .content {
    flex: 1 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px 12px;
  }
  

  .settings-container {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;  
  }

  .apply-button {
    width: 100%;    
    flex: 0 0 auto;
    box-sizing: content-box;
    margin: 0;
  }
</style>