import Vue, {toRaw} from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

import api from '@/services/api'
import completeAnalysisSettings from '../services/analysisSettingsCompletion'
import evalExpression from '@/services/boolExEval'
import error from '@/services/error'
import filters from '@/services/filters'
import router from '@/router/index'
import url from '@/services/url'
import util from '@/services/util'
import VariableModel from '@/models/variable'
import { t } from '@/i18n'
import Util from '../services/util'

Vue.use(Vuex)

let freezeLoadingCount = 0

let cleanViewState = function() {
  return {
    analysisSettings: null,
    analysisSettingsDatasetsDirty: false,
    analysisSettingsChartDirty: false,
    analysisSettingsError: null,
    analysisSettingsModel: null,
    analysisSettingsTabulationDirty: false,
    appliedFilterValues: {}, // applied filter values in analysis page
    cachedSortedResultsBySortersKey: {},
    chartResults: {},
    cellFilter: null,
    filterChips: [],
    filterChipGroups: [],
    filters: [],
    filterTreeData: {},
    filterValues: {}, // filter values currently set in filter page but not yet applied in analysis page
    filterValuesChanged: false,
    lastMetaCall: null,
    phoneCall: null,
    pivotResults: null,
    query: {},
    resultMap: {},
    sortedResultMeta: null,
    sortedResultMetaNoCellFilter: null,
    surveyJsMapping: {},
    view: null,
    viewUnmodified: null // the unmodified view is the view not extended by the role properties
  }
}

// state values which reset when source changes
let cleanSourceState = function() {
  return Object.assign({
    analysisSettingsTrees: null,
    analysisSidebar: 'bookmarks',
    analysisView: 'datasets',
    source: null,
    sourceUnmodified: null, // the unmodified source is the view not extended by the role properties,
    role: null,
    startPageHtml: {},
    views: []
  }, cleanViewState())
}

const store = new Vuex.Store({
  // state values which are kept when source changes
  state: Object.assign(cleanSourceState(), {
    activeTab: 'tab-view',
    authenticated: false,
    confirmDialogs: [],
    dataEntryDialogs: [],
    defaultVerbatimLang: 'en',
    error: false,
    errorMessage: null,
    history: {
      cursor: -1,
      length: 0
    },
    isProgress: false,
    loading: false,
    loadingCount: 0,
    loadingTimeStamp: null,
    linkedBookmarkId: null,
    notificationMessage: '',
    phoneCall: null,
    progress: null,
    ready: false,
    showNotification: false,
    startupData: null,
    startPageHtmlMap: {},
    surveyCreatorDialogs: [],
    user: {},
    verbatimLang: 'en',
    viewsMap: {}
  }),
  getters: {
    cellFilter: state => state.cellFilter,

    isEmptySourceViews: state => state.views.length === 0,
    
    queryWithFilter: state => { 
      let cellFilter = state.cellFilter
      if (cellFilter) {
        let queryWithFilter = Object.assign({}, state.query)

        for(let key in cellFilter) {
          let value = cellFilter[key]
          queryWithFilter[key] = Array.isArray(value) ? value : [value]     
        }
  
        return queryWithFilter
      } else {
        return state.query
      }
    }
  },
  mutations: {
    setPhoneCall(state, phoneCall) {
      state.phoneCall = phoneCall
    },  
    resetSource(state) {
      // acquire initial state
      const cS = cleanSourceState()
      Object.keys(cS).forEach(key => {
        state[key] = cS[key]
      })
    },
    resetView(state) {
      const cV = cleanViewState()
      Object.keys(cV).forEach(key => {
        state[key] = cV[key]
      })
    },
    setConfirmDialogs(state, confirmDialogs) {
      state.confirmDialogs = confirmDialogs
    },
    setDataEntryDialogs(state, dataEntryDialogs) {
      state.dataEntryDialogs = dataEntryDialogs
    },
    setSurveyCreatorDialogs(state, surveyCreatorDialogs) {
      state.surveyCreatorDialogs = surveyCreatorDialogs
    },
    setDefaultVerbatimLang(state, lang) {
      state.defaultVerbatimLang = lang
    },
    setReady(state, bool) {
      state.ready = bool
    },
    setLoading(state, bool) {
      state.loading = bool
    },
    setLoadingCount(state, loadingCount) {
      state.loadingCount = loadingCount
    },
    setLoadingTimestamp(state, loadingTimestamp) {
      state.loadingTimestamp = loadingTimestamp
    },
    setAuthenticated (state, bool) {
      state.authenticated = bool
    },
    setLastMetaCall(state, lastMetaCall) {
      state.lastMetaCall = lastMetaCall
    },
    setCellFilter (state, data) {
      state.cellFilter = data
    },
    setNotification(state, message) {
      if (!state.error) {
        state.loading = false
        state.notificationMessage = message
        state.showNotification = true
      }
    },
    setError(state, message) {
      state.error = message !== null
      state.errorMessage = message
    },
    setUser (state, data) {
      state.user = data
    },
    setSource(state, source) {
      state.source = source
    },
    setSourceUnmodified(state, sourceUnmodified) {
      state.sourceUnmodified = sourceUnmodified
    },
    setStartPageHtml(state, startPageHtml) {
      state.startPageHtml = startPageHtml
    },
    setRoleName(state, roleName) {
      state.roleName = roleName
    },
    setView(state, view) {
      state.view = view
    },
    setViewUnmodified(state, viewUnmodified) {
      state.viewUnmodified = viewUnmodified
    },
    setSources (state, data) {
      state.sources = data
    },
    setViews (state, data) {
      state.views = data
    },
    setFilters (state, data) {
      state.filters = data
    },
    setFilterValues (state, data) {
      state.filterValues = data
    },
    setFilterValuesChanged (state, data) {
      state.filterValuesChanged = data
    },
    setFilterChips (state, data) {
      state.filterChips = data

      let filterChips = data
      let filterChipGroupMap = {}
      let filterChipGroups = []

      filterChips.forEach(filterChip => {
        let filterChipGroup = filterChipGroupMap[filterChip.filter.name]

        if (!filterChipGroup) {
          filterChipGroup = {
            name: filterChip.filter.label,
            filterChips: []
          }
          filterChipGroupMap[filterChip.filter.name] = filterChipGroup
          filterChipGroups.push(filterChipGroup)
        }

        filterChipGroup.filterChips.push(filterChip)
      });

      state.filterChipGroups = filterChipGroups
    },
    setProgress (state, data) {
      state.progress = data
      state.isProgress = !!data
    },
    applyFilterValues (state) {
      state.appliedFilterValues = _.cloneDeep(state.filterValues)
      state.filterValuesChanged = false
    },
    setFilterTreeData(state, data) {
      state.filterTreeData[data.filterVarName] = data.options
    },
    setEmptyFilterValues (state) {
      let values = {}
      _.each(state.filters, function (filter) {
        values[filter.name] = filters.getEmptyFilterValue(filter)
      })
      state.filterValues = values
      state.filterTreeData = {}
    },
    unsetFilterVariable (state, name) {
      let filter = _.find(state.filters, {name: name})
      if (filter) {
        if (filter.treeLevels) {
          _.each(filter.treeLevels, filterVarName => {
            state.filterValues[filterVarName] = []
          })
        } else {
          state.filterValues[name] = filters.getEmptyFilterValue(filter)
        }
      }
    },
    setQuery (state, data) {
      state.query = data
    },
    setAnalysisView (state, data) {
      state.analysisView = data
    },
    setAnalysisSidebar (state, data) {
      state.analysisSidebar = data
    },        
    setAnalysisSettings (state, data) {
      state.analysisSettings = data
    },
    setAnalysisSettingsDatasetsDirty(state, data) {
      state.analysisSettingsDatasetsDirty = data
    },
    setAnalysisSettingsTabulationDirty(state, data) {      
      state.analysisSettingsTabulationDirty = data
    },
    setAnalysisSettingsChartDirty(state, data) {
      state.analysisSettingsChartDirty = data
    },
    setAnalysisSettingsError(state, data) {
      state.analysisSettingsError = data
    },
    setAnalysisSettingsTrees (state, data) {
      state.analysisSettingsTrees = data
    },
    setAnalysisSettingsModel(state, data) {


      //if (data) data.pop();
      //ToDo: Decide if we want to keep this
      // Add chart options model if not present in settings model from database
      if (data && (data.findIndex(e => e.name === 'chart_options') === -1)) {
       
         data.push({
          "name": "chart_options",
          "label": "Chart Options",
          "label_int": {
            "de": "Chart Options",
            "en": "Chart Options"
          },
          "description": "",
          "filter": "$view == 'chart'",
          "type": "options",
          "items": [
            {
              name: 'general',
              label: 'General',
              type: 'group',
              items: [    
                {
                  "name": 'type',
                  "label": 'Chart Type',
                  "type": 'list',
                  "settingsKey": "chart.type",
                  "default": 'column',
                  "listItems": [
                    {
                        "value": "line",
                        "label": "Line Chart",
                        "label_int": {
                            "de": "Liniendiagramm",
                            "en": "Line Chart"
                        }
                    },
                    {
                      "value": "spline",
                      "label": "Spline Chart",
                      "label_int": {
                          "de": "Splinediagramm",
                          "en": "Spline Chart"
                      }
                    },
                    {
                        "value": "column",
                        "label": "Column Chart",
                        "label_int": {
                            "de": "Säulendiagramm",
                            "en": "Column Chart"
                        },
                    },
                    {
                      "value": "bar",
                      "label": "Bar Chart",
                      "label_int": {
                          "de": "Balkendiagramm",
                          "en": "Bar Chart"
                      },
                    },                    
                    {
                        "value": "area",
                        "label": "Area Chart",
                        "label_int": {
                            "de": "Flächendiagramm",
                            "en": "Area Chart"
                        },
                    },
                    {
                      "value": "areaspline",
                      "label": "Area Spline Chart",
                      "label_int": {
                          "de": "Geglättetes Flächendiagramm",
                          "en": "Area Spline Chart"
                      },
                  },
                  /*  
                  {
                      "value": "pie",
                      "label": "Pie Chart",
                      "label_int": {
                          "de": "Tortendiagramm",
                          "en": "Pie Chart"
                      },
                  },
                  */
               /*   {
                    "value": "scatter",
                    "label": "Scatter Chart",
                    "label_int": {
                        "de": "Scatterdiagramm",
                        "en": "Scatter Chart"
                    },
                },*/
              /*  
                  {
                    "value": "pareto",
                    "label": "Pareto Chart",
                    "label_int": {
                        "de": "Pareto Diagramm",
                        "en": "Pareto Chart"
                    },
                },
                */           
            
                  ]
            },
            {
              "name": 'columnStacking',
              "label": 'Stacking',
              "type": 'list',
              "settingsKey": "plotOptions.series.stacking",
              "default": '[undefined]',
              "filter": "$options.chart.type in ['column', 'line', 'spline', 'bar', 'area', 'areaspline']",
              "listItems": [
                {
                    "value": "[undefined]",
                    "label": "none",
                    "label_int": {
                        "de": "none",
                        "en": "none"
                    }
                },
                {
                  "value": "normal",
                  "label": "normal",
                  "label_int": {
                      "de": "normal",
                      "en": "normal"
                  }
                },
                {
                  "value": "percent",
                  "label": "percent",
                  "label_int": {
                      "de": "percent",
                      "en": "percent"
                  }
                },
              ]
            },
            {
              name: 'enableLabels',
              label: 'Enable Data Labels',
              type: 'boolean',
              settingsKey: 'plotOptions.series.dataLabels.enabled',
              default: false
            },
            {
              name: 'width',
              label: 'Width',
              type: 'number',
              settingsKey: 'chart.width',
              default: "1340"
            },
            {
              name: 'height',
              label: 'Height',
              type: 'number',
              settingsKey: 'chart.height',
              default: "720"
            },
            {
              name: 'groupBy',
              label: 'Group By',
              type: 'list',
              settingsKey: '_other.groupBy',
              default: 'default',
              listItems: [
                {
                    "value": "default",
                    "label": "2nd field of columns and rows"                    
                },
                {
                  "value": "ratios",
                  "label": "Ratios"                    
                },
                {
                  "value": "invRatios",
                  "label": "Inverse Ratios"
                }
              ]
            }]},
            {
            name: 'titles',
            label: 'Titles',
            type: 'group',
            items: [            
              {
                name: 'title',
                label: 'Title',
                type: 'text',
                settingsKey: 'title.text',
                emptyText: '(none)'
              },
              {
                name: 'subtitle',
                label: 'Subtitle',
                type: 'text',
                settingsKey: 'subtitle.text',
                emptyText: '(none)'
              },
              {
                name: 'xAxisTitle',
                label: 'X-Axis Title',
                type: 'text',
                settingsKey: 'xAxis.title.text',
                emptyText: '(none)'
              },
              {
                name: 'yAxisTitle',
                label: 'Y-Axis Title',
                type: 'text',
                settingsKey: 'yAxis.title.text',
                emptyText: '(auto)'
              },
              {
                name: 'creditsText',
                label: 'Credits Text',
                type: 'text',
                settingsKey: 'credits.text',
                emptyText: '',
                default: 'TEMA-Q WOENENN'
              },
              {
                name: 'isCreditsLink',
                label: 'Include Link in Credits',
                type: 'boolean',
                settingsKey: 'credits._isLink',
                default: true
              },
            ]
          },{
            name: 'xaxis',
            label: 'X Axis',
            type: 'group',
            items: [ 
              
              // Not working properly
              /*
              {
                name: 'dataSorting',
                label: 'Sorted',
                type: 'boolean',
                settingsKey: 'plotOptions.series._sortData',
                default: false
              },
              */
              {
                name: 'dataSorting',
                label: 'Sorting',
                type: 'boolean',
                settingsKey: 'plotOptions.series._sortDir',
                default: 'none',
                listItems: [
                  {
                    "value": "none",
                    "label": "None"
                  },
                  {
                    "value": "asc",
                    "label": "Ascending"
                  },
                  {
                    "value": "desc",
                    "label": "Descending"
                  }
                ]
              },
              {
                name: 'xReversed',
                label: 'Reversed',
                type: 'boolean',
                settingsKey: 'xAxis.reversed',
                default: false
              },{
                name: 'xOpposite',
                label: 'Opposite Side of Chart',
                type: 'boolean',
                settingsKey: 'xAxis.opposite',
                default: false
              },
              {
                name: 'xSoftMin',
                label: 'Minimum',
                type: 'number',
                settingsKey: 'xAxis.softMin',
                default: ''
              },
              {
                name: 'xSoftMax',
                label: 'Maximum',
                type: 'number',
                settingsKey: 'xAxis.softMax',
                default: ''
              },
              {
                name: 'xTickInterval',
                label: 'Tick Interval',
                type: 'number',
                settingsKey: 'xAxis.tickInterval',
                default: ''
              },  
              {
                name: 'xAutoRotation',
                label: 'Auto Rotation',
                type: 'boolean',
                valueMapping: {true: [-45], false: false},
                settingsKey: 'xAxis.labels.autoRotation',
                default: false
              },
            ]},            
            {
            name: 'yaxis',
            label: 'Y Axis',
            type: 'group',
            items: [ 
            {
              name: 'yType',
              label: 'Type',
              type: 'list',
              settingsKey: 'yAxis.type',
              default: 'linear',
              listItems: [
                {
                    "value": "linear",
                    "label": "Linear"                    
                },
                {
                  "value": "logarithmic",
                  "label": "Logarithmic"                    
                },
                {
                  "value": "datetime",
                  "label": "Datetime"                    
                },
                {
                  "value": "category",
                  "label": "Category"                    
                },
              ]
            },
            {
              name: 'yReversed',
              label: 'Reversed',
              type: 'boolean',
              settingsKey: 'yAxis.reversed',
              default: false
            },
            {
              name: 'yOpposite',
              label: 'Opposite Side of Chart',
              type: 'boolean',
              settingsKey: 'yAxis.opposite',
              default: false
            },
            {
              name: 'yReversedStacks',
              label: 'Reversed Stacks',
              type: 'boolean',
              settingsKey: 'yAxis.reversedStacks',
              default: true
            }, 
            {
              name: 'ySoftMin',
              label: 'Minimum',
              type: 'number',
              settingsKey: 'yAxis.softMin',
              default: ''
            },
            {
              name: 'ySoftMax',
              label: 'Maximum',
              type: 'number',
              settingsKey: 'yAxis.softMax',
              default: ''
            },
            {
              name: 'yTickInterval',
              label: 'Tick Interval',
              type: 'number',
              settingsKey: 'yAxis.tickInterval',
              default: ''
            },  
          ]},
              /*
          {
            name: 'colors',
            label: 'Colors',
            type: 'group',
            items: [            
              {
                name: 'chartColors',
                label: 'Chart Colors',
                type: 'color_array',
                settingsKey: 'colors',
                default: ['#0072C0', '#7DD259', '#F3AB55', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
              },
              
            ]
          },*/
            {
              name: 'series',
              label: 'Series',
              type: 'group',
              items: [
                {
                  name: 'chartSeries',
                  type: 'series_array',
                  settingsKey: 'chart_series',
                  default: [
                    {color: '#0072C0', type: 'inherit'},
                    {color: '#7DD259', type: 'inherit'},
                    {color: '#F3AB55', type: 'inherit'},
                    {color: '#910000', type: 'inherit'},
                    {color: '#1aadce', type: 'inherit'},
                    {color: '#492970', type: 'inherit'},
                    {color: '#f28f43', type: 'inherit'},
                    {color: '#77a1e5', type: 'inherit'},
                    {color: '#c42525', type: 'inherit'},
                    {color: '#a6c96a', type: 'inherit'}
                  ]
                },

              ]
            },
          {
            name: 'legend',
            label: 'Legend',
            type: 'group',
            items: [            
              {
                name: 'enabled',
                label: 'Enable Legend',
                type: 'boolean',
                settingsKey: 'legend.enabled',
                default: true
              },
              {
                name: 'layout',
                label: 'Item Layout',
                type: 'list',
                settingsKey: 'legend.layout',
                default: 'horizontal',
                listItems: [
                  {
                    "value": "horizontal",
                    "label": "horizontal"                    
                  },
                  {
                    "value": "vertical",
                    "label": "vertical"                    
                  },
                  {
                    "value": "proximate",
                    "label": "proximate"                    
                  },
                ]
              },
              {
                name: 'verticalAlignment',
                label: 'Vertical Alignment',
                type: 'list',
                settingsKey: 'legend.verticalAlign',
                default: 'bottom',
                listItems: [
                  {
                    "value": "top",
                    "label": "top"                    
                  },
                  {
                    "value": "middle",
                    "label": "middle"                    
                  },
                  {
                    "value": "bottom",
                    "label": "bottom"                    
                  }
                ]
              },
              {
                name: 'horizontalAlignment',
                label: 'Horizontal Alignment',
                type: 'list',
                settingsKey: 'legend.align',
                default: 'center',
                listItems: [
                  {
                    "value": "left",
                    "label": "left" 
                  },
                  {
                    "value": "center",
                    "label": "center"                    
                  },
                  {
                    "value": "right",
                    "label": "right"                    
                  }
                ]
              },
              {
                name: 'ratioVariableAsName',
                label: 'Ratio variable as label',
                type: 'boolean',
                settingsKey: '_other.ratioVariableAsName',
                default: false
              },
            ]
          },
          {
            name: 'tooltip',
            label: 'Tooltip',
            type: 'group',
            items: [            
              {
                name: 'enabled',
                label: 'Enable Tooltip',
                type: 'boolean',
                settingsKey: 'tooltip.enabled',
                default: true
              },
              {
                name: 'shared',
                label: 'Shared between series',
                type: 'boolean',
                settingsKey: 'tooltip.shared',
                default: false
              },
            ]
          },
          {
            name: 'caption',
            label: 'Caption',
            type: 'group',
            items: [    
              {
                name: 'text',
                label: 'Caption Text',
                type: 'mtext',
                settingsKey: 'caption.text',
                default: ''
              },        
              {
                name: 'align',
                label: 'Horizontal alignment',
                type: 'list',
                settingsKey: 'caption.align',
                default: 'left',
                listItems: [
                  {
                    "value": "left",
                    "label": "left" 
                  },
                  {
                    "value": "center",
                    "label": "center"                    
                  },
                  {
                    "value": "right",
                    "label": "right"                    
                  }
                ]
              },
              {
                name: 'verticalAlign',
                label: 'Vertical alignment',
                type: 'list',
                settingsKey: 'caption.verticalAlign',
                default: 'bottom',
                listItems: [
                  {
                    "value": "top",
                    "label": "top" 
                  },
                  {
                    "value": "middle",
                    "label": "middle"                    
                  },
                  {
                    "value": "bottom",
                    "label": "bottom"                    
                  }
                ]
              },
              
            ]
          },
          {
            name: 'other',
            label: 'Other',
            type: 'group',
            items: [    
              {
                name: 'total',
                label: 'Show Total Values',
                type: 'boolean',
                settingsKey: '_other.showTotal',
                default: false
              }, 
              {
                name: 'maxValueCount',
                label: 'Displayed data limit',
                type: 'number',
                settingsKey: '_other.displayedDataLim',
                default: ''
              },
              {
                name: 'negativeCategories',
                label: 'Negative Categories',
                type: 'numbers_text',
                settingsKey: '_other.negativeCategories',
                default: ''
              },
              {
                name: 'json',
                label: 'Auxiliary JSON',
                type: 'json',  
                settingsKey: '_other.auxJSON',
                default: ''          
              },
              {
                name: 'htmlWidth',
                label: 'HTML Width',
                type: 'text',
                settingsKey: '_other.htmlWidth',
                default: '100%'
              },
              {
                name: 'htmlHeight',
                label: 'HTML Height',
                type: 'text',
                settingsKey: '_other.htmlHeight',
                default: '600px'
              }     
              
            ]
          }
        ] 
       })
      }
      state.analysisSettingsModel = data
    },
    setPivotResults(state, data) {
      state.pivotResults = data
    },
    setChartResults(state, data) {
      state.chartResults = data
    },
    setSortedResultMeta(state, data) {
      state.sortedResultMeta = data
    },
    setSortedResultMetaNoCellFilter(state, data) {
      state.sortedResultMetaNoCellFilter = data
    },
    setResultMap(state, data) {
      state.resultMap = data
    },
    setCachedResultObjBySortIndexKey(state, data) {
      state.cachedSortedResultsBySortersKey = data
    },
    setActiveTab(state, data) {
      state.activeTab = data
    },
    setHistory(state, data) {
      state.history = data
    },
    setStartupData(state, data) {
      state.startupData = data
    },
    setLinkedBookmarkId(state, data) {
      state.linkedBookmarkId = data
    }
  },
  actions: {
    setPhoneCall({ commit }, phoneCall) {
      commit('setPhoneCall', phoneCall)
    },

    setReady({commit}, bool) {
      commit('setReady', bool)
    },

    resetLoading({ dispatch, commit }) {
      commit('setLoadingCount', 0)
      dispatch('setLoading', false)
    },

    setLoading({ commit, state }, bool) {
      if (!state.error) {
        /*if (!bool && ((Date.now() - state.loadingTimestamp) > 200)) {
          await Util.asyncTimeout(2000)

          if (state.error) {
            return
          }
        } else if (state.loadingCount == 0) {
          commit('setLoadingTimestamp', Date.now())
        }*/

        commit('setLoadingCount', bool ? ++state.loadingCount : --state.loadingCount)

        if (state.loadingCount < 0) {
          commit('setLoadingCount', 0)
          commit('setLoading', false)
          
          return
        }

        if (freezeLoadingCount === 0) {
          let loading = state.loadingCount > 0

          commit('setLoading', loading)
        }
      }
    },

    freezeLoading({ dispatch }) {
      ++freezeLoadingCount
      
      dispatch('setLoading', false)
    },

    unfreezeLoading({ state, dispatch }) {
      --freezeLoadingCount

      if (freezeLoadingCount < 0) {
        throw new Error('freeze loading count < 0')
      }

      if (freezeLoadingCount === 0) {
        dispatch('setLoading', state.loadingCount > 0)
      }
    },

    setAuthenticated ({ commit }, bool) {
      commit('setAuthenticated', bool)
    },

    setCellFilter({commit}, data) {
      commit('setCellFilter', data)
    },

    setConfirmDialogs({commit}, data) {
      commit('setConfirmDialogs', data)
    },

    setDataEntryDialogs({commit}, data) {
      commit('setDataEntryDialogs', data)
    },

    setSurveyCreatorDialogs({commit}, data) {
      commit('setSurveyCreatorDialogs', data)
    },

    setUser ({commit}, data) {
      commit('setUser', data)
    },

    unsetFilterVariable({commit}, name) {
      commit('unsetFilterVariable', name)
    },

    reset({commit, dispatch}) {
      commit('setLoadingCount', 0)
        
      dispatch('resetSourceView')
      dispatch('resetSource')
    },

    resetSource({commit}) {
      commit('resetSource')
    },

    async setSource({state, commit}, source) {
      if (state.source !== source) {
        commit('resetSource')
      
        let role = (state.roleName && source.roles) && source.roles.find(role => role.name == state.roleName)

        commit('setSourceUnmodified', source)
        commit('setSource', role ? _.merge(source, _.omit(role, ['name', 'label', 'description', 'users', 'viewVals'])) : source)
      } else {
        Promise.resolve()
      }
    },

    setSources({commit}, data) {
      commit('setSources', data)
    },

    setStartPageHtml({commit}, data) {
      commit('setStartPageHtml', data)
    },

    setRoleName({state, commit}, data) {
      commit('setRoleName', data)
    },

    setView({commit}, data) {
      commit('setView', data)
    },

    clearFilterValues({state}, data) {
      // TODO: There is an issue with this code
      //       The watch on filter values is not working correctly for the naive approach
      //       The reaon for this is not understood and should be investigated

      // This line should work but does not:
      // state.filterValues = {}

      // START workaround
      state.filters.forEach(filter => {
        if (state.filterValues[filter.name]) {
          state.filterValues[filter.name] = filters.getEmptyFilterValue(filter)
        }
      })
      // END workaround
    },

    assignFilterValues({dispatch, state}, data) {
      dispatch('clearFilterValues', data)

      const variables = state.view.variables

      _.each(data, (v, k) => {
        let variable = variables.find(variable => variable.name === k)

        if (!variable) {
          //throw new Error(`variable "${k}" is not defined in "${state.view.name}"`)
        } else {
          try {
            VariableModel.checkFilterValue(v, variable)
          } catch (e) {
            console.error(data)
            error.runtimeError(e, { hideReload: true })
          }
        }
        state.filterValues[k] = v
      })
    },

    setFilterValues({commit}, data) {
      commit('setFilterValues', data)
    },

    setFilterTreeData({commit}, data) {
      commit('setFilterTreeData', data)
    },

    setNotification({commit}, message) {
      commit('setNotification', message)
    },

    setError({commit, state}, data) {
      state.loading = false
      state.showNotification = false

      //commit('resetDialogs')
      commit('setError', data)
    },

    removeError({commit, state}) {
      commit('setError', null)
    },

    setSortedResultMeta({commit, state}, data) {
      if (!state.cellFilter) {
        commit('setSortedResultMetaNoCellFilter', data)
      } else if (data && state.sortedResultMetaNoCellFilter) {
        commit('setSortedResultMetaNoCellFilter', Object.assign(state.sortedResultMetaNoCellFilter, {
          ds: data.ds
        }))
      }
      commit('setSortedResultMeta', data)
    },

    initAnalysis({commit, state, dispatch}, data) {
      dispatch('setLoading', true)
 
      return new Promise(async (resolve) => {
        try {
          let done = function(settings) {
            dispatch('setLoading', false)
            resolve(settings)
          }
          
          if (state.analysisSettings) {
            return done(state.analysisSettings)
          }
          
          let settings = data.analysisSettings

          let settingsModel = await this.dispatch('getAnalysisSettingsModel')

          if (settings == null) {
            // create default settings
            settings = {}

            for (let settingsModelItem of settingsModel) {
              settings[settingsModelItem.name] = settingsModelItem.default;                            
            }
            
            // Validate settings model
            let validateSettingsModel = function(path, modelValue) {
              if (modelValue !== null) {
                if (Array.isArray(modelValue)) {
                  for (let i = 0; i < modelValue.length; i++) {
                    validateSettingsModel(path + '[' + i + ']', modelValue[i])
                  }
                } else if (typeof modelValue == 'object') {
                  Object.keys(modelValue).forEach(function(key,index) {
                      validateSettingsModel(path + '.' + key, modelValue[key])
                  });
                } else if (typeof modelValue == 'boolean') {
                  console.warn('Use string instead of boolean for setting "' + path)
                }
              }
            }
            validateSettingsModel('', settingsModel)
          } else {
            for (let settingsItem of settingsModel) {
              if (settings[settingsItem.name] === undefined) {
                settings[settingsItem.name] = settingsItem.default;                      
              }
            }
          }
          
          completeAnalysisSettings(settings, settingsModel)      
          commit('setAnalysisSettings', settings)
          done(settings)
        } catch (e) {
          error.runtimeError(e)
        }
      })
    },

    setAnalysisView({commit}, data) {
      commit('setAnalysisView', data)
    },

    setAnalysisSidebar({commit}, data) {
      commit('setAnalysisSidebar', data)
    },

    setAnalysisSettings({commit}, data) {
      commit('setAnalysisSettings', data)
    },

    setProgress({ commit }, data) {
      commit('setProgress', data)
    },

    getAnalysisSettingsTrees({commit, state}) {
      if (state.analysisSettingsTrees) {
        return state.analysisSettingsTrees
      } else {
        return new Promise((resolve) => {
          api.call('getAnalysisSettingsTrees', {}, analysisSettingsTrees => {
            commit('setAnalysisSettingsTrees', analysisSettingsTrees)
            resolve(analysisSettingsTrees)
          })
        })
      }
    },

    async getPageHtml({dispatch, state}) {
      let sourceName = state.source.name
      let roleName = state.roleName

      let path = [sourceName]
    
      if (roleName) {
        path.push(roleName)
      }

      let startPageHtml = _.get(state.startPageHtmlMap, path)

      if (!startPageHtml) {
        startPageHtml = (await api.call('getPageHtml')).data

        _.set(state.startPageHtmlMap, path, startPageHtml)
      }

      dispatch('setStartPageHtml', startPageHtml)
    },

    getAnalysisSettingsModel({state}) {
      return state.analysisSettingsModel
    },

    getSources ({dispatch, commit, state}) {
      return new Promise((resolve) => {
        dispatch('setLoading', true)

        const done = (sources) => {
          resolve(sources)
        }

        if (state.sources) {
          return done(state.sources)
        }
          
        api.call('getSources', { params: { includeViews: false } }, sources => {
          dispatch('setSources', sources)
        
          done(sources)

          dispatch('setLoading', false)
        })
      })
    },

    async getSourceViews({commit, dispatch, state}) {
      if (!state.source) {
        let views = []

        commit('setViews', views)
        
        return views
      } else {
        dispatch('setLoading', true)

        let views = util.getByPath(state.viewsMap, state.source.name, state.roleName)

        if (!views) {
          views = (await api.call('getSourceViews', { sourceName: state.source.name })).data
          util.setByPath(state.viewsMap, views, state.source.name, state.roleName)
        }

        commit('setViews', views)

        dispatch('setLoading', false)

        return views
      }
    },

    getSurveyJsMapping: util.AsyncPromiseQueue(async ({ state }, params) => {
      try {
        let key = state.source.name + '/' + params.viewName + '/' + params.collectionName 
        let mapping = state.surveyJsMapping[key]

        if (!mapping) {
          mapping = (await api.call('getSurveyJsMapping', {
            viewName: state.view.name,
            collectionName: params.collectionName
          })).data
          
          state.surveyJsMapping[key] = mapping
        }

        return mapping
      } catch (e) {
        error.runtimeError(e)
      }
    }),

    resetSourceView({commit}) {
      commit('setQuery', {})
      commit('setView', null)
      commit('setFilters', [])
      commit('setAnalysisSettingsModel', null)
      commit('setEmptyFilterValues')
      commit('applyFilterValues')
      commit('setAnalysisSettings', null)
    },

    async getSourceView({commit, dispatch, state}, params) {
      let viewName = params.viewName

      if (state.view && state.view.name == viewName && state.view.roleName == state.roleName) {
        return state.view
      }

      dispatch('setLoading', true)

      let view = (await api.call('getSourceView', {
        sourceName: state.source.name,
        viewName: viewName
      })).data

      commit('setViewUnmodified', view)
      commit('setView', view)
      commit('setFilters', view.filters)
      commit('setAnalysisSettingsModel', view.analysisSettingsModel)
      commit('setEmptyFilterValues')
      commit('applyFilterValues')
      
      dispatch('setLoading', false)

      return view
    },

    setQueryFromFilter({commit, dispatch, state}) {
      return new Promise(async resolve => {
        try {
          let query = {}

          commit("applyFilterValues")

          _.each(state.filterValues, (v, k) => {
            if (v !== null) {
                if (_.isArray(v) && v.length !== 0 && v[0]) {
                // Array or string/date/number filter
                query[k] = _.cloneDeep(v)
              }
              else if (_.isObject(v)) {
                // Nested filter
                _.each(v, (optionsArray, varName) => {
                  if (optionsArray.length > 0) {
                    query[varName] = _.cloneDeep(optionsArray)
                  }
                })
              }
              else if (!_.isArray(v) && v !== "") {
                // String, Number
                query[k] = _.cloneDeep(v)
              }
            }
          })
          commit('setQuery', query)

          commit('setCachedResultObjBySortIndexKey', {})
          dispatch('setSortedResultMeta', null)
          commit('setCellFilter', null)

          await store.dispatch('getSourceViewSortedResultsMeta', {
            verbatimVar: state.analysisSettings.level === 'verbatim' ? state.analysisSettings.verbatim_var : null
          })
        
          resolve()
        } catch (e) {
          error.runtimeError(e)
        }
      })
    },

    getSourceViewSortedResultsMeta: util.AsyncPromiseQueue(async ({ commit, dispatch, state, getters }, params) => {
      let verbatimVar = params.verbatimVar || null
      let noCache = params.noCache

      try {
        let sortedResultMeta = state.sortedResultMeta

        // get meta data
        
        let metaCall = {
          sourceName: state.source.name,
          viewName: state.view.name,
          params: _.assign({}, getters.queryWithFilter, {
            mode: 'meta',
            verbatimVar: verbatimVar
          }),
        }

        if (!noCache && state.sortedResultMeta && _.isEqual(metaCall, state.lastMetaCall)) {
          return state.sortedResultMeta
        }

        commit('setLastMetaCall', metaCall)

        dispatch('setLoading', true)

        sortedResultMeta = (await api.call('getSourceViewSortedResultIds', metaCall)).data

        dispatch('setSortedResultMeta', sortedResultMeta)

        dispatch('setLoading', false)

        return sortedResultMeta
      } catch (e) {
        error.runtimeError(e)
      }
    }),

    getSourceViewSortedResults: util.AsyncPromiseQueue(async ({ commit, dispatch, state, getters }, params) => {
      try {
        let sorters = params.sorters
        let end = params.end
        let verbatimVar = params.verbatimVar || null

        // make sure metadata is already loaded
        await dispatch('getSourceViewSortedResultsMeta', {
          verbatimVar: verbatimVar
        })
        
        let resultMap = state.resultMap
        let cachedSortedResultsBySortersKey = state.cachedSortedResultsBySortersKey
        let sortersKey = sorters.map(sorter => sorter.colId + '_' + sorter.sort).join('__')
        let cachedSortedResults = cachedSortedResultsBySortersKey[sortersKey]

        let sortedResults, cachedLimit

        if (!cachedSortedResults) {
          cachedLimit = 0
          sortedResults = []

          cachedSortedResultsBySortersKey[sortersKey] = [sortedResults, end]
        } else {
          [sortedResults, cachedLimit] = cachedSortedResults
        }

        // get sorted ids
        if (cachedLimit < end && end > sortedResults.length && sortedResults.length < state.sortedResultMeta.ds) {
          let params = _.assign({}, getters.queryWithFilter, {
            skip: sortedResults.length,
            limit: end - sortedResults.length,
            verbatimVar: verbatimVar,
            mode: 'sorted_ids'
          })

          let data = {
            sorters: sorters
          }

          let sortedIdIndexTuples = (await api.call('getSourceViewSortedResultIds', {
            sourceName: state.source.name,
            viewName: state.view.name,
            params: params,
            data: data
          })).data

          let loadIds = _.uniq(sortedIdIndexTuples.map(sortedIdIndexTuple => sortedIdIndexTuple[0]).filter(id => !resultMap[id]))

          // get results
          if (loadIds.length > 0) {
            let results = (await api.call('getSourceViewSortedResultIds', {
              sourceName: state.source.name,
              viewName: state.view.name,
              params: {
                mode: 'data'
              },
              data: {
                ids: loadIds
              }
            })).data

            results.forEach(result => {
              resultMap[result._id] = result
            })
          }

          let newSortedRelusts = sortedIdIndexTuples.map(sortedIdIndexTuple => {
            return [resultMap[sortedIdIndexTuple[0]], sortedIdIndexTuple[1]]
          })

          sortedResults.splice(sortedResults.length, 0, ...newSortedRelusts)
        }

        if (sortedResults.length === 0) {
          commit('setNotification', t('texts.NO_DATA_FOUND'))
        }

        return sortedResults
      } catch (e) {
        error.runtimeError(e)
      }
    }),

    getSourceViewPivotResults ({commit, getters, state}, params) {
      return new Promise((resolve) => {
        let ratios = state.analysisSettings.ratios;
        
        for (let i = 0; i < ratios.length; i++) {
          let ratio = ratios[i];
          ratio.id = ratio.name + '_' + (i + 1);
        }

        // Generate query from analysis settings
        let queryData = {
          analysis: 'tabulation',
          ratios: ratios,
          filters: state.query // as requested in issue 643
        };
        for (let settingsModelEntry of state.analysisSettingsModel) {
          let settingsKey = settingsModelEntry.name;
          if (evalExpression(settingsModelEntry.filter, {view: 'tabulation', settings: state.analysisSettings})) {
            queryData[settingsKey] = queryData[settingsKey] || state.analysisSettings[settingsKey];
          }
        }

        // profiling
        let starttime = Date.now();

        api.call('getSourceViewPivotResults', {
          sourceName: state.source.name,
          viewName: state.view.name,
          data: {
            filter: state.query,
            querydata: queryData,
            noCache: params.noCache
          },
        }, res => {
          // profiling
          let profilingData = res.profilingData;
          if (profilingData) {
            profilingData.push("total serverside query + network: " + (Date.now() - starttime));
            //console.log("--- Profiling Data Tabulation Results ---");
            //console.log(JSON.stringify(profilingData, null, 2));
          }

          res.ratios = ratios
          commit('setPivotResults', res)
          if (res.rows == null || res.rows.length === 0 || res.columns == null || res.columns.lenght === 0) {
            commit('setNotification', t('texts.NO_DATA_FOUND'))
          }
          resolve()
        })
      })
    },

    getQueryUrl({ state }, params) {
      return new Promise((resolve) => {
        let query = {}

        //query.v = store.state.source ? store.state.source.version : null
        query.filter = params.filter ? url.toFilterUrl(state.filterValues) : undefined
        query.analysisSettings = params.analysisSettings ? url.toAnalysisSettingsUrl(state.analysisSettings) : undefined
        query.analysisSidebar = params.analysisSidebar ? state.analysisSidebar : undefined

        resolve(query)
      })
    },

    getStartupData({ state, dispatch }, params) {
      return new Promise((resolve) => {
        if (state.startupData) {
          resolve(state.startupData)
        } else {
          api.call('getStartupData', {}, response => {
            dispatch('setStartupData', response)
            resolve(response)
          })
        }
      })
    },

    async deleteSourceViewResult({ state, dispatch }, params) {
      dispatch('setLoading', true)

      delete state.resultMap[params.id]

      await api.call('deleteSourceViewResult', {
        id: params.id
      })

      dispatch('setLoading', false)
    },

    async updateSourceResultDescription({ state, dispatch }, params) {
      let id = params.id
      let action = params.action
      let data = params.data

      let newData = {}
      for (let key in data) {
        let val = data[key]
        key = key.replace(/---/g, '.')

        newData[key] = val 
      }

      dispatch('setLoading', true)

      let updateData = (await api.call('updateSourceResultDescription', {
        id: id,
        data: newData,
        params: {
          dataCollection: store.state.view.data,
          action: action
        }
      })).data

      dispatch('setLoading', false)

      return updateData
    },

    getHistory({ commit }, params) {
      return new Promise(async resolve => {
        let options = {
          direction: params.direction
        }

        if (params.direction === 'back') {
          options.params = {
            path: router.currentRoute.fullPath
          }
        }

        let historyData = (await api.call('getHistory', options)).data

        commit('setHistory', historyData.history)

        resolve(history.data)
      })
    },
    
    setHistory({ commit }, data) {
      commit('setHistory', data)
    },
    

    setStartupData({ commit }, data) {
      commit('setStartupData', data)
    },

    setLinkedBookmarkId({ commit }, data) {
      commit('setLinkedBookmarkId', data)
    }
  }
})

store.watch(
  state => state.filterValues,
  filterValues => {
    for (let key in filterValues) {
      let filter = store.state.filters.find((filter => filter.name === key))

      if (!filter) {
        // remove unknown filter
        delete filterValues[key]
      }
    }

    store.commit('setFilterValuesChanged', !_.isEqual(store.state.appliedFilterValues, filterValues))
    store.commit('setFilterChips', filters.createFilterChips(filterValues, store.state.filters))
  },
  {  deep: true }
)

export default store
