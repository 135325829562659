<template>
   <div class="survey-wrapper" ref="survey"></div>
</template>

<script>
export default {
  name: 'Survey',
  props: {
    survey: Object
  },
  watch: {
    survey(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.destroy(oldVal)
        this.init(newVal)  
      }
    }
  },
  methods: {
    init(survey) {
      if (survey) {
        survey.render(this.$refs.survey)
      }
    },
    destroy(survey) {
      if (survey) {
        survey.dispose()
      }
    }
  },
  mounted() {
    this.init(this.survey)
  },
  beforeDestroy() {
    this.destroy(this.survey)
  }
}
</script>

<style lang="scss"scoped>
  .survey-wrapper {
    flex: 1 1 0;
    display: flex;

    :deep {
      > div {
        overflow: auto;
      }
    }
  }
</style>