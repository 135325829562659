import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Start from '@/pages/Start'
import Analysis from "@/pages/Analysis"
import Tools from "@/pages/Tools"

import api from '@/services/api'
import error from '@/services/error'
import { getInstance } from '@/auth'
import { authGuard } from '@/auth/authGuard'
import db from '@/services/db'

  
Vue.use(VueRouter)

let routingError = null

const routeToError = e => {
  console.log(new Error())
  console.error(e)
  routingError = e
  router.push({ name: 'routingError' })
}

const router = new VueRouter({
  mode: "history",
  routes: [{
    path: "/",
    name: "start",
    beforeEnter: async (to, from, next) => {
      try {
        let {
          roleName,
          sourceName
        } = await db.adjustBaseParams()
        
        next({ name: 'sourceRedirect', params: { sourceName: sourceName, roleName: roleName } })
      } catch(e) {
        routeToError(e)
      }
    }
  }, {
    path: "/rE",
    name: "routingError",
    component: {
      async mounted() {
        if (routingError) {
          let tempRoutingError = routingError
          routingError = null
          error.throw(tempRoutingError)
        } else {
          window.location = '/tools/reset'
        }
      },
      template: '<div></div>'
    }
  }, {
    path: "/logout",
    name: "logout",
    beforeEnter: function(to, from, next) {
      try {
        let auth = getInstance()
        auth.logout()
        next({ name: 'start' })
      } catch(e) {
        routeToError(e)
      }
    }
  }, {
    path: "/bookmark/:sourceName/:bookmarkId",
    name: "bookmark",
    beforeEnter: async (to, from, next) => {
      try {
        let sourceName = to.params.sourceName
        let bookmarkId = to.params.bookmarkId

        let data = (await api.call('getAnalysisSettings', {
          analysisSettingId: bookmarkId,
          sourceName: sourceName
        })).data

        let analysisSettings = data.analysisSettings
        let analysisView = data.analysisView
        let filter = data.filter
        let viewName = data.viewName

        store.dispatch('setLinkedBookmarkId', bookmarkId)

        next({ 
          name: 'analysisRedirect3',
          params: {
            sourceName: sourceName, 
            viewName: viewName
          },
          query: {
            analysisView: analysisView,
            filter: filter,
            analysisSettings: analysisSettings,
            analysisSidebar: 'bookmarks'
          }
        })
      } catch(e) {
        routeToError(e)
      }
    }
  }, {
    path: "/tools/:action",
    name: "tools",
    component: Tools
  }, {
    path: "/redirect/:sourceName/:roleName?/:viewName",  
    name: "redirect",
    component: {
      async mounted() {
        let { roleName, sourceName, viewName } = await db.adjustBaseParams(this.$route.params.sourceName, this.$route.params.roleName, this.$route.params.viewName)

        let params = Object.assign({}, { roleName, sourceName, viewName }, _.omit(this.$route.params,  ['viewName', 'sourceName', 'roleName']))

        this.$router.replace({ name: router._from.name, params: params, query: this.$route.query })
      },
      template: '<div></div>'
    }
  }, {
    path: "/source_redirect/:sourceName/:roleName?",  
    name: "sourceRedirect",
    component: {
      async mounted() {
        // beforeEnter lifecycle hook is not used to allow beforeDestroy to complete in router component before

        this.$router.replace({ name: 'sourceStart', params: this.$route.params })
      },
      template: '<div></div>'
    }
  }, {
    // short links with an mongodb ObjectId
    path: "/:linkId([a-f\\d]{24})",
    name: "link",
    beforeEnter: async (to, from, next) => {
      try {
        let link = (await api.call('getLink', { linkId: to.params.linkId })).data
        router.replace(link.path)
      } catch(e) {
        routeToError(e)
      }
    }
  }, {
    path: "/:sourceName/analysis",
    name: "analysisRedirect2",
    beforeEnter: async (to, from, next) => {
      try {
        let {
          roleName,
          sourceName,
          viewName
        } = await db.adjustBaseParams(to.params.sourceName, to.params.roleName)
        
        next({ name: 'analysis', params: { sourceName: sourceName, viewName: viewName, roleName: roleName } })
      } catch(e) {
        routeToError(e)
      }
    }
  }, {
    path: "/:sourceName/:roleName?",
    name: "sourceStart",
    component: Start
  }, {
    path: "/:sourceName/:roleName?/:viewName/analysis/redirect",
    name: "analysisRedirect3",
    component: {
      async mounted() {
        /*
        * Reset view and redirect to analysis
        * 
        * This is a workaround needed to open a different view from inside the analysis component. When routing to
        * a path /:sourceName/:viewName/analysis from within analysis the analyis component is not rendered, because VUE router
        * does not render when the basic route stays the same.
        * 
        * It's also necessary to call the redirect inside the component because inside beforeLoad callback of this route also does
        * not trigger a rerender of the component 
        * 
        */

        let route = this.$route
        let query = route.query
        let params = route.params

        await store.dispatch('resetSource')
        await store.dispatch('resetSourceView')
        
        route.meta.skipHistory = true

        this.$router.replace({ name: 'analysis', params: params, query: query })
      },
      template: '<div></div>'
    }
  }, {
    path: "/:sourceName/:roleName?/:viewName/analysis",
    name: "analysis",
    component: Analysis
  }, {
    // redirect to start for unknown routes
    path : '*', 
    redirect: '/'
  }]
})

router.beforeEach(authGuard)

router.beforeEach(async (to, from, next) => {
  try {
    router._from = from

    if ([
      'bookmark',
      'link',
      'logout',
      'redirect',
      'routingError',
      'sourceRedirect',
      'start',
      'analysisRedirect2',
      'analysisRedirect3'
    ].indexOf(to.name) != -1) {
      return next()
    }

    if (!store.state.ready) {
      await store.dispatch('getStartupData')
    }

    let params = to.params


    await db.getBase(params.sourceName, params.roleName, params.viewName)

    store.dispatch('setReady', true)

    if (
        from &&
        from.fullPath !== to.fullPath &&
        from.name === "analysis" &&
        from.query.analysisSettings &&
        from.params.sourceName && 
        from.params.viewName &&
        from.params.sourceName === to.params.sourceName &&
        from.params.viewName === to.params.viewName
      ) {
      // write history without await
      api.call('pushHistory', {
        data: { path: from.fullPath }
      }).then(history => {
        store.dispatch('setHistory', history.data.history)
      })
    } 

    if (to.name === "sourceStart" || to.name === "analysis") {
      store.commit('setActiveTab', to.name)
    }

    return next()
  } catch(e) {
    error.runtimeError(e)
  }
})

export default router
