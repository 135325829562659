import Vue from "vue"
import createAuth0Client from "@auth0/auth0-spa-js"
import store from '@/store'
import { AUTH0_CONFIG } from '@/config'

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        error: null,
        isAuthenticated: false,
        loading: true,
        popupOpen: false,
        user: {}
      }
    },
    methods: {
      async loginWithPopup(options, config) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('loginWithPopup() not implemented when BYPASS_AUTH0 is true')
        }

        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(options, config)
          this.user = await this.auth0Client.getUser()
          store.dispatch('setUser', this.user)
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.error = null
        } catch (e) {
          console.error(e)
          this.error = e
        } finally {
          this.popupOpen = false
        }
      },
      async handleRedirectCallback() {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('handleRedirectCallback() not implemented when BYPASS_AUTH0 is true')
        }

        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          store.dispatch('setUser', this.user)
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      loginWithRedirect(o) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('loginWithRedirect() not implemented when BYPASS_AUTH0 is true')
        }

        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims(o) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('getIdTokenClaims() not implemented when BYPASS_AUTH0 is true')
        }

        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokenSilently(o) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          return 'DUMMY_TOKEN'
        }

        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup(o) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('getTokenWithPopup() not implemented when BYPASS_AUTH0 is true')
        }


        return this.auth0Client.getTokenWithPopup(o)
      },
      logout(o) {
        if (AUTH0_CONFIG.BYPASS_AUTH0) {
          throw new Error('logout() not implemented when BYPASS_AUTH0 is true')
        }

        return this.auth0Client.logout(o)
      }
    },
    async created() {
      if (AUTH0_CONFIG.BYPASS_AUTH0) {
        this.loading = false
        this.isAuthenticated = true
        this.user = AUTH0_CONFIG.BYPASS_AUTH0.user

        return
      }

      let auth0ClientOptions = {
        ...options,
        client_id: options.clientId || AUTH0_CONFIG.clientId,
        redirect_uri: redirectUri,
        domain: options.domain || AUTH0_CONFIG.domain,
        audience: options.audience || AUTH0_CONFIG.audience,
        cacheLocation: AUTH0_CONFIG.cacheLocation || 'localstorage'
      }

      this.auth0Client = await createAuth0Client(auth0ClientOptions)
          
      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          this.error = null
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()

        store.dispatch('setUser', this.user)

        this.loading = false
      }
    }
  })

  return instance
}

export const Auth0 = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}