<template>
<div v-if="errorMessage" class="__woenenn-error-wrapper">
  <v-overlay :model-value="showErrorMessage" class="align-center justify-center">
    <v-card v-if="showErrorMessage" style="max-width: 500px;">
      <v-card-title v-if="!errorMessage.hideTitle" class="title text-h6 white--text primary" :style="{ backgroundColor: getTitleColor() }">
        {{ errorMessage.titleTranslation ? $t(errorMessage.titleTranslation) : errorMessage.title || $t('texts.APPLICATION_ERROR') }}
      </v-card-title>
      <v-card-text style="padding-top: 24px;">
        <div v-if="showMessage">{{ message }}</div>
        <div v-if="showErrorId()" style="padding: 5px 0 0; color: #999; text-align: right;">
          <span style="background: #333; padding: 3px 8px; border-radius: 10px;">{{ $t('texts.ERROR_ID') }}: {{ errorMessage.errorId }}</span>
        </div>
      </v-card-text>
      <v-card-actions style="background: #292929;">
        <v-spacer></v-spacer>
        <v-btn v-if="showResetButton && !errorMessage.hideReset" color="#BF360C" text @click="reset()">{{ $t('actions.reset') }}</v-btn>
        <v-btn v-if="!errorMessage.hideToStartPage" color="primary" text @click="toStartPage()">{{ $t('actions.to_start_page') }}</v-btn>
        <v-btn v-if="!errorMessage.hideClose" color="primary" text @click="closeErrorDialog()">{{ $t('actions.close') }}</v-btn>
        <v-btn v-if="!errorMessage.hideReload" color="primary" text @click="reload()">{{ errorMessage.buttonText ? $t(errorMessage.buttonText) : $t('actions.reload') }}</v-btn>
        <v-btn v-for="(button, index) in errorMessage.buttons" v-bind:key="index" :color="button.color || 'primary'" :text="!!button.isTextButton" @click="button.onClick($event, closeErrorDialog)">{{ button.text }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</div>
</template>

<script>
import _ from 'lodash'
import url from '@/services/url'
import { t } from '@/i18n'

const errorMessageFootprint = errorMessage => {
  return _.pick(errorMessage, ['title', 'titleTranslation', 'msg', 'msgTranslation'])
}

export default {
  name: 'ErrorMessage',

  data: function () {
    return {
      errorMessage: null,
      lastErrorMessage: null,
      showResetButton: false
    }
  },

  methods: {
    closeErrorDialog() {
      this.$store.dispatch('removeError')
    },
    getTitleColor() {
      switch (this.errorMessage.type) {
        case 'info':
          return '#2E7D32'
        case 'warning':
          return '#F9A825'
        default:
          // type 'error' or no type
          return '#C62828'
      }
    },
    reload() {
      url.saveReload()
    },
    reset() {
      window.location = '/tools/reset'
    },
    showErrorId() {
      return this.errorMessage.errorId && this.errorMessage.type != 'info' && this.errorMessage.type != 'warning'
    },
    toStartPage() {
      this.closeErrorDialog()

      window.location.href = '/'
    }
  },

  beforeMount() {
    this.lastErrorMessage = JSON.parse(localStorage.getItem('lastErrorMessage'))

    // last error message is used for the reset button which is only
    // visible if the same error message appears after a reload
    if (this.lastErrorMessage) {
      if (localStorage.getItem('lastErrorMessageSecondLoad')) {
        this.lastErrorMessage = null
        localStorage.removeItem("lastErrorMessage")
        localStorage.removeItem("lastErrorMessageSecondLoad")
      } else {
        localStorage.setItem('lastErrorMessageSecondLoad', true)
      }
    }
  },

  mounted() {
    document.body.insertBefore(this.$el, document.body.firstChild)
  },

  computed: {
    message() {
      return this.errorMessage ? (this.errorMessage.msgTranslation ? t(this.errorMessage.msgTranslation) : this.errorMessage.msg) : ''
    },
    showErrorMessage: {
      // getter
      get() {
        return !!this.$store.state.error
      },
      // setter
      set(showErrorMessage) {
        if (!showErrorMessage) {
          this.$store.dispatch('setError', null)
        }
      }
    },
    showMessage() {
      return !!(this.errorMessage && (this.errorMessage.msg || this.errorMessage.msgTranslation))
    }
  },

  watch: {
    '$store.state.errorMessage': function(errorMessage) {
      if (errorMessage) {
        if (_.isEqual(this.lastErrorMessage, errorMessageFootprint(errorMessage))) {
          this.showResetButton = true
        } else {
          this.lastErrorMessage = null
        }
      }
      
      localStorage.setItem('lastErrorMessage', JSON.stringify(errorMessageFootprint(errorMessage)))

      this.errorMessage = errorMessage
    }
  }
}
</script>

<style lang="scss">
.__woenenn-error-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  .title {
    color: #fff;
    background: $primary;
  }
}
</style>