<template>
<div v-if="show" class="start">
  <div class="header" v-bind:style="{ backgroundImage: backgroundImage }" style="background-color: #ccc;" ref="header">
    <div v-if="header" class="headings">
      <div class="text">
        <h1>{{ header }}</h1>
        <h2 v-if="header2">{{ header2 }}</h2>
      </div>
      <div class="logo" v-if="companyLogo">
        <img :src="companyLogo" alt="logo" />
      </div>
    </div>
    <div class="button-top md-size-65 md-medium-size-55 md-small-size-100" ref="buttonTop">
      <div class="button-top-inner" ref="buttonTopInner">
        <div class="button-top-background"></div>
        <md-button class="md-raised md-primary" :to="{ name: 'analysisRedirect2', params: { sourceName: $store.state.source.name, roleName: $store.state.roleName } }" :disabled="$store.getters.isEmptySourceViews">{{ $t('actions.start_analysis')}}</md-button>
      </div>
    </div>
  </div>
  <div class="md-layout content-padding columns">
    <div class="md-layout-item column">
      <div v-if="showHtmlTemplate" class="tq-html">
        <h2>Überschrift Test 1</h2>
        <p>Beispieltext. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
        
        <div class="tq-html-article">
          <div class="tq-html-image">
            <img src="https://dsukjyvr3uysd.cloudfront.net/images/test/test1.jpg" alt="test1" />
          </div>
          <div class="tq-html-content">
            <div class="tq-html-header-1">Überschrift 1</div>
            <div class="tq-html-header-2">Überschrift 2</div>
            <div class="tq-html-text">Test. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
            <div class="tq-html-text">Test. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
          </div>
        </div>

        <h2>Überschrift Test 2</h2>
        <h3>Unterüberschrift Test</h3>

        <ul>
          <li>Listenelment 1. Lorem ipsum dolor <a href="/dev_MB_PKW/ALQ_smart/analysis">test link</a> sit amet, consetetur sadipscing elitr</li>
          <li>Listenelment 2</li>
          <li>Listenelment 3. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</li>
        </ul>

        <hr />

        <div class="tq-html-article">
          <div class="tq-html-image">
            <img src="https://dsukjyvr3uysd.cloudfront.net/images/test/test2.jpg" alt="test1" />
          </div>
          <div class="tq-html-content">
            <div class="tq-html-header-1">Überschrift 1</div>
            <div class="tq-html-header-2">Überschrift 2</div>
            <div class="tq-html-text">Test. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
          </div>
        </div>

      </div>

      <div v-if="!showHtmlTemplate" v-html="html" class="html-content"></div>
    </div>
    
    <div class="md-layout-item md-size-5 md-medium-size-5 md-small-hide column"></div>

    <div class="md-layout-item md-size-30 md-medium-size-40 md-small-size-100 column">
      <md-list>
        <md-list-item v-for="documentationFile in documentationFiles" :key="documentationFile.link" @click="openDocumentationFile(documentationFile)" target="_blank">
          <md-icon>link</md-icon>
          <span class="md-list-item-text">{{ $func.translate(documentationFile.label) }}</span>
        </md-list-item>

        <md-list-item v-if="documentationFiles.length > 0"></md-list-item>

        <md-list-item v-for="link in links"
          :key="link.label"
          :href="link.url" target="_blank">
          <md-icon>link</md-icon>
          <span class="md-list-item-text">{{ link.label }}</span>
        </md-list-item>
      </md-list>
    </div>
  </div>
  <md-snackbar :md-active.sync="$store.getters.isEmptySourceViews" md-persistent >
    <div style="text-align: center; flex: 1;">Analysis is not possible because there are no sections available</div>
  </md-snackbar>
</div>
</template>

<script>
import _ from 'lodash'
import { t, getLocale } from '@/i18n'
import api from '@/services/api'

export default {
  name: 'Start',
  data: () => ({
    documentationFiles: [],
    show: false,
    showHtmlTemplate: false
  }),
  computed: {
    links() {
      return [ {
        label: t('subnav.website'),
        url: 'https://tema-q.de'
      }, {
        label: t('subnav.contact'),
        url: 'https://tema-q.de/kontakt/'
      }, {
        label: t('subnav.business_terms'),
        url: 'https://tema-q.de/agb/'
      }, {
        label: t('subnav.legal_notice'),
        url: 'https://tema-q.de/impressum/'
      }, {
        label: t('subnav.privacy_policy'),
        url: 'https://tema-q.de/datenschutzhinweise/'
      }]
    },
    header() {
      let source = this.$store.state.source
      let startHeader = source && source.page.startHeader
      return startHeader && startHeader[getLocale()]
    },
    header2() {
      let source = this.$store.state.source
      let startHeader2 = source && source.page.startHeader2
      return startHeader2 && startHeader2[getLocale()]
    },
    html() {
      let locale = getLocale()

      let startPageHtml = this.$store.state.startPageHtml

      return (startPageHtml && startPageHtml[locale]) || ''
    },
    backgroundImage() {
      let backgroundCss = null
      let source = this.$store.state.source

      if (source) {
        let page = source.page
        let backgroundImage = page && page.startBackgroundImage

        backgroundCss = backgroundImage ? 'url(https://dsukjyvr3uysd.cloudfront.net' + backgroundImage + ')' : backgroundCss
      }

      return backgroundCss
    },
    companyLogo() {
      let backgroundUrl = null
      let source = this.$store.state.source

      if (source) {
        let page = source.page
        let companyImage = page && page.startCompanyImage

        backgroundUrl = companyImage ? 'https://dsukjyvr3uysd.cloudfront.net' + companyImage : backgroundUrl
      }

      return backgroundUrl
    }
  },
  watch: {
    '$store.state.views': function() {
      this.changeViews()
    }
  },
  methods: {
    openDocumentationFile(documentationFile) {
      api.call('getDocumentationUrl', {
        data: {
          path: documentationFile.link
        }
      }, response => {
        let signedUrl = response.signedUrl

        window.open(signedUrl)
      })
    },
    async changeViews() {
      let documentationFileMap = {} 

      this.$store.state.views.forEach(view => {
        let documentation = view.documentation || []

        documentation.forEach(documentationFile => {
          let link = documentationFile.link
          documentationFileMap[link] = documentationFile
        })
      })

      this.documentationFiles = _.values(documentationFileMap)
    },
    handleScroll: function (event) {
      let buttoTopElem = this.$refs.buttonTop
      let headerElem = this.$refs.header

      if (buttoTopElem.offsetTop < event.target.scrollTop) {
        headerElem.style.top = (event.target.scrollTop - buttoTopElem.offsetTop) + 'px'
      } else {
        headerElem.style.top = 0;
      }
    }
  },
  async mounted() {
    this.changeViews()

    this.show = true

    let content = document.getElementById('content')

    if (content && content.parentNode) {
      content.parentNode.onscroll = event => {
        this.handleScroll(event)
      }
    }

    this.showHtmlTemplate = !!this.$route.query.showHtmlTemplate
  },
  beforeDestroy: function() {
    let content = document.getElementById('content')
    
    if (content && content.parentNode) {
      delete content.parentNode.onscroll
    }
  }
}
</script>

<style scoped lang="scss">
.start {
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 1560px;
  background: #fff;

  .column {
    padding: 20px 0 0
  }

  .content-padding {
      padding: 0 36px;
  }

  .header {
    padding: 0 36px;
    position: relative;
    background-size: cover;
    background-position: center center;
    z-index: 5;
  }

  .headings {
    display: flex;
    overflow: auto;
    padding: 24px 0 0;
    //margin: -16px;
    margin-bottom: 0;

    .text {
      flex: 2 0 0;
      padding: 0 15px 0 0;
      
      h1 {
        margin: 16px 0;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.2em;
        color: #fff;
      }

      h2 {
        margin: 16px 0;
        padding: 0;
        font-size: 16px;
        line-height: 1.2em;
        font-weight: 500;
        color: #fff;
      }
    }

    .logo {
      flex: 1 0 0;
      max-width: 200px;
    }
  }

  .html-content {
    margin: 20px 0;
  }

  .button-top {
    position: relative;
    height: 80px;

    .button-top-inner {
      background-size: cover;
      position: relative;
      padding: 0;
      height: 80px;
      display: flex;
      align-items: center;
      z-index: 100;
    }

    :deep {
      .md-button {
        margin: 0;
        border-radius: 5px;
        padding: 0px 10px;
        background: #fff;
        height: 50px;
      }
    }
  }

  :deep {
    .md-list {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 600px){
  .start {
    .content-padding {
      padding: 0 18px;
    }

    .header {
      padding: 0 18px;
    }
  }
}
</style>
