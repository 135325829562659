<template>
<div class="audioplayer-wrapper">
  <div class="loading" v-if="!ready">
    <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <div class="audioplayer" v-if="ready">
    <div class="amplitude-player">
      <div class="meta-container">
        <span data-amplitude-song-info="name" class="song-name"></span>
      </div>
      <div class="time-container">
        <span class="current-time">
          <span class="amplitude-current-minutes" ></span>:<span class="amplitude-current-seconds"></span>
        </span>          
        <div class="progress-container">
          <div class="amplitude-wave-form"></div>                    
          <input type="range" class="amplitude-song-slider"/>
          <progress class="song-played-progress"></progress>
          <progress class="song-buffered-progress" value="0"></progress>
        </div>
        <span class="duration">
          <span class="amplitude-duration-minutes"></span>:<span class="amplitude-duration-seconds"></span>
        </span>
      </div>          
      <div class="central-controls">
        <md-button :disabled="!hasPrevious" class="md-icon-button" @click="toPrevious"><md-icon>skip_previous</md-icon></md-button>
        <md-button :disabled="!canPlay" class="md-icon-button" @click="togglePlay(null)">
          <md-icon v-if="isPlaying">pause</md-icon>
          <md-icon v-else>play_arrow</md-icon>
        </md-button>
        <md-button :disabled="!hasNext" class="md-icon-button" @click="toNext"><md-icon>skip_next</md-icon></md-button>
      </div>   
      <div style="width: 100%; justify-content: space-between;">
        <div class="volume-container">
          <md-icon class="volume-icon"><md-tooltip>{{$t('texts.AUDIO_VOLUME')}}</md-tooltip>volume_up</md-icon>
          <slider v-model="volume"></slider>
          <span class="volume-value">{{volume}}%</span>
        </div>
        <div class="playback-speed-container">
          <md-icon class="playback-speed-icon"><md-tooltip>{{$t('texts.AUDIO_PLAYBACK_SPEED')}}</md-tooltip>speed</md-icon>
          <slider v-model="playbackSpeedLevel" :steps="10" :min="0" :max="10"></slider>
          <span class="playback-speed-value">{{playbackSpeedRate.toFixed(2)}}x</span>
        </div> 
      </div>   
    </div>
    <div class="playlist-container">
      <md-list class="playlist">
        <md-list-item  v-for="(item, index) in playlist" :key="index" class="soundfile-list-item md-caption" :class="{ 'active-soundfile-list-item' : index === playingIndex}">
          <div class="md-body-1" style="display: flex; align-items: center; width: 100%;">
            <div class="md-caption" style="width: 24px; text-align: right; ">{{index + 1}}</div>
            <md-button class="md-icon-button" @click="togglePlay(index)" style="margin: 0 2px;">
              <md-icon class="play-icon" v-if="!isPlaying || playingIndex !== index">play_circle_filled</md-icon>
              <md-icon class="pause-icon" v-else>pause_circle_filled</md-icon>
            </md-button>
            <div style="flex: 1 1 0; min-width: 0; overflow: hidden; text-overflow: ellipsis;">{{item.name}}</div>
            <div style="min-width: 64px; text-align: right;">{{item.duration}}</div>
          </div>
        </md-list-item>
      </md-list> 
    </div>  
  </div>
</div>
</template>
  

<script>
import api from '@/services/api'
import amplitude from "amplitudejs";

export default {
  label: 'AudioPlayer',
  props: {
    'audioData': {
      type: Array        
    }
  },
  data: () => {
    return {
      playlist: null,
      inPlayRequest: false,
      isPlaying: false,
      playingIndex: -1,
      playbackSpeedLevel: 3,
      playbackSpeedRate: 1.0,
      fastForward: false,
      ready: false,
      volume: 50
    }
  },
  async mounted() {
    this.updateAudioPlaylist();
    await this.initAmplitude(0);

    window.addEventListener("keyup", this.keyUp);

    this.ready = true    
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyUp);
    
    this.stopAmplitude();
  },
  computed: {
    canPlay() {
      return !!(this.playlist && (this.playlist.length > 0))
    },
    hasPrevious() {
      return this.canPlay
    },
    hasNext() {
      return !!(this.canPlay && ((this.playingIndex + 1) < this.playlist.length))
    }
  },
  methods: {
    keyUp(event) {
      if (event.which == 116 || event.keyCode == 116) {
        // F5 key pressed  
        this.togglePlay(null, -1)
      }
    },
    async togglePlay(index, offsetSeconds) {
      if (!this.isPlaying || (index != null && index !== this.playingIndex)) {
        await this.play(index, offsetSeconds);
      } else {
        this.pause();
      }
    },
    async play(index, offsetSeconds = 0) {
      if (this.inPlayRequest) {
        return
      }

      this.inPlayRequest = true

      if (index == null) {
        index = this.playingIndex;
      }
      if (index !== this.playingIndex) {
        await this.initAmplitude(index);
      } 
      amplitude.play();
      
      if (offsetSeconds !== 0) {
        let playedSeconds = Math.floor(amplitude.getSongPlayedSeconds())
        
        if (playedSeconds > 0) {
          amplitude.skipTo(playedSeconds + offsetSeconds, this.playingIndex) 
        } else {
          amplitude.skipTo(0, this.playingIndex)
        }
      }
      this.isPlaying = true;
      this.playingIndex = index;
      this.inPlayRequest= false;
    },
    pause() {
      if (this.inPlayRequest) {
        return
      }

      amplitude.pause();
      this.isPlaying = false;
    },
    async initAmplitude(index) {
      let songs;
      if (this.playlist && this.playlist[index] != null) {
        let url;
        await api.call('getAudioUrl', {fileName: this.playlist[index].filename}, response => {
          url = response.signedUrl;
        })
        songs = [{
          url: url,
          name: this.playlist[index].name
        }];
      } else {
        songs = [];
      }
      this.stopAmplitude();      
      amplitude.init({
        "songs": songs,
        "callbacks": {
          'play': () => {
            this.setVolume(this.volume);
            this.setPlaybackSpeedLevel(this.playbackSpeedLevel);
          },
          'ended': () => {
            this.toNext();
          }          
        },
        waveforms: {
          sample_rate: 100
        },
      });
      this.playingIndex = index; 
    },
    stopAmplitude() {
      if (amplitude.getPlayerState() !== 'stopped') {
        amplitude.stop();
      }      
      this.isPlaying = false;
      this.playingIndex = 0;
    },
    toNext() {
      if (this.playingIndex < this.playlist.length - 1) {
        this.play(this.playingIndex + 1);
      } else {
        this.isPlaying = false
        this.playingIndex = 0
      }
    },
    toPrevious() {
      if (this.playingIndex > 0) {
        this.play(this.playingIndex - 1);
      } else {
        amplitude.skipTo(0, this.playingIndex )        
        this.isPlaying = true;
      }     
    },
    setVolume(volume) {
      amplitude.getConfig().audio.volume = volume / 100;
    },
    setPlaybackSpeedLevel(playbackSpeedLevel) {
      let playbackSpeedRate = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 4, 8][playbackSpeedLevel];
      this.playbackSpeedRate = playbackSpeedRate;
      amplitude.getConfig().audio.playbackRate = playbackSpeedRate;
    },
    updateAudioPlaylist() {
      let audioData = this.audioData;
      let playlist = null;
      if (audioData) {
        playlist = [];
        audioData.forEach( async (e, i) => {
          let name = e.label;
          //let project =  this.$store.state.source.name;
          let filename = e.filename;
          let duration;
          if (!isNaN(e.playlength)) {
            let seconds = Math.floor(e.playlength % 60);
            let minutes = Math.floor(e.playlength / 60);
            duration =  minutes + ':' + (seconds <= 9 ? '0' + seconds : seconds);         
          } else {
            duration = "0:00";
          }
          playlist[i] = {
            name: name,
            filename: filename,
            duration: duration
          };
        })
      }
      this.playlist = playlist;
    }
  },
  watch: {
    async audioData() {
      this.updateAudioPlaylist();
      await this.initAmplitude(0);
    },
    volume(newValue) {
      this.setVolume(newValue);
    },
    playbackSpeedLevel(newValue) {
      this.setPlaybackSpeedLevel(newValue);
    }
  } 
    
}
</script>


<style lang="scss" scoped>

.audioplayer-wrapper {
  flex: 1 1 0;
  display: flex;
  background-color: #fff;
}

.loading {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
}

.audioplayer {
  max-width: 370px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.playlist-container {
  flex: 1 1 0;
  overflow: auto;
}

.playlist {
  padding: 0;
}

.playlist .md-list-item-container {
  color: #222;
}

div.amplitude-wave-form{
    margin-top: -14px;
}
      
 div.amplitude-wave-form svg{
      stroke: #E40000;
      height: 50px;
      width: 100%;
      stroke-width: .5px;
}

 div.amplitude-wave-form svg g{
   stroke: #E40000;
   height: 50px;
   width: 100%;
}
 div.amplitude-wave-form svg g path{
   stroke: #E40000;
   height: 50px;
   width: 100%;
}

div.amplitude-wave-form{
    margin-top: -16px;
}
      
.volume-container {
  display:flex;
  align-items: center;
}

.volume-container, .playback-speed-container {
  margin: 4px 16px;
}

.volume-container .slider, .playback-speed-container .slider {
  flex: 1 1 0;
}

.playback-speed-container {
  display:flex;
  align-items: center;
}

.volume-icon, .playback-speed-icon {
  margin-right: 8px;
}

.volume-value, .playback-speed-value {
  width: 40px;
  margin-left: 8px;
}


.playlist li.active-soundfile-list-item {
  background: #f4f4f4;
}

.playlist li .md-button i {
  opacity: 0.4;
  transition: opacity 200ms;
}

.playlist li .md-button i:hover {
  opacity: 0.5;
}

.playlist li.amplitude-active-song-container .md-button i {
  opacity: 1;
}


/*
  1. Base
*/

/*
  2. Components
*/
div.amplitude-player {
  display: flex;
  flex: 0 0 0;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(233, 233, 233);
  display: flex;
  max-width: 900px; 
  background-color: #fafafa;
  }

/* Small only */
@media screen and (max-width: 39.9375em) {
  div.amplitude-player {
    flex-direction: column; } }
/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  div.amplitude-player {
    max-height: 715px; 
  } }
/* Large and up */
@media screen and (min-width: 64em) {
  div.amplitude-player {
    max-height: 715px;
  } }
div#amplitude-left {
  padding: 0;
  border-right: 1px solid #CFD8DC;
  width: 50%;
  display: flex;
  flex-direction: column; }
  div#amplitude-left img.album-art {
    width: 100%; }
  div#amplitude-left div#player-left-bottom {
    flex: 1;
    background-color: #F1F1F1;
    padding: 20px 10px; }
    





div.progress-container {
  width: 70%;
  float: left;
  position: relative;
  height: 20px;
  cursor: pointer;
  /*
    IE 11
  */ }
  div.progress-container:hover input[type=range].amplitude-song-slider::-webkit-slider-thumb {
    display: block; }
  div.progress-container:hover input[type=range].amplitude-song-slider::-moz-range-thumb {
    visibility: visible; }
  div.progress-container progress.song-played-progress {
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 60;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 5px;
    background: transparent;
    border: none;
    /* Needed for Firefox */ }
  @media all and (-ms-high-contrast: none) {
    div.progress-container *::-ms-backdrop, div.progress-container progress.song-played-progress {
      color: #E40000;
      border: none;
      background-color: #CFD8DC; } }
  @supports (-ms-ime-align: auto) {
    div.progress-container progress.song-played-progress {
      color: #E40000;
      border: none; } }
  div.progress-container progress.song-played-progress[value]::-webkit-progress-bar {
    background: none;
    border-radius: 5px; }
  div.progress-container progress.song-played-progress[value]::-webkit-progress-value {
    background-color: #E40000;
    border-radius: 5px; }
  div.progress-container progress.song-played-progress::-moz-progress-bar {
    background: none;
    border-radius: 5px;
    background-color: #E40000;
    height: 5px;
    margin-top: -2px; }
  div.progress-container progress.song-buffered-progress {
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 5px;
    border: none;
    background-color: #D7DEE3; }
  div.progress-container progress.song-buffered-progress[value]::-webkit-progress-bar {
    background-color: #CFD8DC;
    border-radius: 5px; }
  div.progress-container progress.song-buffered-progress[value]::-webkit-progress-value {
    background-color: #F6BCBC;
    border-radius: 5px;
    transition: width .1s ease; }
  div.progress-container progress.song-buffered-progress::-moz-progress-bar {
    background: none;
    border-radius: 5px;
    background-color: #F6BCBC;
    height: 5px;
    margin-top: -2px; }
  
  div.progress-container input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 7.5px 0;
    position: absolute;
    z-index: 9999;
    top: -7px;
    height: 20px;
    cursor: pointer;
    background-color: inherit; }
  div.progress-container input[type=range]:focus {
    outline: none; }
  div.progress-container input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    background: #0075a9;
    border-radius: 0;
    border: 0 solid #010101; }
  div.progress-container input[type=range]::-webkit-slider-thumb {
    border: 1px solid #E40000;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px; }
  div.progress-container input[type=range]:focus::-webkit-slider-runnable-track {
    background: #00adfb; }
  div.progress-container input[type=range]::-moz-range-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
    background: #0075a9;
    border-radius: 0;
    border: 0 solid #010101; }
  div.progress-container input[type=range]::-moz-range-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 1px solid #E40000;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer; }
  div.progress-container input[type=range]::-ms-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  div.progress-container input[type=range]::-ms-fill-lower {
    background: #003d57;
    border: 0 solid #010101;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0); }
  div.progress-container input[type=range]::-ms-fill-upper {
    background: #0075a9;
    border: 0 solid #010101;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0); }
  div,progress-container input[type=range]::-ms-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 1px solid #E40000;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer;
    height: 0;
    display: block; }

div.time-container span.current-time {
  float: left;
  width: 15%;
  text-align: center; 
}

div.time-container span.duration {
  float: left;
  width: 15%;
  text-align: center; 
}

div.time-container:after {
  content: "";
  display: table;
  clear: both; 
}

.meta-container {
  text-align: center; margin-bottom: 24px;
}

.amplitude-play-pause.amplitude-playing .play-icon {
  display: none;
}

.amplitude-play-pause:not(.amplitude-playing) .pause-icon {
  display: none;
}

.central-controls {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}


</style>