<template>
  <div v-if="variable != null" class="variable-badges">
    <letter-badge :letter="levelLetter" :label="levelLabel" :color="levelColor" :delay="1000"></letter-badge>
    <letter-badge :letter="typeLetter" :label="typeLabel" :color="typeColor" :delay="1000"></letter-badge>
  </div>
</template>

<script>

export default {
  name: 'VariableBadges',
  props: [
    'variable',
    'size'
  ],
  watch: {
    variable() {
      this.updateIcons();
    }
  },
  methods: {
    updateIcons() {
      if (this.variable) {
        let level
        let levelLetter
        let levelColor

        if (this.variable.verbatimVar || this.variable.type === 'verbatim_n') {
          level = 'verbatim'
          levelLetter = 'V'
          levelColor = '#5CBAE6'
        } else {
          level = 'sample'
          levelLetter = 'S'
          levelColor = '#93DD73'
        }

        this.levelLetter = levelLetter
        this.levelColor = levelColor
        this.levelClass = 'level-' + level
        this.levelLabel = 'level: ' + level

        let typeLetter
        let typeColor
        switch(this.variable.type) {
          case 'boolean': 
            typeLetter = 'B'
            typeColor = '#C3F573'
            break
          case 'categorical': 
            typeLetter = 'C'
            typeColor = '#F5C373'
            break
          case 'text':
            typeLetter = 'T'
            typeColor = '#CDC9B9'
            break
          case 'date':
            typeLetter = 'D'
            typeColor = '#D998CB'
            break
          case 'datetime':
            typeLetter = 'D'
            typeColor = '#6ac2e2'
            break
          case 'link':
            typeLetter = 'L'
            typeColor = '#cccccc'
            break
          case 'number':
            typeLetter = 'N'
            typeColor = '#FB9B85'
            break
          case 'array_of_strings':
            typeLetter = 'A'
            typeColor = '#CDD987'
            break
          case 'verbatim_n':
            typeLetter = 'V'
            typeColor = '#84D6C6'
            break
        }
        this.typeLetter = typeLetter
        this.typeColor = typeColor


        this.typeLabel = 'type: ' + this.variable.type
        this.typeClass = 'type-' + this.variable.type
      }
    }
  },
  data() {
    return {
      levelClass : '',
      levelLetter : '',
      levelLabel : '',
      typeClass : '',
      typeLetter : '',
      typeLabel : ''
    }    
  },
  beforeMount() {
    this.updateIcons()
  } 
}
</script>

<style lang="scss" scoped>  
  .variable-badges {
    display: flex;
    font-size: 10px;
    margin: 0 0.5em;
  }
</style>