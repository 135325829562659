/*

This file contains functions to be used from all components if in script tag like below

this.$func.functionName(somedata)

or if template tag like

{{ $func.functionName(somedata) }}

*/

import {  getLocale } from '@/i18n'
import _ from 'lodash'

export const func = {
  translate(strOrObject) {
    if (strOrObject == null || _.isString(strOrObject)) {
      return strOrObject
    }
    return strOrObject[getLocale()] || '<no translation>'
  }
}