<template>
<div class="tq-save-analysis-settings" v-if="dataReady">
  <div class="heading">
    <div>{{$t('texts.BOOKMARKS')}}</div>
  </div>

  <md-content style="height: 100%; overflow: auto;" class="md-scrollbar">
    <div class="trees">
      <analysis-settings-tree :tree="publicTree" :name="$t('texts.PUBLIC_BOOKMARKS')" @selectAnalysisSettings="selectAnalysisSettings" @dragStart="dragStart" @dragEnd="dragEnd" @dragChange="dragChange"></analysis-settings-tree>
      <analysis-settings-tree :tree="userTree" :name="$t('texts.PRIVATE_BOOKMARKS')" @selectAnalysisSettings="selectAnalysisSettings" @dragStart="dragStart" @dragEnd="dragEnd" @dragChange="dragChange"></analysis-settings-tree>
    </div>
  </md-content>
</div>
</template>

<script>
import api from '@/services/api'
import error from '@/services/error'
import localStorageHelper from '@/services/localStorageHelper'

export default {
  name: 'AnalysisSettingsManager',
  data() {
    return {
      bookmark_tab: 'create_bookmark',
      blockInput: false,
      analysisSettings: null,
      folderName: null,
      showCreateFolderDialog: false,
      showEditFolderDialog: false,
      dataReady: false,
      showApplyCurrentAnalysis: false,
      applyCurrentAnalysis: false,
      showCheckbox: true,
      showAllViews: localStorageHelper.getValue('showAllViews', true),
      showGlobalBookmarks: localStorageHelper.getValue('showGlobalBookmarks', true),
      userTree: [],
      publicTree: [],
      publicMode: false,
      moveObj: null,
      confirmMoveDialog: false
    }
  },
  methods: {
    block(run) {
      this.$store.dispatch('setLoading', true)
      if (!this.blocked) {
        this.blocked = true

        run(() => {
          this.blocked = false
          this.$store.dispatch('setLoading', false)
        })
      }
    },
    selectAnalysisSettings(analysisSettings) {
      this.block(unblock => {
        api.call('getAnalysisSettings', {
          sourceName: this.$store.state.source.name,
          analysisSettingId: analysisSettings.id
        }, async analysisSettings => {
          this.$emit('applyAnalysis', analysisSettings.viewName, analysisSettings.analysisSettings, analysisSettings.filter, analysisSettings.analysisView)

          unblock()
        })
      })
    },
    dragStart() {
      this.moveObj = {}
    },
    dragEnd() {
      let moveObj = this.moveObj

      if (moveObj.id) {
        if (moveObj.moved) {
          let analysisSettingsTreeComponent = moveObj.analysisSettingsTreeComponent
          
          analysisSettingsTreeComponent.updateOpenFolderTree()
          analysisSettingsTreeComponent.$forceUpdate()
        } else {
          // moved between trees
          let newAnalysisSettingsTreeComponent = moveObj.newAnalysisSettingsTreeComponent
          let oldAnalysisSettingsTreeComponent = moveObj.oldAnalysisSettingsTreeComponent
          let newOpenFolders = newAnalysisSettingsTreeComponent.openFolders
          let oldOpenFolders = oldAnalysisSettingsTreeComponent.openFolders

          if (newOpenFolders !== oldOpenFolders) {
            newOpenFolders[moveObj.id] = oldOpenFolders[moveObj.id]
            delete oldOpenFolders[moveObj.id]
          }

          newAnalysisSettingsTreeComponent.updateOpenFolderTree()
          newAnalysisSettingsTreeComponent.$forceUpdate()

          if (newAnalysisSettingsTreeComponent !== oldAnalysisSettingsTreeComponent) {
            oldAnalysisSettingsTreeComponent.updateOpenFolderTree()
            oldAnalysisSettingsTreeComponent.$forceUpdate()
          }
        }

        api.call('moveAnalysisSettings', {
          analysisSettingId: moveObj.id,
          data: _.pick(moveObj, [
            'id',
            'newIndex',
            'newParentId',
            'oldParentId',
            'parentId',
            'previousId'
          ])
        })
      }

      this.moveObj = null
    },
    dragChange(event, analysisSettingsTreeComponent, parentAnalysisSettings) {
      if (event.removed) {
        let removed = event.removed
        let analysisSettings = removed.element

        Object.assign(this.moveObj, {
          id: analysisSettings.id,
          oldParentId: parentAnalysisSettings.id,
          oldRootId: parentAnalysisSettings.rootId || parentAnalysisSettings.id,
          oldAnalysisSettingsTreeComponent: analysisSettingsTreeComponent
        })
      }

      if (event.added) {
        let added = event.added
        let analysisSettings = added.element
        let newIndex = added.newIndex

        Object.assign(this.moveObj, {
          id: analysisSettings.id,
          newParentId: parentAnalysisSettings.id,
          newRootId: parentAnalysisSettings.rootId || parentAnalysisSettings.id,
          previousId: newIndex === 0 ? null : parentAnalysisSettings.children[newIndex - 1].id,
          newAnalysisSettingsTreeComponent: analysisSettingsTreeComponent
        })

        analysisSettings.rootId = parentAnalysisSettings.rootId
      }

      if (event.moved) {
        let moved = event.moved
        let analysisSettings = moved.element
        let newIndex = moved.newIndex

        Object.assign(this.moveObj, {
          moved: true,
          id: analysisSettings.id,
          parentId: parentAnalysisSettings.id,
          previousId: newIndex == 0 ? null : parentAnalysisSettings.children[newIndex - 1].id,
          analysisSettingsTreeComponent: analysisSettingsTreeComponent
        })
      }
    },
    confirmMove(confirm) {
      this.moveObj = null
      this.confirmMoveDialog = false
    }
  },
  async beforeMount() {
    try {
      this.analysisSettingsTreeMap = await this.$store.dispatch('getAnalysisSettingsTrees')

      let { userTree, publicTree } = this.analysisSettingsTreeMap
      this.userTree = userTree
      this.publicTree = publicTree

      let openFolderTree = localStorageHelper.getSourceValue('openFolderTree', {})

      localStorageHelper.setSourceValue('openFolderTree', {
        [userTree.id]: openFolderTree[userTree.id] || { open: true, children: {} },
        [publicTree.id]: openFolderTree[publicTree.id] || { open: true, children: {} }
      })

      this.dataReady = true
    } catch(e) {
      error.runtimeError(e)
    }
  },
  beforeUnmount() {
    this.$store.dispatch('setLinkedBookmarkId', null)
  }
}
</script>

<style lang="scss" scoped>
.tq-save-analysis-settings {
    flex: 1 0 0;
    min-width: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    .heading {
      flex: 0 0 0;
      padding: 16px 16px 12px 16px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid rgb(221, 221, 221);
      white-space: nowrap;
      color: $primary;
      text-transform: uppercase;
    }
  }
</style>