<template>
  <div>
    <v-switch
      v-model="isVerbatim"
      label="Is Verbatim"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'surveyjs-creator-is-verbatim',
  data: () => ({
    isVerbatim: false
  }),
  props: {
    question: Object
  },
  watch: {
    isVerbatim(isVerbatim) {
      this.question.value = isVerbatim
    }
  },
  methods: {
  },
  beforeMount() {
    this.isVerbatim = !!this.question.value
  }
}
</script>

<style lang="scss" scoped>
</style>