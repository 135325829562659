"use strict";

function getValueForTag(tag, callbackOrObject) {
  if (tag[tag.length - 1] === ')') {
    // tag is a function
    return callFunction(tag, callbackOrObject);
  } else {
    let value;
    if (typeof callbackOrObject === 'function') {
      value = callbackOrObject(tag);
    } else {
      let tagTokens = tag.split('.');
      value = callbackOrObject;
      while (value != null && tagTokens.length) {
        value = value[tagTokens.shift()];
      }
    }
    if (value == null) {
      value = '';
    }
    return value;
  }
}


function callFunction(functionStr, callbackOrObject) {
  let functionObj = parseFunction(functionStr);
  let fnArgs = functionObj.arguments;
  fnArgs.forEach((arg, i) => {
      if (arg[0] === '"' || arg[0] === '\'') {
        // argument is a plain string
        fnArgs[i] = arg.substring(1, arg.length - 1);
       } else if (/^[0-9\-\+]/.test(arg)) {
       // argument is a number
       fnArgs[i] = parseFloat(arg);
       } else {
        // argument is a variable
        fnArgs[i] = getValueForTag(arg, callbackOrObject);
        }
    }
  );  
  let fnName = functionObj.name;
  if (typeof callbackOrObject === 'function') {
    return callbackOrObject(fnName, fnArgs);
  } else {
    return callbackOrObject[fnName](...fnArgs);
  }  
}


function parseFunction(functionStr) {
  functionStr = functionStr.slice(0, -1); // remove closing parenthesis
  // Split function string at opening parenthesis. The part before is the function name, the part after are arguments.
  let parenthesisIndex = functionStr.indexOf('(');
  let fnName = functionStr.substring(0, parenthesisIndex);
  let fnArgs = getArguments(functionStr.substring(parenthesisIndex + 1));
  return {
    name: fnName,
    arguments: fnArgs
  }
}


function getArguments(argsStr) {
  let tokens = argsStr.split(/([,'"])/g);
  let args = [];
  let quote = null;
  let argument = null;
  for (let i = 0; i < tokens.length; i++) {
    let token = tokens[i].trim();
    if (token !== '' && token !== ',') {
      if (token === '"' || token === '\'') {
        quote = token;
        i++;
        argument = quote;
        for (;quote != null && i < tokens.length; i++) {
          token = tokens[i];
          argument += token;
          if (token === quote) {
            args.push(argument);
            quote = null;
            break;
          }
        }
      }  else {
        args.push(token);
      }
    }
  }
  return args;  
}



/**
 * Parses a template with  tags in curly brackes and raplaces the tags with values from a function or map object.
 * 
 * If the parameter callbackOrObject is a function, the function will be called with the tag as its only argument. A simple
 * callback function may look like this: 
 * 
 * function callback(tag) {
 *   if (tag === 'date') {
 *     return new Date().toString();
 *   }
 * }
 * 
 * If the parameter callbackOrObject is an object, the tag will be used as a key to get the value from the object. A simple map
 * object may look like this:
 * 
 * {
 *   firstName: "John",
 *   lastName: "Doe"
 * }
 * 
 *  If the result from the callback or map object is null or undefined, the tag will be replaced with an empty string.
 * 
 * 
 * @param {string} templateStr - original template string containing tags enclosed in curly braces
 * @param {Function|Object} callbackOrObject - callback function or map object
 * @returns {string} - the new string with replaced tags
 */
function parseTemplate(templateStr, callbackOrObject) {
  // ToDo: Testing: templateStr += " {{getDate('2019-01-31T10:01:00.000Z', 'm/d/yy', 2)}}"
  let templateRegex = /{{([^{}]*)}}/g;  // Finds text in double curly brackets
  while (templateRegex.test(templateStr)) {
    templateStr = templateStr.replace(templateRegex, function(match, tag) {
      return getValueForTag(tag.trim(), callbackOrObject);
    });
  }
  return templateStr;
}


/**
 * Creates an object that maps tags to parsed values. If a map object is provided, entries will be
 * written into this object. Otherwise a new object will be created.
 * 
 * @param {String} templateStr
 * @param {Function|Object} callbackOrObject
 * @param {Object} map
 */
function getTagValueMap(templateStr, callbackOrObject, map) {
  let tagValueMapping = map || {};
  // Collect outer tags and parse them with parseTemplate(), which will take care of nested tags.
  let tokens = templateStr.split(/({{|}})/g); // SPlits at '}}' and '}}' and includes separators in result
   let level = 0;
  let tag = '';
  for (let token of tokens) {
    if (token === '{{') {
      if (level > 0) {
        tag += token;
      }
      level++;
    } else if (token === '}}') {
      level--;
      if (level === 0) {
        // End of tag reached. Parse it and put it into mapping. Add double curly brackets so the tag can be
        // parsed by parseTemplate()
        tagValueMapping[tag] = parseTemplate('{{' + tag.trim() + '}}', callbackOrObject);
        tag = '';
      } else if (level > 0) {
        tag += token;
      } else { // level < 0. Special case that can occur if JSON object closes two levels.
        level = 0;
      }
    } else if (level > 0) {
      tag += token;
    }
  } 
  return tagValueMapping; 
}

const templateParser = { parseTemplate, getTagValueMap };

export default templateParser;