<template>
  <div class="list-badge" v-bind:style="{ backgroundColor: color }">
    <span class="letter">{{letter}}</span>
    <md-tooltip md-direction="bottom">{{tooltip}}</md-tooltip>
  </div>
</template>

<script>

export default {
  name: 'ListBadge',
  props: ['letter', 'color', 'tooltip'],
  watch: {
    letter() {
      this.update();
    },
    color() {
      this.update();
    },
    tooltip() {
      this.update();
    }
  },
  methods: {
    update() {
      
    }
  },
  data() {
    return {
    }    
  },
  beforeMount() {
    this.update()
  } 
}
</script>

<style lang="scss" scoped>
  .list-badge {
    background: #cccccc;
    border-radius: 0.6em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.2em;
    margin-right: 0.3em;
    text-align: center;
    width: 1.2em; 
    font-size: 10px;
  }

  .letter {
    vertical-align: text-bottom;
  }
</style>

