<template>
 <div class="analysis-settings-tree" v-bind:class="{ 'closed': !openFolderTree[tree.id].open }">
    <v-dialog v-model="confirmDeleteDialog" :persistent="true" width="unset">
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          <template v-if="confirmDeleteDialogName === 'folder'">{{$t('texts.CONFIRM_DELETE_FOLDER')}}</template>
          <template v-if="confirmDeleteDialogName === 'bookmark'">{{$t('texts.CONFIRM_DELETE_BOOKMARK')}}</template>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resolveConfirmDelete(false)">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="primary" text @click="resolveConfirmDelete(true)">{{$t('actions.confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCreateFolderDialog" width="500">
      <v-card>
        <v-card-title class="text-h6 white--text primary">{{$t('texts.CREATE_FOLDER')}}</v-card-title>

        <v-card-text>
          <v-form lazy-validation @submit="createFolder" onSubmit="return false">
            <v-text-field
              v-model="folderName"
              :counter="50"
              :label="$t('texts.FOLDER_NAME')"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showCreateFolderDialog = false">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="primary" text @click="createFolder()">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="showAddBookmarkToFolderDialog" width="500">
      <v-card>
        <v-card-title class="text-h6 white--text primary">{{$t('texts.CREATE_BOOKMARK')}}</v-card-title>

        <v-card-text>
          <v-form lazy-validation @submit="addBookmarkToFolder" onSubmit="return false">
            <v-text-field
              v-model="bookmarkName"
              :counter="50"
              :label="$t('texts.BOOKMARK_NAME')"
              required
            ></v-text-field>

            <v-checkbox
              v-model="saveWithFilter"
              :label="$t('texts.SAVE_WITH_FILTER')"
            ></v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showAddBookmarkToFolderDialog = false">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="primary" text @click="addBookmarkToFolder">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditBookmarkDialog" width="500">
      <v-card>
        <v-card-title class="text-h6 white--text primary">{{$t('texts.EDIT_BOOKMARK')}}</v-card-title>

        <v-card-text>
          <v-form lazy-validation @submit="editBookmark" onSubmit="return false">
            <v-text-field
              v-model="bookmarkName"
              :counter="50"
              :label="$t('texts.BOOKMARK_NAME')"
              required
            ></v-text-field>

            <v-checkbox
              v-model="applyCurrentAnalysis"
              :label="$t('texts.APPLY_CURRENT_ANALYSIS')"
            ></v-checkbox>

            <v-checkbox
              v-if="applyCurrentAnalysis"
              v-model="saveWithFilter"
              :label="$t('texts.SAVE_WITH_FILTER')"
            ></v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showEditBookmarkDialog = false">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="primary" text @click="editBookmark">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditFolderDialog" width="500">
      <v-card>
        <v-card-title class="text-h6 white--text primary">{{$t('texts.EDIT_FOLDER')}}</v-card-title>

        <v-card-text>
          <v-form lazy-validation @submit="editFolder" onSubmit="return false">
            <v-text-field
              v-model="folderName"
              :counter="50"
              :label="$t('texts.FOLDER_NAME')"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showEditFolderDialog = false">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="primary" text @click="editFolder">{{$t('actions.edit')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="tree-heading"><span @click="clickTreeToggle" class="tree-heading-toggle"><v-btn icon><v-icon class="tree-heading-icon">mdi-chevron-down</v-icon></v-btn></span><span class="tree-heading-text">{{name}}</span><v-btn v-if="editRights" icon @click="openCreateFolderDialog"><v-icon color="grey">mdi-folder-plus-outline</v-icon></v-btn></div>
    <div class="tree-content">
      <div class="tree-root">
        <draggable v-model="tree.children" @change="change(...arguments, openFolders, tree)" @end="end" @start="start" :options="{disabled : !editRights}" group="folder">
          <div v-for="analysisSettingsRoot in tree.children" v-bind:key="analysisSettingsRoot.id">
            <div v-if="analysisSettingsRoot.folder" class="root-folders" v-bind:class="{'closed': !isOpenFolder(analysisSettingsRoot.id) }">
              <template>
                <div class="root-folder">
                  <v-btn icon @click="toggleRootFolder(analysisSettingsRoot)"><v-icon class="root-folder-icon" small>mdi-chevron-down</v-icon></v-btn>
                  <v-icon color="secondary" v-if="isOpenFolder(analysisSettingsRoot.id)">mdi-folder-open</v-icon>
                  <v-icon color="secondary" v-if="!isOpenFolder(analysisSettingsRoot.id)">mdi-folder</v-icon>
                  
                  <v-tooltip bottom open-delay="1000" :offset-overflow="true">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="root-folder-text" v-bind="attrs" v-on="on">{{analysisSettingsRoot.name}}</div>
                    </template>
                    <span>{{analysisSettingsRoot.name}}</span>
                  </v-tooltip>
                  
                  <v-menu bottom left v-if="editRights">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="openEditFolderDialog(analysisSettingsRoot)">
                        <v-list-item-content>{{ $t('actions.edit') }}</v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="deleteFolder(analysisSettingsRoot, tree)">
                        <v-list-item-content>{{ $t('actions.delete') }}</v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="openAddBookmarkToFolderDialog(analysisSettingsRoot)">
                        <v-list-item-content>{{ $t('actions.add_bookmark') }}</v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon>
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>

              <div class="bookmarks">
                <draggable v-model="analysisSettingsRoot.children" group="bookmark" @change="change(...arguments, openFolders[analysisSettingsRoot.id].children, analysisSettingsRoot)" @end="end" @start="start" :options="{disabled: !editRights}">
                  <analysis-settings-bookmark v-for="analysisSettings in analysisSettingsRoot.children" v-bind:key="analysisSettings.id" :bookmark="analysisSettings" :editRights="editRights" @edit="openEditBookmarkDialog(analysisSettings)" @select="$emit('selectAnalysisSettings', analysisSettings)" @delete="deleteBookmark(analysisSettings, analysisSettingsRoot)"></analysis-settings-bookmark>
                </draggable>
              </div>
            </div>
            <analysis-settings-bookmark v-if="!analysisSettingsRoot.folder" :bookmark="analysisSettingsRoot" :editRights="editRights"></analysis-settings-bookmark>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import localStorageHelper from '@/services/localStorageHelper'
import authorization from '@/services/authorization'

export default {
  name: 'AnalysisSettingsTree',
  props: [
    'tree',
    'name'
  ],
  data() {
    return {
      bookmarkName: null,
      folderName: null,
      saveWithFilter: true,
      editAnalysisSettings: null,
      addBookmarkFolder: null,
      openFolderTree: null,
      showAddBookmarkToFolderDialog: false,
      showEditBookmarkDialog: false,
      showCreateFolderDialog: false,
      showEditFolderDialog: false,
      applyCurrentAnalysis: true,
      confirmDeleteDialog: false,
      confirmDeleteDialogName: false,
      resolveConfirmDelete: null,
      moveFolderOpen: false
    }
  },
  computed: {
    openFolders() {
      return this.openFolderTree[this.tree.id].children
    }
  },
  methods: {
    block(run) {
      this.$store.dispatch('setLoading', true)
      if (!this.blocked) {
        this.blocked = true

        run(() => {
          this.blocked = false
          this.$store.dispatch('setLoading', false)
        })
      }
    },
    async confirmDelete(name) {
      this.confirmDeleteDialog = true
      this.confirmDeleteDialogName = name

      let confirm = await new Promise(resolve => {
        this.resolveConfirmDelete = resolve
      })

      this.resolveConfirmDelete = null
      this.confirmDeleteDialog = false
      this.confirmDeleteDialogName = null

      return confirm
    },
    openEditBookmarkDialog(analysisSettings) {
      this.editAnalysisSettings = analysisSettings
      this.bookmarkName = analysisSettings.name
      this.saveWithFilter = !!analysisSettings.hasFilter
      this.applyCurrentAnalysis = true
      this.showEditBookmarkDialog = true
    },
    editBookmark() {
      this.showEditBookmarkDialog = false
      
      this.block(async unblock => {
        this.editAnalysisSettings.name = this.bookmarkName
        this.editAnalysisSettings.hasFilter = this.saveWithFilter

        let data = {
          name: this.bookmarkName
        }

        if (this.applyCurrentAnalysis) {
          data.applyCurrentAnalysis = this.applyCurrentAnalysis,
          data.analysisSettings = this.$store.state.analysisSettings,
          data.filter = this.saveWithFilter ? this.$store.state.appliedFilterValues : undefined,
          data.analysisView =  this.$store.state.analysisView
        }

        await api.call('editAnalysisSettings', {
          analysisSettingsId: this.editAnalysisSettings.id,
          data: data
        }, unblock)
      })
    },
    async deleteBookmark(analysisSettings, parentAnalysisSettings) {
      if (await this.confirmDelete('bookmark')) {
        this.block(async unblock => {
          await api.call('deleteAnalysisSettings', {
            analysisSettingId: analysisSettings.id
          })
          
          parentAnalysisSettings.children = parentAnalysisSettings.children.filter(childAnalysisSettings => childAnalysisSettings !== analysisSettings)
          unblock()
        })
      }
    },
    isOpenFolder(analysisSettingsId) {
      let openFolder = this.openFolders[analysisSettingsId]

      return openFolder ? openFolder.open : false
    },
    openEditFolderDialog(analysisSettings) {
      this.editAnalysisSettings = analysisSettings
      this.folderName = analysisSettings.name

      this.showEditFolderDialog = true
    },
    editFolder() {
      this.showEditFolderDialog = false

      this.block(async unblock => {
        this.editAnalysisSettings.name = this.folderName

        await api.call('editAnalysisSettings', {
          analysisSettingsId: this.editAnalysisSettings.id,
          data: {
            name: this.folderName
          }
        }, unblock)
      })
    },
    async deleteFolder(analysisSettings, parentAnalysisSettings) {
      if (await this.confirmDelete('folder')) {
        this.block(async unblock => {
        await api.call('deleteAnalysisSettings', {
          analysisSettingId: analysisSettings.id
        })
        
        parentAnalysisSettings.children = parentAnalysisSettings.children.filter(childAnalysisSettings => childAnalysisSettings !== analysisSettings)
          unblock()
        })
      }
    },
    openAddBookmarkToFolderDialog(analysisSettings) {
      this.bookmarkName = null
      this.saveWithFilter = true
      this.addBookmarkFolder = analysisSettings
      this.showAddBookmarkToFolderDialog = true
    },
    addBookmarkToFolder() {
      const state = this.$store.state

      this.showAddBookmarkToFolderDialog = false

      this.block(unblock => {
        api.call('saveAnalysisSettings', {
          data: {
            parentId: this.addBookmarkFolder.id,
            name: this.bookmarkName,
            analysisSettings: state.analysisSettings,
            filter: this.saveWithFilter ? state.appliedFilterValues : undefined,
            analysisView: state.analysisView,
            viewName: state.view.name
          }
        }, analysisSettingsNode => {
          this.addBookmarkFolder.children.push(analysisSettingsNode)

          this.openFolders[this.addBookmarkFolder.id].open = true

          unblock()
        })
      })
    },
    clickTreeToggle() {
      let open = !this.openFolderTree[this.tree.id].open
      this.openFolderTree[this.tree.id].open = open

      this.updateOpenFolderTree()

      this.$forceUpdate()
    },
    openCreateFolderDialog() {
      this.folderName = null
      this.showCreateFolderDialog = true
    },
    createFolder() {
      this.showCreateFolderDialog = false

      this.block(unblock => {
        api.call('saveAnalysisSettings', {
          data: {
            parentId: this.tree.id,
            isFolder: true,
            name: this.folderName
          }
        }, folder => {
          this.openFolders[folder.id] = { open: true, children: {} }
          this.tree.children.push(folder)
          this.updateOpenFolderTree()
          unblock()
        })
      })
    },
    start() {
      this.$emit('dragStart')
    },
    end() {
      this.$emit('dragEnd')
    },
    change(event, openFolders, parentAnalysisSettings) {
      this.$emit('dragChange', event, this, parentAnalysisSettings)
    },
    toggleRootFolder(analysisSettings) {
      let open = !this.openFolders[analysisSettings.id].open
      this.openFolders[analysisSettings.id].open = open
      this.updateOpenFolderTree()

      this.$forceUpdate()
    },
    updateOpenFolderTree() {
      localStorageHelper.updateSourceValue('openFolderTree', {}, openFolderTree => {
        openFolderTree[this.tree.id] = this.openFolderTree[this.tree.id]
        return openFolderTree
      })
    }
  },
  async beforeMount() {
    this.openFolderTree = localStorageHelper.getSourceValue('openFolderTree', {})

    let openFolders
    let newOpenFolders = {}
    if (this.openFolderTree[this.tree.id]) {
      openFolders = this.openFolderTree[this.tree.id].children
    } else {
      openFolders = {}
      this.openFolderTree[this.tree.id] = { open: false }
    }

    this.openFolderTree[this.tree.id].children = newOpenFolders

    let linkedBookmarkId = this.$store.state.linkedBookmarkId
    let linkedBookmarkIdFound = false

    this.tree.children.forEach(analysisSettingsRoot => {
      if (linkedBookmarkId) {
        let found = !!analysisSettingsRoot.children.find(analysisSettings => analysisSettings.id === linkedBookmarkId)
        newOpenFolders[analysisSettingsRoot.id] = openFolders[analysisSettingsRoot.id] || { children: {} }
        newOpenFolders[analysisSettingsRoot.id].open = found
        linkedBookmarkIdFound = linkedBookmarkIdFound || found
      } else {
        newOpenFolders[analysisSettingsRoot.id] = openFolders[analysisSettingsRoot.id] || { open: false, children: {} }
      }
    })


    this.editRights = this.tree.public ? authorization.hasPermission('all:public_bookmarks') : true

    if (linkedBookmarkIdFound) {
      this.openFolderTree[this.tree.id].open = linkedBookmarkIdFound
    }

    this.updateOpenFolderTree()
  }
}
</script>

<style lang="scss" scoped>
  .analysis-settings-tree {
    border-top: 1px solid $border-grey;

    .root-folder {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 10px 5px 4px;
    }

    .root-folder-text {
      flex: 1 0 0;
      padding: 0 0 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .root-folder-icon {
      transition: transform 200ms;
    }

    .root-folders.closed {
      .bookmarks {
        height: 0;
      }

      .root-folder-icon {
        transform: rotate(-90deg);
      }
    } 

    .bookmarks {
      overflow: hidden;
    }

    .tree-content {
      overflow: hidden;
    }

    .tree-heading {
      display: flex;
      align-items: center;
      padding: 5px 10px 0 5px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid $border-grey;

      .tree-heading-toggle {
        cursor: pointer;
      }

      .tree-heading-icon {
        transition: transform 200ms;
      }

      .tree-heading-text {
        font-weight: 500;
        padding: 0 0 0 10px;
        vertical-align: middle;
        flex: 1 1 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .analysis-settings-tree.closed {
    .tree-content {
      height: 0;
    }

    .tree-heading-icon {
      transform: rotate(-90deg);
    }
  }

  .analysis-settings-tree:first-child {
    border-top: none;
  }
</style>