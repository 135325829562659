export default function completeAnalysisSettings(analysisSettings, analysisSettingsModel) {
  // Add defaults value for all settings that have no value at all. This can happen if a setting item was added to the
  // settings model after a bookmark was saved.
  let settings = analysisSettings;
  for (let settingsModelItem of analysisSettingsModel) {
    let settingsItem = settings[settingsModelItem.name];
    if (settingsItem === undefined) {
      settingsItem = settingsModelItem.default;
      settings[settingsModelItem.name] = settingsItem;
    }
    if (settingsModelItem.type === "multi_list") {
      if (settingsItem != null) {
        for (let multiListItem of settingsItem) {
          let name = multiListItem.name;
          for (let multiListItemModel of (settingsModelItem.items || [])) {
            if (multiListItemModel.name === name) {
              for (let parameter of (multiListItemModel.parameters || [])) {
                if (parameter.default != null && multiListItem[parameter.name] == null) {
                  multiListItem[parameter.name] = parameter.default;
                }
              }
              break;
            }
            
          }
        }
      }
    }  
    if (settingsModelItem.type === 'options') {
      if (settings[settingsModelItem.name] == null) {
        settings[settingsModelItem.name] = {};
      }
      completeOptionSettings(settings[settingsModelItem.name], settingsModelItem)
    }
  }
}
  
let completeOptionSettings = function(setting, optionModelItem) {
  
  let items = optionModelItem.items;
  if (items != null) {
    for (let item of items) {
      completeOptionSettings(setting, item)
    }
  }
  let settingsKey = optionModelItem.settingsKey;
  if (settingsKey != null) {
    let keyArray = settingsKey.split('.');
    let key;
    for (let i = 0; i < keyArray.length - 1; i++) {
      key = keyArray[i];
      if (setting[key] == null) {
        setting[key] = {};
      }
      setting = setting[key];   
    }
    key = keyArray[keyArray.length - 1];
    if (setting[key] == null) {
      setting[key] = optionModelItem.default;             
    }
    if (setting[key] === '[undefined]' || (setting[key] === '' && optionModelItem.type === 'number')) {
      setting[key] = null;             
    }
  }      

}  
