import store from '@/store'
import _ from 'lodash'

const Util = {
  AsyncPromiseQueue(run) {
    // Setting up an async queue so this function is only run in sequence
      
    // Queue for function call
    const queue = []
    
    return (...args) => {
      return new Promise(resolve => {
        queue.push([args, resolve])

        const next = () => {
          let queueReturn = run(...queue[0][0])
          let runQueueReturn = (...args) => {
            queue[0][1](...args)
            queue.shift()
            if (queue.length !== 0) {
              next()
            }
          }

          if (queueReturn instanceof Promise) {              
            queueReturn.then((...args) => {
              runQueueReturn(...args)
            })
          } else {
            runQueueReturn(queueReturn)
          }
        }

        if (queue.length === 1) {
          next()
        }
      })
    }
  },

  async asyncTimeout(ms) {
    await new Promise(resolve => setTimeout(resolve, ms))
  },

  getByPath(obj) {
    let keys = []

    for (let i = 1; i < arguments.length; i++) {
      let key = arguments[i]
      key && keys.push(key)
    }

    return _.get(obj, keys.join('.'))
  },

  async loading(run) {
    await store.dispatch('setLoading', true)
    await run()
    await store.dispatch('setLoading', false)
  },

  noop: function() {},
  
  setByPath(obj, value) {
    let keys = []

    for (let i = 2; i < arguments.length; i++) {
      let key = arguments[i]
      key && keys.push(key)
    }

    return _.set(obj, keys.join('.'), value)
  }
}

export default Util