import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Import language files
// import en from './en'
import en from './en'
import de from './de'

Vue.use(VueI18n)

// Export VueI18n instance with options
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  messages: { en, de } // set locale messages
})

export function setLocale(locale) {
  localStorage.setItem('locale', locale)
  i18n.locale = locale || 'en'
}

export function getLocale() {
  return i18n.locale
}

export function t(text) {
  return i18n.t(text)
}

export default i18n