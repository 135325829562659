<template>
<div class="setting-container md-layout-item" v-if="visible" :class="{'error': errorMessage != null}">
  <div class="md-subheading" style="color: #cd2927; display: flex; align-items: center; justify-content: flex-start;">
    <div>{{getLocalizedLabel(settingsModelItem)}}</div>
    <md-button v-if="settingsModelItem.type === 'ordered_list' || settingsModelItem.type === 'multi_list'" class="md-icon-button list-edit-button" @click="showListDialog(); editing = true">
      <md-icon>edit</md-icon>
    </md-button>
    <md-button v-if="settingsModelItem.type === 'options'" class="md-icon-button list-edit-button" @click="showOptionsDialog(); editing = true">
      <md-icon>edit</md-icon>
    </md-button>
    <md-button v-if="settingsModelItem.type === 'list_view'" class="md-icon-button list-edit-button" @click="showListDialog(); editing = true">
      <md-icon>info</md-icon>
    </md-button>
  </div>
  <div v-if="settingsModelItem.type === 'text'">
      <md-field class="text-field" md-inline>
        <label>(none)</label>
        <md-input :value="settingsValue" @change="updateValue($event.target.value)"></md-input>
      </md-field>
  </div>
  <div v-else-if="settingsModelItem.type === 'list_view'">
    <md-content class="md-scrollbar item-list item-list2">
      <div v-for="item in sourceSettingsValue" :key="item.name || item" class="list-group-item">
        <variable-badges v-if="isItemsVariables" :variable="itemNameMap[item.name || item]"></variable-badges>
        <div>{{getLocalizedLabel(item.name || item)}}
          <md-tooltip md-delay="500" v-if="getItemDescription(item.name || item)">{{getItemDescription(item.name || item)}}</md-tooltip>
        </div>
      </div>
    </md-content>
  </div>
  <div v-else-if="settingsModelItem.type === 'list_select_view'">
    <md-field class="menu-select-field" style="margin: -8px 8px 24px; width: auto;" >   
      <md-menu class="menu-select" md-direction="bottom-start"  md-size="huge" :md-active.sync="editing">
        <div class="menu-select-item">
          <variable-badges v-if="isItemsVariables" :variable="itemNameMap[settingsValue]"></variable-badges>
          <div>{{ getLocalizedLabel(settingsValue.name)}}</div>
          <md-tooltip md-delay="500" v-if="getItemDescription(settingsValue.name || settingsValue)">{{getItemDescription(settingsValue.name || settingsValue)}}</md-tooltip>
          <md-button class="md-icon-button" md-menu-trigger><md-icon style="color: #888;">arrow_drop_down</md-icon></md-button>
        </div>
        <md-menu-content>
          <md-menu-item v-for="item in sourceSettingsValue" :key="item.name" :value="item" @click="updateValue(item)" v-once>
            <div style="display: flex; align-items: center;">
                <variable-badges v-if="isItemsVariables" :variable="itemNameMap[item]"></variable-badges>
                {{getLocalizedLabel(item.name || item)}}             
            </div>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-field>
  </div>
  <div v-else-if="settingsModelItem.type === 'ordered_list'">
    <md-content class="md-scrollbar item-list item-list2" >
      <div class="list-group-item" v-for="item in settingsValue" :key="item.name || item">
        <variable-badges :variable="itemNameMap[item.name || item]"></variable-badges>
        <div>{{getLocalizedLabel(item)}}
          <md-tooltip md-delay="500" v-if="getItemDescription(item)">{{getItemDescription(item)}}</md-tooltip>
        </div>
      </div>
    </md-content>
    <md-dialog :md-active.sync="dialogVisible" @md-clicked-outside="updateValue(selectedListItems)">
     <md-toolbar md-elevation="0" class="md-title md-primary">{{ getLocalizedLabel(settingsModelItem) }}</md-toolbar>
     <div style="height: 420px; margin: 24px 32px; display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 32px;">
        <div style="display: flex; flex-direction: column; width: 300px;">
          <div class="md-subheading item-list-title" style="margin-bottom: 0; height: 32px">{{ $t('labels.available') }}</div>
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
               <md-field class="search-field">
                 <label>Search</label>
                <md-input ref="availableSearchTextInput" @focus="availableSearchText = availableSearchText || ''" @blur="availableSearchText = availableSearchText || null" @input="updateAvailableItems()" size=0 v-model="availableSearchText"></md-input>
                 <md-icon>search</md-icon>
              </md-field>
              <md-button @click="changeAvailableSorting()" class="md-icon-button" style="top: 8px; margin: 0;">
                <md-icon v-if="availableSorting === 'asc'" style="color: #888;">arrow_downward</md-icon>
                <md-icon v-else-if="availableSorting === 'desc'" style="color: #888;">arrow_upward</md-icon>
                <md-icon v-else style="color: #888;">sort_by_alpha</md-icon>
              </md-button>
          </div>
          <md-content class="md-scrollbar item-list item-select-list">
              <draggable :group="settingsModelItem.name" :list="availableFilteredListItems" :sort="false" style="height: 100%">
                <div class="list-group-item dnd-list-item md-content" v-for="item in availableFilteredListItems" :key="item.name || item">
                  <div>
                    <md-icon class="drag-indicator">drag_indicator</md-icon>
                  </div>
                  <variable-badges :variable="itemNameMap[item.name || item]"></variable-badges>
                  <div style="overflow: hidden; text-overflow: ellipsis;">{{ getLocalizedLabel(item) }}
                    <md-tooltip md-delay="500" v-if="getItemDescription(item)">{{getItemDescription(item)}}</md-tooltip>
                  </div>                  
                </div>
              </draggable>
          </md-content>
        </div>
        <div style="display: flex; flex-direction: column; width: 300px;">
          <div class="md-subheading item-list-title" style="height: 32px; margin-bottom: 52px;">{{ $t('labels.selected') }}</div>
          <md-content class="md-scrollbar item-list">
            <draggable :group="settingsModelItem.name" :list="selectedListItems" style="height: 100%">
              <div class="list-group-item dnd-list-item" v-for="selItem in selectedListItems" :key="selItem.name || selItem">
                <div>
                  <md-icon class="drag-indicator">drag_indicator</md-icon>
                </div>
                <variable-badges :variable="itemNameMap[selItem.name || selItem]"></variable-badges>
                <div>{{ getLocalizedLabel(selItem) }}
                  <md-tooltip md-delay="500">{{itemNameMap[selItem.name || selItem].description}}</md-tooltip>
                </div>
              </div>
            </draggable>
          </md-content>
        </div>
      </div>
      <md-dialog-actions>
        <md-button @click="dialogVisible = false">{{ $t('actions.cancel') }}</md-button>
        <md-button class="md-primary" @click="dialogVisible = false; updateValue(selectedListItems)">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>

  <!-- Multi List -->
  <div v-else-if="settingsModelItem.type === 'multi_list'">
    <md-content v-if="settingsValue.length !== 0" class="md-scrollbar item-list item-list2" >
      <div class="list-group-item" v-for="(item, itemIndex) in settingsValue" :key="itemIndex">
          {{ getLocalizedLabel(item.name)  + (item.calc_vars ?  " (" + variablesNameLabelMap[item.calc_vars] + ")": "")}}
          <md-tooltip md-delay="500" v-if="getItemDescription(item.name)">{{getItemDescription(item.name)}}</md-tooltip>
      </div>
    </md-content>
    <div v-else style="height: 8px"></div>
    <md-dialog :md-active.sync="dialogVisible"  @md-clicked-outside="updateValue(selectedListItems)">
     <md-toolbar md-elevation="0" class="md-title md-primary">{{ getLocalizedLabel(settingsModelItem) }}</md-toolbar>
      <!-- List of available items -->
      <div style="height: 420px; margin: 16px 32px 24px; display: flex; grid-column-gap: 32px;">
       <div style="display: flex; flex-direction: column; width: 300px;">
          <div class="md-subheading item-list-title">{{ $t('labels.available') }}</div>
          <md-content class="md-scrollbar item-list item-select-list">
              <draggable :group="{name: settingsModelItem.name, pull: 'clone', put: false}" :clone='cloneListItem' :list="availableListItems" :sort="false" style="height: 100%">
                <div v-for="(item, itemIndex) in availableListItems" handle=".handle" :key="itemIndex">
                  <div v-if="isItemVisible(item)" class="list-group-item dnd-list-item md-content" >
                    <div >
                      <md-icon class="drag-indicator">drag_indicator</md-icon>
                    </div>
                    <div>
                      {{ getLocalizedLabel(item.name)  + (item.calc_vars ? " (" + variablesNameLabelMap[item.calc_vars] + ")" : "")}}
                      <md-tooltip md-delay="500" v-if="getItemDescription(item.name)">{{getItemDescription(item.name)}}</md-tooltip>
                    </div>                            
                  </div>
                </div>
              </draggable>
          </md-content>
        </div>
        <!-- List of selected items -->
        <div style="display: flex; flex-direction: column; width: 480px;">
          <div class="md-subheading item-list-title">{{ $t('labels.selected') }}</div>
          <md-content class="md-scrollbar item-list item-select-list" style="flex: 1 1 0; overflow: auto; height: 100%;">
            <draggable :group="{name: settingsModelItem.name, pull: false}" :list="selectedListItems" style="height: 100%">
              <div class="ratio-list-item" v-for="(selItem, selItemIndex) in selectedListItems" :key="selItemIndex">
                <div style="background: #f2f2f2; padding-left: 12px; display: flex; align-items: center; justify-content: space-between;">
                  <div style="font-weight: 500;">
                    {{ getLocalizedLabel(selItem.name) }}
                  </div>
                  <md-button @click="selectedListItems.splice(selItemIndex, 1);" class="md-icon-button" style="margin: 0;">
                    <md-icon style="color: #888;">cancel</md-icon>
                  </md-button>
                </div>
                <!-- Parameters -->
                <div v-for="(parameter, parameterIndex) in itemNameMap[selItem.name].parameters" :key="parameterIndex">
                  <div v-if="isRatioParameterVisible(selItem, parameter)" class="parameter-list-item" >
                    <div v-once style="margin-left: 4px; ">{{ getLocalizedLabel(parameter) }}</div>
                    <md-field :class="{'no-field-separator': parameter.name === 'calc_vars'}" style="margin: -8px 8px 8px 16px; width: auto; min-height: 32px;">   
                      <div v-if="parameter.name === 'calc_vars'" style="display:flex; justify-content: stretch; width: 100%"> <!-- Allow selection of calcculation variables in dialog -->
                        <md-content class="md-scrollbar item-list" style="max-height: 116px;" >
                          <div class="list-group-item" v-for="variable in selItem.calc_vars" :key="parameterIndex + variable">
                            <variable-badges :variable="nameVariableMap[variable]"></variable-badges>
                            <div>{{variablesNameLabelMap[variable]}}
                              <md-tooltip md-delay="500" v-if="getItemDescription(variable)">{{getItemDescription(variable)}}</md-tooltip>
                            </div>
                          </div>
                        </md-content>
                         <md-button v-if="parameter.name === 'calc_vars'" class="md-icon-button list-edit-button" style="margin: 0;" @click="ratioVarsDialogVisible=true"><md-icon>edit</md-icon></md-button>
                         <variables-selection-dialog :variablesNameLabelMap="variablesNameLabelMap" :dialog-visible="ratioVarsDialogVisible" :availableVariables="parameter.availableItems" :selVarNames="selItem.calc_vars" @closed="ratioVarsDialogClosed(selItem, $event)">        
                         </variables-selection-dialog>
                       </div>    
                      <md-select v-else-if="parameter.type === 'list'" :value="selItem[parameter.name]" @md-selected="selItem[parameter.name] = $event" @md-opened="setParameterSelectOpened(parameter.name + selItemIndex)">
                        <!-- options will not be loaded until first opening of select. Use parameter-name + selItemIndex as key for select. -->
                        <template v-if="openedParameterSelects[parameter.name + selItemIndex]">
                          <!-- after select is opened, options contain all items -->
                          <md-option v-for="(parameterItem, parameterItemIndex) in parameter.availableItems" :key="parameterItemIndex" :value="parameterItem.name || parameterItem">
                            {{ getLocalizedLabel(parameterItem.name || parameterItem) }}
                          </md-option>
                        </template>
                        <template v-else>
                          <!-- initial options contain only selected item -->
                          <md-option :value="selItem[parameter.name]">
                            {{ getLocalizedLabel(selItem[parameter.name]) }}
                          </md-option>
                        </template>
                      </md-select>               
                      <md-select v-else-if="parameter.availableItems != null" :value="selItem[parameter.name]" @md-selected="selItem[parameter.name] = $event">
                        <md-option v-for="(parameterItem, parameterItemIndex) in parameter.availableItems" :key="parameterItemIndex" :value="parameterItem">
                          {{ getLocalizedLabel(parameterItem) }}
                        </md-option>
                      </md-select>
                      <md-input v-else-if="parameter.type === 'number'" type="number" :value="selItem[parameter.name]" @input="selItem[parameter.name] = $event"></md-input>
                    </md-field>                       
                  </div>
                </div>
              </div>
            </draggable>
          </md-content>
        </div>
      </div>
      <md-dialog-actions>
        <md-button @click="dialogVisible = false">{{ $t('actions.cancel') }}</md-button>
        <md-button class="md-primary" @click="dialogVisible = false; updateValue(selectedListItems)">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
   
  <div v-else-if="settingsModelItem.type === 'options'">
    <md-dialog :md-active.sync="dialogVisible"  @md-clicked-outside="updateValue(editableSettingsValue)">
     <md-toolbar md-elevation="0" class="md-title md-primary">{{ getLocalizedLabel(settingsModelItem) }}</md-toolbar>
      <div style="max-height: 640px">
      <analysis-option-item :optionModel="settingsModelItem" :optionSettings="editableSettingsValue"> </analysis-option-item>
      </div>
       <md-dialog-actions>
        <md-button @click="dialogVisible = false">{{ $t('actions.cancel') }}</md-button>
        <md-button class="md-primary" @click="dialogVisible = false; updateValue(editableSettingsValue)">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>


  <md-field v-else-if="settingsModelItem.items" class="menu-select-field" style="margin: -8px 8px 24px; width: auto;" >
    <md-select v-if="settingsModelItem.type === 'number'" :name="settingsModelItem.name" :value="settingsValue" @md-selected="updateValue">
      <md-option v-for="item in settingsModelItem.items" :key="item" :value="item" v-once>
        {{ getLocalizedLabel(item) }}
      </md-option>
    </md-select>
    <md-menu v-else class="menu-select" md-direction="bottom-start"  md-size="huge" :md-active.sync="editing">
      <div class="menu-select-item">
        <variable-badges v-if="isItemsVariables" :variable="itemNameMap[settingsValue]"></variable-badges>
        <div>{{ getLocalizedLabel(itemNameMap[settingsValue])}}</div>
        <md-tooltip md-delay="500" v-if="getItemDescription(settingsValue.name || settingsValue)">{{getItemDescription(settingsValue.name || settingsValue)}}</md-tooltip>
        <md-button class="md-icon-button" md-menu-trigger><md-icon style="color: #888;">arrow_drop_down</md-icon></md-button>
      </div>
      <md-menu-content>
        <md-menu-item v-for="item in availableListItems" :key="item.name" :value="item" @click="updateValue(item)" v-once>
          <div style="display: flex; align-items: center;">
              <variable-badges v-if="isItemsVariables" :variable="itemNameMap[item]"></variable-badges>
              {{ getLocalizedLabel(itemNameMap[item]) }}
          </div>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </md-field>
  
  <md-field v-else-if="settingsModelItem.type === 'number'">
    <md-input type="number" v-model="empty"></md-input>
  </md-field>
  
  <div v-else>
    {{ settingsModelItem.label }}
  </div>
   
  <div v-if="errorMessage != null" style="color: red; font-weight: 500;" >
    <span md-caption style="white-space: wrap;">{{errorMessage}}</span>
  </div>

</div>
</template>



<script>

import evalExpression from '@/services/boolExEval';
import { getLocale, t } from '@/i18n';
import Vue from 'vue'
import VariablesSelectionDialog from './VariablesSelectionDialog.vue';

export default {
  components: { VariablesSelectionDialog },
  name: 'AnalysisSettingsItem',
  props: ['analysisView', 'settings', 'settingsModel', 'settingsModelItem', 'settingsItemName'],
  data() {
    return {
      openedParameterSelects: {},
      availableListItems: null,
      availableFilteredListItems: [],
      selectedListItems: [],
      availableSorting: null,
      availableSearchText: null,
      dialogVisible: false,      
      isItemsVariables: false,
      allVariables: null,
      nameVariableMap: {},
      variablesNameLabelMap: {},
      errorMessage: null,
      editing: false,
      editableSettingsValue: null,

      ratioVarsDialogVisible: false,
      selectedRatioVars: [],
      availableRatioVars: []
    }
  },
  methods: {
    cloneListItem(obj) {
      // Create a deep clone of the list item
      return JSON.parse(JSON.stringify(obj));
    },
    getItemDescription(item) {
      if (typeof item == 'string') { // item is actually item name
        item = this.itemNameMap[item];
      }
      let description = item ? item.description : null;
      if (description === '') {
        description = null;
      }
      return  description;
    },
    getLocalizedLabel(item) {
      if (typeof item == 'string') { // item is probably item name
        item = this.itemNameMap[item] || item;
      }
      return  item == null ? '' : (item.label_int && item.label_int[getLocale()]) || item.label || item;
    },
    updateValue(value) {
      this.ungroupRatios();
      
      if (!_.isEqual(value, this.settingsValue)) {
        if (Array.isArray(value)) {
          if (this.settingsValue == null) {
            this.settingsValue = value;
          } else {
            this.settingsValue.splice(0);
            this.settingsValue.splice(0, 0, ...value);
          } 
        } else {
          this.settingsValue = value;
        }
        this.$emit('input', this.settingsValue);
      }
    },
    showListDialog() {
      this.availableSearchText = null;
      this.updateLists();
      // make dialog visible
      this.dialogVisible = true;
    },
    showOptionsDialog() {
      this.editableSettingsValue = JSON.parse(JSON.stringify(this.settingsValue || {}));
      // make dialog visible
      this.dialogVisible = true;
    },
    setParameterSelectOpened(parameterName) {
      Vue.set(this.openedParameterSelects, parameterName, true);
    },
    updateLists(isInit) {
      let settingsModelItem = this.settingsModelItem;
      // TODO: Überprüfen ob settingsModelItem.hidden hier sinnvoll ist
      //       Es wird zur Zeit benötigt da die default Angaben für datasets_sort_model im analysisSettingsModel zu einem Fehler führen
      if (!(settingsModelItem.type === 'list' || settingsModelItem.type === 'ordered_list' || settingsModelItem.type === 'multi_list') || settingsModelItem.hidden === 'true') {
        return;
      }

      // get items after applying filter
      let filteredItems = this.filterItems(settingsModelItem.items, settingsModelItem.list_filter);
      let filteredItemNameSet = {};
      for (let item of filteredItems) {
        filteredItemNameSet[item.name] = true;
      }

      // Remove all elements from settings value that were filtered
      if (Array.isArray(this.settingsValue)) {
        for (let i = this.settingsValue.length - 1; i >= 0; i--) {
          let settingsValueName = this.settingsValue[i].name || this.settingsValue[i];
          if (!filteredItemNameSet[settingsValueName]) {
            if (isInit) {
               console.error('Item "' + settingsValueName + '" of "' + settingsModelItem.name + '" in selection, but not in items list');
            }
            this.settingsValue.splice(i, 1);
          }
        }
      }

      // Make the selected items a deep clone of the settings value. This way we can also detect changes
      // deep in the object hierarchy, for example if paramters of ratios are changed.
      //if (this.selectedListItems.length === 0) {
        let selectedItems;
        selectedItems = JSON.parse(JSON.stringify(this.settingsValue || []));
        this.selectedListItems.splice(0, this.selectedListItems.length, ...selectedItems);
      //}

      this.groupRatios();      

      let selectedItemNameSet = {};
      if (this.selectedListItems) {
        for (let selectedItem of this.selectedListItems) {
          selectedItemNameSet[selectedItem.name || selectedItem] = selectedItem;
        }
      }

      if (settingsModelItem.type === 'list' || settingsModelItem.type === 'ordered_list') {
        let availableItems = [];
        for (let item of filteredItems) {
          if (!selectedItemNameSet[item.name]) {
            let availableItem;
            if (settingsModelItem.parameters) {
              // If the settings model item has the property "parameters", the items will be an object with a name and
              // the items' parameters as keys
              availableItem = {name: item.name};
              for (let parameterName in item.parameters) {
                availableItem[parameterName] = item.parameters[parameterName];
              }
            } else {
              availableItem = item.name;
            }

            if (availableItems.indexOf(availableItem) !== -1) {
              console.log(availableItem)
            }

            availableItems.push(availableItem);
          }
        }
        this.availableListItems = availableItems;        
        this.updateAvailableItems()
      } else if (settingsModelItem.type === 'multi_list') {
        this.availableListItems = [];
        for (let item of filteredItems) {
          let ratio = {name: item.name, label: item.name};
          let parameters = item.parameters;
          // Set default values for ratio parameters
          for (let parameter of parameters) {
            if (parameter.name === 'calc_vars') {
              // replace null with empty array for calc_vars
              parameter.default = parameter.default || [];
            } 
            ratio[parameter.name] = parameter.default;             
            parameter.availableItems = this.filterItems(parameter.items, parameter.list_filter);
          }
          this.availableListItems.push(ratio);
        }
      }
    },
    updateAvailableItems() {      
        // determine available items
        let selectedItems = this.selectedListItems;
        let availableItems = this.availableListItems.slice();

        availableItems = availableItems.filter(value =>  {
          let searchText = this.availableSearchText;
          if (selectedItems.indexOf(value) >= 0) {
            return false;
          }
          if (searchText) {
            let searchRegex = new RegExp(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'ig');
            return this.getLocalizedLabel(value).match(searchRegex);
          }
          return true;
        });
        // sort filtered items
        if (this.availableSorting) {
          availableItems.sort((a, b) => {
            return (a.label || a).localeCompare(b.label || b) * (this.availableSorting === 'desc' ? -1 : 1);
          })
        }

        this.availableFilteredListItems = availableItems;
    },
    changeAvailableSorting() {
      if (this.availableSorting === 'asc') {
        this.availableSorting = 'desc';
      } else if (this.availableSorting === 'desc') {
        this.availableSorting = null;
      } else {
        this.availableSorting = 'asc';
      }
      this.updateAvailableItems();
    },
    filterItems(items, list_filter) {
      if (items != null) {
        let filteredItems = [];
        for (let item of items) {
          let isFiltered = false;
          if (list_filter && !evalExpression(list_filter, {view: this.analysisView, settings: this.settings, item: item})) {
            isFiltered = true;
          } else if (item.filter && !evalExpression(item.filter, {view: this.analysisView, settings: this.settings, item: item})) {
            isFiltered = true;
          }
          if (!isFiltered) {
            filteredItems.push(item);
          }
        }
        return filteredItems;
      }
    },
    isRatioParameterVisible(ratio, parameter) {
      if (parameter.hidden === 'true' || parameter.hidden === true) {
        return false;
      } else if (parameter.filter) { // if filter not undefined, null or empty string
        return evalExpression(parameter.filter, {view: this.analysisView, settings: this.settings, ratio: ratio});
      } else {
        return true;
      }
    },
    isItemVisible(item) {
      if (item.hidden === 'true' || item.hidden === true) {
        return false;
      } else if (item.filter) {
        return evalExpression(item.filter, {view: this.analysisView, settings: this.settings});
      } else {
        return true;
      }
    },
    // called from outside if settings changed. The filtering of items may be different due to changes in other parts of the settings,
    // therefore we update the selected and available.
    settingsChanged() {
      this.updateLists();
      this.updateErrorMessage();
    },
    updateErrorMessage() {
      let settingsModelItem = this.settingsModelItem;
      let error = null;
      if (settingsModelItem.type === 'ordered_list' || settingsModelItem.type === 'multi_list') {
        let selItemCount = this.settingsValue ? this.settingsValue.length : 0;
        if (!error && settingsModelItem.min != null) {
          if (selItemCount < settingsModelItem.min) {
            error = t('texts.ERROR_TOO_FEW_ITEMS_SELECTED') + settingsModelItem.min;
          }
        }
        if (!error && settingsModelItem.max != null) {
          if (selItemCount > settingsModelItem.max) {
            error =  t('texts.ERROR_TOO_MANY_ITEMS_SELECTED') + settingsModelItem.max;
          }
        }
      }
      if (!error && settingsModelItem.type === 'multi_list' && this.settingsValue) {
        let itemModelNameMap = {};
        for (let itemModel of settingsModelItem.items) {
          itemModelNameMap[itemModel.name] = itemModel;
        }
        // check selected items
        for (let listItem of this.settingsValue) {
          let itemModel = itemModelNameMap[listItem.name];
          if (itemModel) {
            let parametersModel = itemModel.parameters;
            if (parametersModel) {
              for (let parameterModel of parametersModel) {
                let parameterValue = listItem[parameterModel.name];
                if (parameterValue == null) {
                  error =  t('texts.ERROR_NO_SELECTION_FOR') + this.getLocalizedLabel(itemModel) + ' / ' +  this.getLocalizedLabel(parameterModel);
                }
              }
            }
          }
        }
      }
      this.errorMessage = error;
    },
    groupRatios() {
      // Iterate over all ratios and combine ratios of same type if they have variables as parameters
      // This is done when the ratios dialog is opened
      let prevRatio;
      for (let i = this.selectedListItems.length - 1; i >= 0; i--) {
        let ratio = this.selectedListItems[i];
        if (ratio.calc_vars != null) {          
          if (prevRatio != null && prevRatio.name === ratio.name && prevRatio.calc_vars != null) {
            prevRatio.calc_vars.unshift(ratio.calc_vars);
            this.selectedListItems.splice(i, 1);
          } else {
            ratio.calc_vars = [ratio.calc_vars];
            prevRatio = ratio;              
          }       
        } else {
          prevRatio = ratio;              
        }
      }
    },
    ungroupRatios() {
      // Ungroup the previously grouped ratios.
      // This is done when the ratios dialog is closed.
      for (let i = this.selectedListItems.length - 1; i >= 0; i--) {
        let ratio = this.selectedListItems[i];
        if (ratio.calc_vars != null) {          
          for (let j = 1; j < ratio.calc_vars.length; j++) {
            let ratioCopy = JSON.parse(JSON.stringify(ratio));
            ratioCopy.calc_vars = ratio.calc_vars[j];
            this.selectedListItems.splice(i + j, 0, ratioCopy);
            ratioCopy.id = ratio.name + j;
          }  
          ratio.calc_vars = ratio.calc_vars[0];     
        }
      }
    },
    ratioVarsDialogClosed(ratio, selVariables) {
      this.ratioVarsDialogVisible = false;
      if (selVariables != null) {
        if (ratio.calc_vars == null) {
          Vue.set(ratio, 'calc_vars', []);
        } else {
          ratio.calc_vars.splice(0);
        }
        ratio.calc_vars.splice(0, .0, ...selVariables);
      }
    }
  },
  beforeMount() {
    this.allVariables = this.$store.state.view.variables.slice();
    
    this.nameVariableMap = this.allVariables.reduce(function(map, variable) {
        map[variable.name] = variable;
        return map;
    }, {});

    let settingsModelItem = this.settingsModelItem;
    if (settingsModelItem.source) {
      if (settingsModelItem.type === 'list_view') {
        this.settingsValue = this.$store.state.analysisSettings[settingsModelItem.source];
      }
      let sourceModelItem = this.settingsModel.find(element => element.name === settingsModelItem.source);
      settingsModelItem.items = sourceModelItem.items;
      settingsModelItem.isItemsVariables = sourceModelItem.isItemsVariables;
    }

    if (settingsModelItem.items) {
      if (settingsModelItem.items === '$variables') {
        // set a copy of the view's variables as the items
        settingsModelItem.items = this.$store.state.view.variables.slice();
        settingsModelItem.isItemsVariables = true;
      }
      this.isItemsVariables = settingsModelItem.isItemsVariables;
      // create a map from item name to item
      this.itemNameMap = {};
      // fill itemNameMap
      for (let item of settingsModelItem.items) {
        this.itemNameMap[item.name || item] = item;
      }
    }
    if (settingsModelItem.type === 'list') {
      // update list of available items for type 'list' only once at beginning
      this.updateLists();      
    } else if (settingsModelItem.type === 'multi_list') {
      // prepare multi list
      for (let item of settingsModelItem.items) {
        let parameters = item.parameters;
        for (let parameter of parameters) {
          if (parameter.items === '$variables' || parameter.isVariables) {
            parameter.isVariables = true;
            parameter.items = this.$store.state.view.variables.slice();
            for (let variable of parameter.items) {
              this.variablesNameLabelMap[variable.name] = variable.label;
            }
          }
        }
      }
    }     

    // Determine error message before first render
    this.updateErrorMessage();
  },
  computed: {
    visible() {
      return this.isItemVisible(this.settingsModelItem);
    },
    sourceSettingsValue() {      
      let modelItem = this.settingsModelItem;
      if (modelItem.source) {
        let settings = this.settings;
        let sourceModelItem = this.settingsModel.find(element => element.name === modelItem.source);
        let sourceSettings = settings[modelItem.source];
        if (sourceModelItem.type === 'ordered_list' || sourceModelItem.type === 'multi_list') {
            if (modelItem.max < sourceSettings.length) {
                return sourceSettings.slice(0, modelItem.max);
            } else {
                return sourceSettings;
            }
        }      
      }
      return null;
    },
    settingsValue: {
      get () {
        let settings = this.settings;
        let settingsValue = settings[this.settingsItemName];
        if (this.settingsModelItem.type === 'list_select_view') {
          let sourceSettingsValue = this.sourceSettingsValue;
          if (settingsValue == null || (settingsValue.id != null && sourceSettingsValue.find(element => element.id === settingsValue.id) == null)) {
            settingsValue = this.sourceSettingsValue[0];
            settings[this.settingsItemName] = settingsValue;
          }          
        }
        return settingsValue;        
      },
      set (value) {
        this.settings[this.settingsItemName] = value;
      }
    },
    
  }
}
</script>


<style lang="scss" scoped>

  .setting-container {
    padding: 0;
    border-left: 4px solid transparent;
  }

  .setting-container.error {
    border-left-color: red;
    background: inherit !important;
  }

  .item-list-title {
    color: $primary;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-field {
    transition: width 200ms;
    width: 100%;
    min-height: 32px;
    margin: 0 16px 0 8px;
  }

  .item-list {
    flex: 1 1 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    border: 1px solid #ccc;
  }

  .item-list2 {
    max-height: 116px;
    margin: 4px 8px 24px;
    border-color: #bbb !important;
  }

  .list-group-item {
    font-size: 14px;
    border-top: 1px solid #eee;
    margin-top: -1px;
    padding: 4px 12px 4px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-overflow: ellipsis;
    cursor: default;
  }

  .dnd-list-item {
    padding-left: 0;
    cursor: grab;    
  }

  .dnd-list-item>div:first-of-type {
    padding-right: 8px;
  }

  .dnd-list-item:hover {
    background-color: #f2f2f2;
  }

 .list-group-item .drag-indicator {
   color:#ccc;
 }

  .md-list-item-container {
    color: inherit;
  }

  .list-group-item .md-list-item-content {
    color: green;
    min-height: 40px;
  }

  .error-icon {
    color: red !important;
  }

  .list-edit-button {
    margin-left: auto;
    opacity: 0.2;
    transition: opacity 0.4s;
    transform: scale(0.9);
  }

  .md-layout-item:hover .list-edit-button,
  .parameter-list-item .list-edit-button:hover {
    opacity: 1;
  }

  .text-field {
    min-height: 32px;
    margin: 4px 8px 24px;
    padding-top: 0;
    width: auto;
  }

  .text-field label {
      top: 7px;
  }

  .md-focused.text-field label {
      top: 7px;
      opacity: 0;
      color: inherit;
      font-weight: inherit;
      text-transform: inherit;
  }

  .ratio-list-item {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    text-overflow: ellipsis;
  }

  .ratio-list-item:not(:first-of-type) {
    border-top: 1px solid #ddd;
  }

  .parameter-list-item {
    padding: 4px 12px 4px 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .parameter-list-item:last-of-type {
    padding-bottom: 12px;
  }

  .menu-select {
      width: 100%;
      margin-top: 5px;
      cursor: default;
  }

  .menu-select>div:first-child {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 10px;
    border: 1px solid #bbb;

  }

  .menu-select .md-button {
    margin-left: auto;
    min-width: 28px;
    width: 28px;
    height: 28px;
  }

  .menu-select-field::after {
    display: none;
  }

  .no-field-separator::after {
    background: none;
  }

</style>


<style lang="scss" >

    .settings-container {

      .md-subheading {
        font-size: 16px;
        font-weight: 500;
      }

      .md-select .md-input {
        font-weight: 400 !important;
      }

      .md-field.md-theme-default.md-has-value .md-input {
        font-size: 14px;
      }

    }

    .md-select-menu {
      max-width: 320px;
    }

</style>