<template>
  <div>
    <div v-if="optionModel.type === 'options'" class="options-panel">
      <div class="menu">
        <div v-for="(optionGroup, index) in optionModel.items" v-bind:key="optionGroup.name" @click="selGroupIndex=index" class="md-subheading menu-item"  v-bind:class="{'sel-menu-item': selGroupIndex === index }">
          {{optionGroup.label}}
        </div>
      </div>  
      <div class="options-editor">
        <analysis-option-item v-for="(optionGroup, index) in optionModel.items" v-bind:key="optionGroup.name" v-bind:optionModel="optionGroup" v-bind:optionSettings="optionSettings" class="options-group" v-bind:class="{ visible: selGroupIndex === index }">
        </analysis-option-item>
      </div>
    </div>
    <div v-else-if="optionModel.type === 'group'" class="option-group">
      <div style="width: 100%; padding: 16px 48px 40px 48px;">
        <analysis-option-item v-for="item of optionModel.items" v-bind:key="item.name"  v-bind:optionModel="item" v-bind:optionSettings="optionSettings"></analysis-option-item>
      </div>
    </div>
    <div v-else-if="optionModel.type === 'heading'" class="option-heading">
      {{optionModel.label}}
    </div>
    <div v-else-if="isVisible()" style="display: flex; align-items: center;">
      <div class="option-label">{{optionModel.label}}</div>
      <md-field v-if="optionModel.type === 'text'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-input v-model="inputValue" @input="updateValue" :placeholder="optionModel.emptyText" ></md-input>
      </md-field>
      <md-field v-if="optionModel.type === 'mtext'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-textarea v-model="inputValue" @input="updateValue" :placeholder="optionModel.emptyText" ></md-textarea>
      </md-field>
      <md-field v-else-if="optionModel.type === 'number'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-input v-model="inputValue" type="number" @input="updateValue" ></md-input>
      </md-field>
      <md-field v-else-if="optionModel.type === 'numbers_text'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-input v-model="inputValue" type="text" @input="updateValue" placeholder="(example: 1, 2, 3, 7)"></md-input>
      </md-field>
      <div v-else-if="optionModel.type === 'boolean'" style="padding: 0; margin: 6px 32px; width: 100%;">
        <md-checkbox v-model="inputValue" @change="updateValue"></md-checkbox>
      </div>
      <md-field v-else-if="optionModel.type === 'color'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-input v-model="inputValue" @input="updateValue" ></md-input>
      </md-field>
      <md-field v-else-if="optionModel.type === 'list'" md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
        <md-select v-model="inputValue">
          <md-option v-for="(item, itemIndex) in optionModel.listItems" :key="itemIndex" :value="item.value">
            {{ item.label }}
          </md-option>
        </md-select>
      </md-field>
      <div v-else-if="optionModel.type === 'color_array'" md-inline style="padding: 16px 0 0; margin: 0 0 16px; width: 100%;">
        <div v-if="optionValue!=null">
          <div  v-for="(item, index) in optionValue" :key="index" style="display: flex; align-items: center;">
            <div>{{index}}</div>
            <div class="color-preview" :style="{ background: optionValue[index] }" @click="showColorPickerFor(index)"></div>
            <md-field md-inline style="padding: 16px 0 0; margin: 0 0 16px;">
              <md-input :value="inputValue[index]" @input="updateValue($event, index)" @blur="$event.target.value = optionValue[index]"></md-input>
            </md-field>
            <md-menu md-direction="bottom-start">
              <md-button md-menu-trigger class="md-icon-button"><md-icon>more_vert</md-icon></md-button>
              <md-menu-content>
                <md-menu-item @click="removeArrayItem(index)">{{ $t('actions.delete') }}</md-menu-item>
                <md-menu-item @click="addArrayItem(item, index+1)">{{ $t('actions.duplicate') }}</md-menu-item>
                <md-menu-item @click="moveUpArrayItem(index)" v-show="index > 0">{{ $t('actions.move_up') }}</md-menu-item>
                <md-menu-item @click="moveDownArrayItem(index)" v-show="index < inputValue.length-1">{{ $t('actions.move_down') }}</md-menu-item>
              </md-menu-content>
            </md-menu>         
          </div>
          <div style="display: flex; align-items: center; width: 100%; justify-content: space-between;">
            <md-button @click="resetSettings"><md-icon>replay</md-icon>Reset</md-button>
            <md-button @click="addArrayItem('#3476ff')" class="add-button"><md-icon>add</md-icon>{{ $t('actions.add_color') }}</md-button>
          </div>
        </div>
      </div>
      <div v-else-if="optionModel.type === 'series_array'" md-inline style="padding: 16px 0 0; margin: 0 0 16px; width: 100%;">
        <div v-if="optionValue!=null">
          <div  v-for="(item, index) in optionValue" :key="index" style="display: flex; align-items: center;">
            <div>{{index}}</div>
            <div class="color-preview" :style="{ background: optionValue[index].color }" @click="showColorPickerForSeries(index)"></div>
            <md-field md-inline style="padding: 16px 0 0; margin: 0 20px 16px;">
              <md-input :value="inputValue[index].color" @input="updateValue($event, index, 'color')" @blur="$event.target.value = optionValue[index]"></md-input>
            </md-field>
            <md-field md-inline style="width: 300px; padding: 16px 0 0; margin: 0 0 16px;">



              <md-select :value="inputValue[index].type" @input="updateValue($event, index, 'type')" @blur="$event.target.value = optionValue[index]">
                <md-option value="inherit">Inherit</md-option>
                <md-option value="line">Line</md-option>
                <md-option value="spline">spline</md-option>
                <md-option value="column">column</md-option>
                <md-option value="bar">bar</md-option>
                <md-option value="area">area</md-option>
              </md-select>
            </md-field>



            <md-menu md-direction="bottom-start">
              <md-button md-menu-trigger class="md-icon-button"><md-icon>more_vert</md-icon></md-button>
              <md-menu-content>
                <md-menu-item @click="removeArrayItem(index)">{{ $t('actions.delete') }}</md-menu-item>
                <md-menu-item @click="addArrayItem(item, index+1)">{{ $t('actions.duplicate') }}</md-menu-item>
                <md-menu-item @click="moveUpArrayItem(index)" v-show="index > 0">{{ $t('actions.move_up') }}</md-menu-item>
                <md-menu-item @click="moveDownArrayItem(index)" v-show="index < inputValue.length-1">{{ $t('actions.move_down') }}</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
          <div style="display: flex; align-items: center; width: 100%; justify-content: space-between;">
            <md-button @click="resetSettings"><md-icon>replay</md-icon>Reset</md-button>
            <md-button @click="addArrayItem({color: '#3476ff', type: 'inherit'})" class="add-button"><md-icon>add</md-icon>Add Series</md-button>
          </div>
        </div>
      </div>
      <md-field v-else-if="optionModel.type === 'json'"  style="padding: 0; margin: 16px 0 16px;">
          <md-textarea :value="inputValue" @input="updateValue" spellcheck="false"></md-textarea>
      </md-field>
      <md-button v-if="optionModel.type !== 'color' && optionModel.type !== 'color_array' && optionModel.type !== 'series_array'" class="md-icon-button reset-button" @click="resetInput">
        <md-icon>replay</md-icon>
        <md-tooltip md-direction="top">{{ $t('tooltips.SET_TO_DEFAULT') }}</md-tooltip>
      </md-button>
    </div>    
    <canvas ref="dummyCanvas" width=1 height=1 style="display: none"></canvas>
    <md-dialog v-if="optionModel.type === 'color_array' || optionModel.type === 'series_array'" class="color-dialog" :md-active.sync="showColorPicker" :md-click-outside-to-close=false>
      <color-picker v-model="editingColor"></color-picker>
      <md-dialog-actions>
        <md-button class="md-secondary" @click="showColorPicker = false">Cancel</md-button>
        <md-button class="md-primary" @click="inputValue[editingIndex].color = editingColor; showColorPicker = false">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>


<script>
import evalExpression from '@/services/boolExEval';

export default {
  name: 'optionSettingsItem',
  props: {
    optionModel: Object,
    optionSettings: Object,
  },
  data() {
    return {
      selGroupIndex: 0,
      showColorPicker: false,
      editingColor: '#ff000',
      editingIndex: 0,
    }
  },
  mounted() {
    let settings = this.optionSettings;
    this.cleanSettings(settings);    
  },
  methods: {  
    resetInput() {
      let optionModel = this.optionModel;
      let defaultVal = optionModel.default;
      if (defaultVal == null && (optionModel.type === 'mtext' || optionModel.type === 'text')) {
        defaultVal = '';
      }
      this.inputValue = defaultVal;
    },
    resetSettings() {
      this.optionValue = structuredClone(this.optionModel.default);
    },
    cleanSettings(settings) {
      let keys = Object.keys(settings);
      keys.forEach(key => {
        if (key === 'undefined' || key === '[object Object]') {
          delete settings[key];
        } else if (typeof settings[key] === 'object' && settings[key] !== null) {        
          this.cleanSettings(settings[key]);
        }
      })
    },
    updateValue(value, index, field) {
      if (this.optionModel.type === 'numbers_text') {
        value = value.trim();
        let inputValue = '';
        let m = value.match(/(?:([1-8]+).*?)/g);        
        if (m != null) {
          for (let i = 0; i < m.length; i++) {
            if (i > 0) {
              inputValue += ', ';
            }
            inputValue += m[i];
          }
        }
        this.inputValue = inputValue;          
      } else if (this.optionModel.type === 'color_array') {
        value = value.trim();
        if (/^#[0-9a-fA-F]{6}$/.test(value)) {
          this.$set(this.inputValue, index, value);
          this.inputValue = this.inputValue;
        }
      } else if (this.optionModel.type === 'series_array') {
        value = value.trim();
        if (field !== 'color' || /^#[0-9a-fA-F]{6}$/.test(value)) {
          //this.$set(this.inputValue, index, value);
          this.inputValue[index][field] = value;
          this.inputValue = this.inputValue;
        }
      } else {
       this.inputValue = value;            
      }
    },
    getValue() {
      let settings = this.optionSettings;
      let settingsKey = this.optionModel.settingsKey;
      if (settingsKey != null) {
        let settingsKeyArray = settingsKey.split('.');
        for (let i = 0; i < settingsKeyArray.length; i++) {
          let key = settingsKeyArray[i];
          if (settings == null) {
            break;
          } else {
            settings = settings[key];
          }            
        }
        if (settings === null) {
          settings = this.optionModel.default;
        }
        return settings;
      }
    },
    isVisible() {
      let optionModel = this.optionModel;
      if (optionModel.hidden === 'true' || optionModel.hidden === true) {
        return false;
      } else if (optionModel.filter) {
        return evalExpression(optionModel.filter, {options: this.optionSettings});
      } else {
        return true;
      }
    },
    addArrayItem(item, index) {
      if (this.inputValue == null) {
        this.inputValue = [];
      }
      this.inputValue.splice(index == null ? this.inputValue.length : index, 0, item);
      this.inputValue = this.inputValue;
    },
    removeArrayItem(index) {
      this.inputValue.splice(index, 1);
      this.inputValue = this.inputValue;
    },
    moveUpArrayItem(index) {
      if (index > 0) {
        let item1 = this.inputValue[index - 1];
        let item2 = this.inputValue[index];
        this.inputValue.splice(index - 1, 2, item2, item1);
        this.inputValue = this.inputValue;
      }
    },
    moveDownArrayItem(index) {
      if (index < this.inputValue.length - 1) {
        let item1 = this.inputValue[index];
        let item2 = this.inputValue[index + 1];
        this.inputValue.splice(index, 2, item2, item1);
       this.inputValue = this.inputValue;
      }
    },
    showColorPickerFor(index) {
      this.showColorPicker = true;
      this.editingColor = this.inputValue[index];
      this.editingIndex = index;
    },
    showColorPickerForSeries(index) {
      this.showColorPicker = true;
      this.editingColor = this.inputValue[index].color;
      this.editingIndex = index;
    }
  },
  computed: {
    inputValue: {
      get() {
        let inputValue = Array.isArray(this.optionValue) ? this.optionValue.slice() : this.optionValue;
        if (this.optionModel.type === 'list') {
          if (inputValue == null) {
            inputValue = '[undefined]';
          }
        }
        return inputValue;
      },
      set(inputValue) {          
        if (inputValue === '[undefined]') {
          if (this.optionModel.type === 'list' || this.optionModel.type === 'number') {
            inputValue = null;
          }
        } else if (this.optionModel.type === 'number') {
          if (inputValue === '') {
            inputValue = null;
          } else {
            inputValue = Number.parseInt(inputValue);
            if (isNaN(inputValue)) {
              inputValue = null;
            }
          }
        }
        this.optionValue = inputValue;
      }
    },
    optionValue: {
      get () {
        let dummy = this.dummy; // only used to ensure new calculation after change of optionValue
        let settings = this.optionSettings;
        let settingsKey = this.optionModel.settingsKey;
        if (settingsKey != null) {
          let settingsKeyArray = settingsKey.split('.');
          for (let i = 0; i < settingsKeyArray.length; i++) {
            let key = settingsKeyArray[i];
            if (settings == null) {
              break;
            } else {
              settings = settings[key];
            }            
          }
          if (settings === undefined) {
            settings = this.optionModel.default;
          }
          let optionValue = settings;
          let valueMapping = this.optionModel.valueMapping;
          if (valueMapping != null) {
            let key = Object.keys(valueMapping).find(key => _.isEqual(valueMapping[key], optionValue));
            optionValue = key;
            if (this.optionModel.type === 'boolean') {
              optionValue = optionValue == null ? null : optionValue.toLowerCase() === 'true';
            } else if (this.optionModel.type === 'number') {
              optionValue = Number.parseFloat(optionValue);
            } 
          }
          return optionValue;
        }
      },
      set(value) {
        let valueMapping = this.optionModel.valueMapping;
        if (valueMapping != null && value in valueMapping) {
          value = valueMapping[value];
        }
        let settings = this.optionSettings;
        let settingsKeyArray = this.optionModel.settingsKey.split('.');
        for (let i = 0; i < settingsKeyArray.length; i++) {
          let key = settingsKeyArray[i];
          if (i < settingsKeyArray.length - 1) {
            if (settings[key] == null) {
              if (value == null) {
                break;
              } else {
                this.$set(settings, key, {});
              }
            }
            settings = settings[key];
          } else {
            if (value == null) {
              if (settings) {
                delete settings[key];
              }
            } else {
              this.$set(settings, key, value);
            }
          }
        }
      }      
    },
  },
}

</script>


<style lang="scss">
  
  .option-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  flex-start;   
  } 

  .option-label {
    width: 200px;
    margin: 0 20px 0 10px;
  }

  .color-label {
    height: 24px;
    width: 32px;
    border: 1px solid #aaa;
    margin: 0 24px;    
  }

  .options-panel {
    display: flex;
    border-bottom: 1px solid #ddd; 
    max-height: 640px;
  }

  .menu {
    padding: 20px 0 100px;
    border-right: 1px solid #ddd;
  }

  .menu-item {
    cursor: pointer;
    margin: 8px 0;
    padding: 8px 32px;
    min-width: 180px;
    border-right: 3px solid transparent;
    transition: color 200ms linear, border-color 200ms linear;
    user-select: none;
  }

  .sel-menu-item {
    color: #E40000;
    border-right: 3px solid #E40000;
  }

  .options-editor {
    display: grid;
  }

  .options-group {
    grid-area: 1 / 1;
    z-index: 1;
    background: white;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .options-group.visible {
    visibility: visible;
    z-index: 2;
  }

  .array-button {
    margin: 0;
  }

  .array-button:disabled .md-icon {
    opacity: 0.5;
  }

  .color-preview {
    min-width: 30px; 
    height: 30px;
    margin: 0 16px;
    cursor: pointer;
  }

  .add-button .md-button-content {
    display: flex;
    align-items: center;
  }

  .color-dialog {
    min-width: 120px !important;
  }

  .reset-button {
    opacity: 0.3;
  }

  .reset-button:hover {
    opacity: 1;
  }

</style>