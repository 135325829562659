const auth0Switch = 3
let AUTH0_CONFIG

if (auth0Switch === 0) {
  // woenenn
  AUTH0_CONFIG = {
    clientId: 0 ? '7Mgn2J6ve4UBdSAw1a07S0M64fW4DJJ4' : 'ymn1VEPWilzlM6H047QMPjdm9UdQBpfi',
    domain: 'tema-q.eu.auth0.com',
    audience: 'https://login.woenenn.tema-q.de',
    cacheLocation: 'localstorage', // 'memory
    admins: ['admin', 'clientadmin']
  }
} else if (auth0Switch === 1) {
  // launchfeedback
  AUTH0_CONFIG = {
    clientId: 'GfaZ8RhMqLNRq8q9f7cawlSPrLisIDgY',
    domain: 'mobilmacher.eu.auth0.com',
    audience: 'https://launch-feedback.de/api/v1',
    cacheLocation: 'localstorage', // 'memory
    admins: ['admin', 'clientadmin']
  }
} else if (auth0Switch === 2) {
  // woenenn mobilmacher
  AUTH0_CONFIG = {
    clientId: '3bqGHMUmQXJwVpapGRJil69h280YlsJS',
    domain: 'mobilmacher.eu.auth0.com',
    audience: 'https://woennen.de/api/v1',
    cacheLocation: 'localstorage', // 'memory
    admins: ['admin', 'clientadmin']
  }
} else if (auth0Switch === 3) {
  // woenenn mobilmacher
  AUTH0_CONFIG = {
    clientId: 'XqGcRMMaIviZ7GYM8tmYs2MSbCP8C2Ez',
    domain: 'login.tema-q.de', // CName entry to tq-apps.eu.auth0.com
    //domain: 'tq-apps.eu.auth0.com',
    audience: 'https://woennen.de/api/v1', 
    cacheLocation: 'localstorage', // 'memory
    admins: ['admin', 'clientadmin']
  }
}

/*AUTH0_CONFIG.BYPASS_AUTH0 = {
  user: {
    sub: 'auth0|5c895b7702d7ad0170b9fabb',
    email: 'dasboe@me.com'
  }
}*/


/*const API_CONFIG = {
  baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : ''
  //baseUrl: 'https://woenenn-api.tema-q.de'
}*/

const LOCAL_STORAGE_VERSION = 1

export {
  AUTH0_CONFIG,
  LOCAL_STORAGE_VERSION // Increment version to reset localstorage when accessed
}
