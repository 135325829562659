<template>
<div style="flex: 1; display: flex; justify-content: center; align-items: center;">
  <div v-show="!finished">running</div>
  <div v-show="finished">{{message}}</div>
</div>
</template>

<script>

export default {
  name: 'Tools',
  data: () => ({
    finished: false,
    message: null
  }),
  methods: {
    done(message) {
      this.message = message
      this.finished = true
    },
    reset() {
      localStorage.clear();
      window.location.href = '/'
    }
  },
  created() {
    switch (this.$route.params.action) {
      case 'reset':
        this.reset()

        break
      default:
        this.done('action "' + this.$route.params.action + '" not available')
    }
  },
}
</script>

<style scoped>
</style>
