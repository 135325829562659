import store from '@/store/index'

export default {
  hasPermission(permissionName) {
    let startupData = store.state.startupData
    return startupData.permissions.indexOf(permissionName) !== -1
  },

  hasRole(roleName) {
    let startupData = store.state.startupData
    return startupData.roles.indexOf(roleName) !== -1
  }
}