<template>
  <div v-if="ready">
    <v-dialog
      v-model="showDialog"
      persistent
      width="400"
    >
      <v-card v-if="showDialog" style="display: flex; flex-direction: column;">
        <v-card-title class="text-h6 white--text primary">
          Connected Survey
        </v-card-title>

        <v-card-text class="pa-0" style="display: flex; flex: 1 1 0;">
          <template v-if="multiple">
            <v-autocomplete
              v-model="relatedSurveyIds"
              :items="surveys"
              :item-text="getItemText"
              item-value="_id"
              filled
              multiple
              label="Surveys"
            >
            </v-autocomplete>
          </template>

          <template v-if="!multiple">
            <v-autocomplete
              v-model="relatedSurveyId"
              :items="surveys"
              :item-text="getItemText"
              item-value="_id"
              filled
              label="Surveys"
            >
            </v-autocomplete>
          </template>
        </v-card-text>

        <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save">{{$t('actions.ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-btn depressed color="primary" @click="onClick">
        Connected Survey{{this.multiple ? 's' : ''}}
      </v-btn>
      <template v-if="multiple && relatedSurveyIds.length > 0">
        <ul>
          <li v-for="(relatedSurveyId, index) in relatedSurveyIds" v-bind:key="index">
            {{ getRelatedSurveyDescription(relatedSurveyId) }}
          </li>
        </ul>
      </template>
      <template v-if="!multiple && relatedSurveyId">
        <ul>
          <li>
            {{ getRelatedSurveyDescription(relatedSurveyId) }}
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'surveyjs-creator-related-surveys',
  data: () => ({
    ready: false,
    relatedSurveyId: Object,
    relatedSurveyIds: Object,
    showDialog: false,
    surveys: null,
  }),
  props: {
    multiple: {
      default: false,
      type: Boolean
    },
    question: Object
  },
  methods: {
    getRelatedSurveyDescription(relatedSurveyId) {
      let relatedSurvey = this.surveys.find(survey => survey._id == relatedSurveyId)

      if (!relatedSurvey) {
        throw new Error(`Related survey not in database. ObjectId: ${relatedSurveyId}`)
      }

      return `${relatedSurvey.label} (ID: ${relatedSurvey._id})`
    },

    getItemText(relatedSurvey) {
      return `${relatedSurvey.label} (ID: ${relatedSurvey._id})`
    },

    async onClick() {
      this.showDialog = true
    },

    save() {
      this.question.value = this.multiple ? this.relatedSurveyIds : this.relatedSurveyId

      this.showDialog = false
    }
  },
  async mounted() {
    this.surveys = (await api.call('getSurveys')).data

    let value = this.question.value
    
    if (this.multiple) {
      this.relatedSurveyIds = value || []
    } else {
      this.relatedSurveyId = value || null
    }

    this.ready = true
  }
}
</script>

<style lang="scss" scoped>
</style>