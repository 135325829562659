export default {
  getEmptyFilterValue(filter) {
    switch (filter.type) {
      case 'array_of_strings':
      case 'boolean':
      case 'date':
      case 'datetime':
      case 'date':
      case 'number':
      case 'string':
      case 'text':
      case 'verbatim_n':
        return ''
      case 'boolean':
        return false
      case 'categorical':
        return []
      default:
        throw new Error(`unknown type "${filter.type}"`)
    }
  },

  createFilterChips (filterValues, filters) {
    let filterChips = []

    for (let key in filterValues) {
      let filter = filters.find(function(filter) { return filter.name === key })

      if (!filter) {
        console.error(key)
        console.error(filters.map(filter => filter.name))
        throw new Error(`no filter with filter name "${key}"`)
      }

      let filterValue = filterValues[key]

      if (filterValue && Array.isArray(filterValue) ? filterValue.length > 0 : true) {
        switch(filter.type) {
          case 'array_of_strings':
          case 'boolean':
          case 'date':
          case 'datetime':
          case 'number':
          case 'string':
          case 'text':
          case 'verbatim_n':
            if (filterValue) {
              filterChips.push({
                value: filterValue,
                filter: filter
              })
            }
            break
          case 'categorical':
            filterValue.forEach((categoricalFilterValue) => {
              filterChips.push({
                value: categoricalFilterValue,
                filter: filter
              })
            })
            break
        }
      }
    }
    return filterChips
  },
}