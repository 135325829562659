import store from '@/store/index'
import api from '@/services/api'
import { t } from '@/i18n'

import localStorageHelper from '@/services/localStorageHelper'

let error = {
  new(e, options) {
    if (e && e.__RUNTIME_ERROR) {
      return e
    }

    return {
      __RUNTIME_ERROR: true,
      e: e,
      options: options
    }
  },

  throw(e, options) {
    throw error.new(e, options)
  },


  async runtimeError(e, options) {
    store.dispatch('resetLoading')

    if (e && e.__RUNTIME_ERROR) {
      return await this.runtimeError(e.e, e.options)
    }

    try {
      options = Object.assign({
        clearLocalStorage: true,
        type: 'error',
        showTechnicalErrorMessage: false,
        clientDebugData: {},
        buttons: [],
        silent: false,
        writeLog: true
      }, options || {})

      if (options.clearLocalStorage) {
        localStorageHelper.clear()
      }

      console.error(e)
      console.error(new Error())

      let message = e ? (_.isString(e) ? e : (e.message ? e.message : undefined)) : undefined

      if (message == 'Login required') {
        // TODO: this message comes from AUTH0. It might be better to catch
        // it using another approach which is documentet and less prone to 
        // regression

        window.location = '/'
        return
      }

      if (!options.showTechnicalErrorMessage) {
        options.clientDebugData = Object.assign(options.clientDebugData || {}, {
          message: message
        })
        message = t('errors.UNEXPECTED_ERROR')
      }

      e = e || new Error('runtime error')

      let errorId = null
      
      if (options.writeLog) {
        let appliedFilterValues = null
      
        if (store.state.appliedFilterValues) {
          appliedFilterValues = {}

          for (let key in store.state.appliedFilterValues) {
            let appliedFilterValue = store.state.appliedFilterValues[key]
            appliedFilterValues[key.replace(/\./g, '---')] = appliedFilterValue
          }
        }

        let serverLog = {
          type: options.type,
          errorType: 'RUNTIME ERROR',
          silent: !!options.silent,
          message: message,
          name: e.name || null,
          description: e.description || null,
          number: e.number || null,
          fileName: e.fileName || null,
          lineNumber: e.fileNumber || null,
          columnNumber: e.columnNumber || null,
          stack: e.stack || null,
          callStack: new Error().stack,
          source: e.toSource ? e.toSource() : null,
          string: e.toString ? e.toString() : null,
          userAgent: navigator.userAgent || null,
          clientDebugData: options.clientDebugData,
          data: {
            source: store.state.source ? store.state.source.name : null,
            view: store.state.view ? store.state.view.name : null,
            appliedFilterValues: appliedFilterValues,
            analysisSettings: store.state.analysisSettings
          },
          user: store.state.user.email,
          href: window.location.href
        }

        try {
          let error = (await api.call('runtimeError', {
            data: JSON.parse(JSON.stringify(serverLog))
          })).data

          errorId = error.errorId
        } catch(e) {
          console.error('Can not send server error')
        }
      }

      let titleTranslation = options.titleTranslation || (() => {
        switch (options.type) {
          case 'info':
            return 'errors.RUNTIME_INFO'
          case 'warning':
            return 'errors.RUNTIME_WARNING'
          default:
            // 'error' type or no type
            return 'errors.RUNTIME_ERROR'
        }
      })()
      
      if (!options.silent) {
        store.dispatch('setError', Object.assign({
          titleTranslation: titleTranslation,
          msg: message,
          errorId: errorId,
          hideClose: true,
          hideReload: true,
          hideToStartPage: false
        }, options))
      }
    } catch (e) {
      console.error('ERROR IN HANDLING RUNTIME ERRORS')
      console.error(e)
    }
  },

  serverCommunicationError(e, errorId, type, options) {
    console.error(e.message)
    console.error(e)
    console.error(new Error())
    console.error(options)

    let message = _.isString(e) ? e : (e.message ? e.message : undefined)

    let titleTranslation = options.titleTranslation || (() => {
      switch (type) {
        case 'info':
          return 'errors.SERVER_COMMUICATION_INFO'
        case 'warning':
          return 'errors.SERVER_COMMUICATION_WARNING'
        default:
          // 'error' type or no type
          return 'errors.SERVER_COMMUICATION_ERROR'
      }
    })()

    store.dispatch('setError', Object.assign({
      titleTranslation: titleTranslation,
      msg: message,
      type: type,
      errorId: errorId,
      hideClose: true,
      hideToStartPage: true
    }, options || {}))
  }
}

export default error