// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App'
import Vuex from 'vuex'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import error from '@/services/error'
import phoneCall from '@/services/phoneCall'
import { Auth0 } from '@/auth'
import { func } from '@/func.js'

// UI
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
// VueMaterial theme is defined in App.vue

// Plugins

// Vue Draggable
import Draggable from 'vuedraggable'

// ag-grid
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import "ag-grid-enterprise"
import {LicenseManager} from "ag-grid-enterprise"

LicenseManager.setLicenseKey("CompanyName=Tema - Q, Technik und Management fr Qualitt GmbH,LicensedGroup=Corbie,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-016158,ExpiryDate=10_July_2022_[v2]_MTY1NzQwNzYwMDAwMA==cf8c8587d75c1b8168d3981e4808c72d")

// jsPDF
import 'jspdf'

// make custom global functions availabe
Vue.prototype.$func = func

// highcharts
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

//PortalVue is a set of two components that allow you to render a component's template (or a part of it)
// anywhere in the document - even outside the part controlled by your Vue App
//Vue.use(PortalVue)

// VUEJS paginate
import Paginate from 'vuejs-paginate'

// Include plugins
Vue.use(Vuex)
Vue.use(VueMaterial)

// Auth0
Vue.use(Auth0, {
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})


Vue.config.errorHandler = (err, vm, info) => {
  console.log('ERROR')

  if (vm.__ERROR_HANDLER) {
    // handle errors for Vue Composer separately
    vm.__ERROR_HANDLER.error(err, vm, info)
    return
  }

  console.error(err)
  console.error(vm)
  console.error(info)

  error.runtimeError(err)
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  
  // TODO: Perform any custom logic or log to server

}

Vue.config.warnHandler = function (err, vm, info) {
  if (vm.__ERROR_HANDLER) {
    vm.__ERROR_HANDLER.warning(err, vm, info)
  }
};

// Components
import AceEditor from '@/components/AceEditor'
import AlertView from '@/components/AlertView'
import AnalysisChips  from '@/components/AnalysisChips'
import AnalysisFilters from '@/components/AnalysisFilters'
import AnalysisOptionItem  from '@/components/AnalysisOptionItem'
import AnalysisSettings from '@/components/AnalysisSettings'
import AnalysisSettingsBookmark from '@/components/AnalysisSettingsBookmark'
import AnalysisSettingsItem  from '@/components/AnalysisSettingsItem'
import AnalysisSettingsManager from '@/components/AnalysisSettingsManager'
import AnalysisSettingsTree from '@/components/AnalysisSettingsTree'
import AnalysisViews from '@/components/AnalysisViews'
import AudioPlayer from '@/components/AudioPlayer'
import ColorPicker from '@/components/ColorPicker'
import ConfirmDialog from '@/components/ConfirmDialog'
import DataChart from '@/components/DataChart'
import DataEntry from '@/components/DataEntry'
import EmailDialog from '@/components/EmailDialog'
import ErrorMessage from '@/components/ErrorMessage'
import IFrame from '@/components/IFrame'
import InlineChart from '@/components/InlineChart'
import InputTreeselect from '@/components/InputTreeselect'
import LetterBadge from '@/components/LetterBadge'
import ListBadge from '@/components/ListBadge'
import LoadingView from '@/components/LoadingView'
import PhoneCallDialer from '@/components/PhoneCallDialer'
import PhoneCallDialog from '@/components/PhoneCallDialog'
import PivotTable from '@/components/PivotTable'
import QuestionSelect from '@/components/QuestionSelect'
import ResultsTable from '@/components/ResultsTable'
import ResultsDetail from '@/components/ResultsDetail'
//import RowData from '@/components/RowData'
//import RowDataNavigation from '@/components/RowDataNavigation'
import SingleCaseView from '@/components/SingleCaseView'
import Slider from '@/components/Slider'
import TqMessage from '@/components/ui/TqMessage'
import VariableBadges from '@/components/VariableBadges'
import VariablesSelectionDialog from '@/components/VariablesSelectionDialog'
import Survey from '@/components/Survey'
import SurveyjsCreator from '@/components/SurveyCreator'
import SurveyjsCreatorRelatedSurveys from '@/components/surveyjs-widgets/SurveyCreatorRelatedSurveys'
import SurveyjsWidgetSurveyDate from '@/components/surveyjs-widgets/SurveyDate'
import SurveyjsWidgetSurveyCreatorJson from '@/components/surveyjs-widgets/SurveyCreatorJson'
import SurveyjsWidgetSurveyCreatorForm from '@/components/surveyjs-widgets/SurveyCreatorForm'
import SurveyjsWidgetSurveyCreatorVue from '@/components/surveyjs-widgets/SurveyCreatorVue'
//import SurveyjsWidgetSurveyCreatorQuestion from '@/components/surveyjs-widgets/SurveyCreatorQuestion'
//import SurveyjsWidgetSurveyCreatorVariable from '@/components/surveyjs-widgets/SurveyCreatorVariable'
import SurveyjsCreatorIsVerbatim from '@/components/surveyjs-widgets/SurveyCreatorIsVerbatim'
import SurveyjsCreatorIsWoenennQuestionVariable from '@/components/surveyjs-widgets/SurveyCreatorIsWoenennQuestionVariable'
//import VariableEditor from '@/components/VariableEditor'
import VariableForm from '@/components/VariableForm'
import VueComposer from '@/components/VueComposer'
import VueComposerPreview from '@/components/VueComposerPreview'
import surveyJsUtil from '@/services/surveyjs/util'
import surveyJsWidgets from '@/services/surveyjs/widgets'

// Include components
Vue.component('ace-editor', AceEditor)
Vue.component('alert-view', AlertView)
Vue.component('analysis-chips', AnalysisChips)
Vue.component('analysis-filters', AnalysisFilters)
Vue.component('analysis-option-item', AnalysisOptionItem)
Vue.component('analysis-settings', AnalysisSettings)
Vue.component('analysis-settings-bookmark', AnalysisSettingsBookmark)
Vue.component('analysis-settings-item', AnalysisSettingsItem)
Vue.component('analysis-settings-manager', AnalysisSettingsManager)
Vue.component('analysis-settings-tree', AnalysisSettingsTree)
Vue.component('analysis-views', AnalysisViews)
Vue.component('audioplayer', AudioPlayer)
Vue.component('color-picker', ColorPicker)
Vue.component('confirm-dialog', ConfirmDialog)
Vue.component('data-entry', DataEntry)
Vue.component('data-chart', DataChart)
Vue.component('draggable', Draggable)
Vue.component('emailDialog', EmailDialog)
Vue.component('error-message', ErrorMessage)
Vue.component('i-frame', IFrame)
Vue.component('inline-chart', InlineChart)
Vue.component('input-treeselect', InputTreeselect)
Vue.component('letter-badge', LetterBadge)
Vue.component('list-badge', ListBadge)
Vue.component('loading-view', LoadingView)
Vue.component('paginate', Paginate)
Vue.component('phone-call-dialer', PhoneCallDialer)
Vue.component('phone-call-dialog', PhoneCallDialog)
Vue.component('pivot-table', PivotTable)
Vue.component('question-select', QuestionSelect)
Vue.component('results-table', ResultsTable)
Vue.component('results-detail', ResultsDetail)
//Vue.component('row-data', RowData)
//Vue.component('row-data-navigation', RowDataNavigation)
Vue.component('slider', Slider)
Vue.component('single-case-view', SingleCaseView)
Vue.component('survey', Survey)
Vue.component('surveyjs-creator', SurveyjsCreator)
Vue.component('surveyjs-creator-survey-date', SurveyjsWidgetSurveyDate)
Vue.component('surveyjs-creator-json', SurveyjsWidgetSurveyCreatorJson)
Vue.component('surveyjs-creator-form', SurveyjsWidgetSurveyCreatorForm)
Vue.component('surveyjs-creator-vue', SurveyjsWidgetSurveyCreatorVue)
Vue.component('surveyjs-creator-related-surveys', SurveyjsCreatorRelatedSurveys)
//Vue.component('surveyjs-creator-question', SurveyjsWidgetSurveyCreatorQuestion)
//Vue.component('surveyjs-creator-variable', SurveyjsWidgetSurveyCreatorVariable)
Vue.component('surveyjs-creator-is-verbatim', SurveyjsCreatorIsVerbatim)
Vue.component('surveyjs-creator-is-woenenn-question-variable', SurveyjsCreatorIsWoenennQuestionVariable)
Vue.component('tq-message', TqMessage)
Vue.component('variable-badges', VariableBadges)
Vue.component('variable-form', VariableForm)
Vue.component('variables-selection-dialog', VariablesSelectionDialog)
Vue.component('vue-composer', VueComposer)
Vue.component('vue-composer-preview', VueComposerPreview)

// Directives
import ResultsCellValue from '@/directives/ResultsCellValue'

import vuetify from '@/plugins/vuetify'

// Include directives
Vue.directive('tqResultsCellValue', ResultsCellValue)

surveyJsUtil.init({ theme: 'legacy' })
surveyJsWidgets.init()

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,

  components: {
    App
  },

  template: '<App />',

  mounted: function () {
  },

  vuetify,

  beforeDestroy: function() {
  }
})