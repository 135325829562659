import pako from 'pako'
import store from '@/store/index'
import router from '@/router/index'
import { getInstance } from '@/auth'

const encodeUrl = function(url) {
  // deflate (pack)
  let deflatedStr = pako.deflate(url, {to: 'string'})
  // convert to Base64
  return '2_' + btoa(deflatedStr)
}

const decodeUrl = function(url) {
  try {
    let binaryStr
    let urlSplit = url.split('_')

    if (urlSplit.length > 1) {
      let version = urlSplit.shift()
      let coded = urlSplit.join('_')

      switch (version) {
        case '2':
          // convert to binary string
          binaryStr = atob(coded)
          break;
        default:
          throw 'unkown version'
      }
    } else {
      // convert to binary string
      binaryStr = Buffer.from(url, 'base64').toString()
    }

    return pako.inflate(binaryStr, {to: 'string'})
  } catch (err) {
    console.error(err)
    // error while decoding
    return '{}'
  }
}

const url = {
  lastQueryUrl: null,

  fromFilterUrl(url) {
    let decodedUrlStr = decodeUrl(url)
    return JSON.parse(decodedUrlStr)
  },

  toFilterUrl(filter) {
    let reducedFilter = {}
    let empty = true

    for (let key in filter) {
      let val = filter[key]

      if (val.length > 0) {
        empty = false
        reducedFilter[key] = val
      }
    }
    let filterJson = JSON.stringify(reducedFilter)

    return empty ? undefined : encodeUrl(filterJson)
  },

  fromAnalysisSettingsUrl(url) {
    if (_.isString(url)) {
      let decodedUrlStr = decodeUrl(url)
      return JSON.parse(decodedUrlStr)
    } else {
      return url
    }
  },

  toAnalysisSettingsUrl(analysisSettings) {
    return encodeUrl(JSON.stringify(analysisSettings))
  },

  updateAnalysisUrl(analysisView, replace) {
    const auth = getInstance()
    store.dispatch('getQueryUrl', { filter: true, analysisSettings: true, analysisSidebar: true }).then(queryUrl => {
      queryUrl.analysisView = analysisView

      if (_.isEqual(url.lastQueryUrl, queryUrl)) {
        return
      } else {
        url.lastQueryUrl = queryUrl
      }

      let sourceName = store.state.source.name
      let viewName = store.state.view.name
      let roleName = store.state.roleName
      let analysisSettings = store.state.analysisSettings
      let filterValues = store.state.filterValues
      let analysisSidebar = store.state.analysisSidebar

      let lastAnalysis = {
        analysisSettings: analysisSettings,
        filterValues: filterValues,
        analysisView: analysisView,
        analysisSidebar: analysisSidebar
      }

      let storageName = 'lastAnalysisStore_' + auth.user.sub

      let lastAnalysisStore = localStorage.getItem(storageName)
      lastAnalysisStore = lastAnalysisStore ? JSON.parse(lastAnalysisStore) : {}

      let lastAnalysisBySourceName = lastAnalysisStore[sourceName]

      if (!lastAnalysisBySourceName) {
        lastAnalysisStore[sourceName] = lastAnalysisBySourceName = { __sourceVersion: store.state.source.version }
      }

      lastAnalysisBySourceName[viewName] = lastAnalysis

      localStorage.setItem(storageName, JSON.stringify(lastAnalysisStore))
      if (replace) {
        router.replace({ name: 'analysis', params: { sourceName: sourceName, viewName: viewName, roleName: roleName }, query: queryUrl })
      } else {
        router.push({ name: 'analysis', params: { sourceName: sourceName, viewName: viewName, roleName: roleName }, query: queryUrl })
      }
    })
  },

  decomposeUrl(urlStr) {
    let data = {};
    let url = new URL('file://foo.com' + urlStr);
    let pathTokens = url.pathname.split('/');
    while (pathTokens[0] === '') {
      pathTokens.shift();
    }
    data.sourceName = pathTokens[0];
    data.viewName = pathTokens[1];
    
    let params = {};
    for (let paramName of url.searchParams.keys()) {
      let paramValue = url.searchParams.get(paramName);
      if (paramName === 'analysisSettings') {
        paramValue = this.fromAnalysisSettingsUrl(paramValue);
      } else if (paramName === 'filter') {
        paramValue = this.fromFilterUrl(paramValue);
      }
      params[paramName] = paramValue;
    }    
    data.params = params;
    return data;
  },

  saveReload() {
    let href

    if (store.state.source) {
      if (store.state.view) {
        href = `/${store.state.source.name}/${store.state.view.name}/analysis`
      } else {
        href = `/${store.state.source.name}/analysis`
      }
    } else {
      href = '/'
    }
    
    window.location.href = href
  },

  redirect(params, query) {
    try {
      params = Object.assign({
        sourceName: store.state.source.name,
        viewName: store.state.view.name,
        roleName: store.state.roleName
      }, params || {})

      query = Object.assign({
        analysisSidebar: store.state.analysisSidebar,
        analysisView: store.state.analysisView
      }, query || {})

      router.push({ name: 'analysisRedirect3', params: params, query: query })
    } catch (e) {
      error.runtimeError(e)
    }
  }
}

export default url