<template>
  <v-tooltip bottom :offset-overflow="true" :open-delay="delay" :disabled="!label">
    <template v-slot:activator="{ on, attrs }">
      <div class="letter-badge" v-bind="attrs" v-on="on" v-bind:style="{ backgroundColor: color }">
        {{letter}}
      </div>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'LetterBadge',
  props: {
    color: {
      type: String,
      default: '#ccc'
    },
    letter: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    delay: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>  
  .letter-badge {
    background: #cccccc;
    border-radius: 0.6em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.2em;
    margin-right: 0.3em;
    text-align: center;
    width: 1.2em; 
  }
</style>